import React, {useEffect} from "react";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

import { getAddressFromCoordinates } from "../../lib/helper"

const PlacesSearch = ({ onSelect, lat, lng }) => {
	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		callbackName: "initMap",
		requestOptions: {
			/* Define search scope here */
		},
		debounce: 300,
	});

	const ref = useOnclickOutside(() => {
		clearSuggestions();
	});

	const handleInput = (e) => {
		// Update the keyword of the input element
		setValue(e.target.value);
	};

	const handleSelect =
		({ description }) =>
		() => {
			// When the user selects a place, we can replace the keyword without request data from API
			// by setting the second parameter to "false"
			setValue(description, false);
			clearSuggestions();

			// Get latitude and longitude via utility functions
			getGeocode({ address: description }).then((results) => {
				const { lat, lng } = getLatLng(results[0]);
				// console.log("📍 Coordinates: ", { lat, lng });
				onSelect?.({ lat, lng, address: description });
			});
		};

	const renderSuggestions = () =>
		data.map((suggestion) => {
			const {
				place_id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;

			return (
				<li
					style={{
						cursor: "pointer",
					}}
					key={place_id}
					onClick={handleSelect(suggestion)}>
					<strong>{main_text}</strong> <small>{secondary_text}</small>
				</li>
			);
		});

	useEffect(() => {
	  if (lat && lng) {
		async function fetchaddredd() {
			try {
				const address = await getAddressFromCoordinates(lat, lng);
				setValue(address, false)
			} catch (error) {
				console.log(error)
			}
		}

		fetchaddredd()
	  }
	}, [lat, lng, setValue])
	

	return (
		<div style={containerStyles} ref={ref}>
			<input
				value={value}
				onChange={handleInput}
				disabled={!ready}
				placeholder="Search City"
				className="uk-input"
			/>

			{/* We can use the "status" to decide whether we should display the dropdown or not */}
			{status === "OK" && (
				<ul style={ulStyles} className="uk-list uk-list-divider">
					{renderSuggestions()}
				</ul>
			)}
		</div>
	);
};

export default PlacesSearch;

const ulStyles = {
	position: "absolute",
	width: "100%",
	backgroundColor: "white",
	zIndex:2
};

const containerStyles = { position: "relative", backgroundColor: "white" };
