import { React, useState, useEffect, useRef } from "react";
import axios from "axios";
import { urls, routes } from "../../Global";
import { useLocation, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import tick from "../../assets/svg_icons/tick-large.svg";
import Alert from "../../Components/Helpers/Alert";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next'; 


function Edit() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);
    
    const [responseMessage, setResponseMessage] = useState({
        type: "",
        message: ""
    })

    const [updating, setUpdating] = useState(undefined)

    const { product_id } = useParams();

    const cookies = new Cookies()
    const user_id = cookies.get("id")
    const reseller_id = cookies.get("reseller_id")

    const location = useLocation()
    const product = location.state ? location.state.product : {};
    // console.log(product)
    // product.user_id = user_id
    product.reseller_id = reseller_id
    product.product_id = product_id
    // product.product_age = 10
    // product.number_of_usage = 1
    product.suggested_amount_low = 100
    product.suggested_amount_high = 200
    product.address = "thane 400601"
    product.product_size = "1";
	product.product_location = "1";
    delete product.display_image;
    var refreshTime = 3
    const refreshTag = useRef();
    console.log(product)

    async function createProduct() {
        try {
            const newdata = {...product};
            setUpdating(true)
            console.log("newdata", newdata)
            const edit = await axios.patch(urls.serverUrl + routes.editProduct + `/${reseller_id}`, newdata)
            if (edit.status === 200) {
                setUpdating(false)
            }
            
            let countdown = refreshTime;
                const intervalId = setInterval(function () {
                    refreshTag.current.innerHTML = `Refreshing in ${countdown}`;
                    if (countdown <= 0) {
                        clearInterval(intervalId);
                        window.history.replaceState({}, document.title, '/seller');
                        window.location.replace('/seller');
                    }
                    countdown--;
                }, 1000);
        } catch (err) {
            // console.log(err)
            // setResponseMessage({
            //     type: "danger",
            //     message: "Some error occured, please try again"
            // })

            console.log("Some error occured, please try again");
            setUpdating(false)
        }
    }
    setInterval(function () {
        refreshTag.current.innerHTML = `Refreshing in ${refreshTime}`
        if (refreshTime <= 0) {
            window.history.replaceState({}, document.title, '/seller');
            window.location.replace('/seller');
        }
        refreshTime--
    }, 1000)
    
    useEffect(function () {
        createProduct()
    }, [])

    return (
        <>
            <div
                className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
                data-uk-grid>
                <div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
                    <img
                        src="/images/side_banner.png"
                        alt="Side Banner"
                        className="uk-width-side-banner"
                    />
                </div>
                <div className="uk-flex uk-flex-center uk-flex-middle">
                    <div className="uk-container uk-container-add uk-width-auto uk-section">
                        {
                            updating === true ? (
                                <>
                                    <div className="uk-text-center">
                                        <div data-uk-spinner></div>
                                        <p className="uk-margin-top">{t('updatingProduct')}</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Alert
                                        message={responseMessage}
                                        reset={function () {
                                            setResponseMessage({
                                                type: false,
                                                message: false,
                                            });
                                        }}
                                    />
                                    <div className="uk-position-relative">
                                        <img src={product.product_images} alt={product.product_title} />
                                        <ReactSVG src={tick} className="uk-icon-product-added" />
                                    </div>
                                    <p style={{ marginTop: "85px" }} className="uk-text-6 uk-text-bold uk-text-center">{t('productUpdatedSuccessfully')}</p>
                                    <p className="uk-text-center uk-margin-top" ref={refreshTag}>Refreshing in {refreshTime}</p>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Edit;
