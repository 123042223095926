import React, { useState, useRef } from "react";
import camera from "../../assets/svg_icons/camera.svg";
import { ReactSVG } from "react-svg";
import { useEffect } from "react";

const AadhaarUpload = ({ setParentFiles, text }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState({
    file: false,
    base64Url: false,
  });
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const newFiles = [...e.dataTransfer.files];
    handleFileSelect(newFiles);
  };

  const handleFileSelect = (selectedFiles) => {
    if (selectedFiles.length > 0) {
      const filesWithBase64 = {};
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Url = event.target.result;
        filesWithBase64.file = selectedFiles[0];
        filesWithBase64.base64 = base64Url.split(',')[1];
        setFiles(filesWithBase64);
      };
      reader.readAsDataURL(selectedFiles[0]);
    }
  };

  useEffect(
    function () {
      if (setParentFiles) {
        setParentFiles(files);
      }
    },
    [files, setParentFiles]
  );

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      className={`uk-upload-aadhaar dropzone ${dragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}>
      <div>
        <img src="/images/aadhaar.png" alt="Aadhaar" />
      </div>
      <div className="uk-flex uk-flex-center uk-position-center">
        <ReactSVG src={camera} />
        <p className="uk-color-white uk-margin-small-left">{text}</p>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        onChange={(e) => handleFileSelect(Array.from(e.target.files))}
        style={{ display: "none" }}
      />
      {files.file ? (
        <ul className="uk-list-files">
          <li key={files.file.name}>
            {files.file.name}
            <button
              data-uk-close
              onClick={(e) => {
                e.stopPropagation();
                setFiles({
                  file: false,
                  base64Url: false,
                });
              }}></button>
          </li>
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default AadhaarUpload;
