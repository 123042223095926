import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { AppBar, Box, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import Cookies from "universal-cookie";
import { ReactSVG } from "react-svg";
import tshirt from "../../assets/svg_icons/t-shirt.svg";
import wallet from "../../assets/svg_icons/wallet.svg";
import calender from "../../assets/svg_icons/calender.svg";
import heartregular from "../../assets/svg_icons/heart-regular.svg";
import messages from "../../assets/svg_icons/messages.svg";
import avatar from "../../assets/svg_icons/avatar.svg";
import righttobracket from "../../assets/svg_icons/right-to-bracket-solid.svg";
import rightfrombracket from "../../assets/svg_icons/right-from-bracket-solid.svg";
import Profile from "../Content/Profile";


const whatsAppNumber = "+918109062889";

function Header() {
    const theme = useTheme();
    const cookies = new Cookies();
    const id = cookies.get("id");

    const [isOpen, setIsOpen] = useState(false);

    const is480 = useMediaQuery(theme.breakpoints.down("480"));

    const [popup, setPopup] = useState(false);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 100) {
                // Set the state to true when scrolled down by 100 pixels
                setIsOpen(false);
            }
        };

        // Add a scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const mobileStyles = {
        flexDirection: "column",
        position: "fixed",
        backgroundColor: "white",
        right: isOpen ? 0 : "-30rem",
        top: "5rem",
        height: "100%",
        transition: ".4s ease",
        width: "100vw",
    };

    return (
        <AppBar position="sticky" sx={{ boxShadow: "none" }}>
            <Toolbar
                sx={{
                    bgcolor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 0.6,
                }}>
                <div data-uk-navbar>
                    <div>
                        <NavLink to="/">
                            <img
                                style={{ width: "70px" }}
                                src="/rounded-logo.svg"
                                alt="Anywhere Cloths rounded logo"
                                decoding="async"
                                loading="lazy"
                            />
                        </NavLink>
                    </div>
                </div>

                {is480 && (
                    <IconButton sx={{ transition: ".4s" }} onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? (
                            <ClearIcon fontSize="large" sx={{ color: "text.secondary" }} />
                        ) : (
                            <MenuIcon fontSize="large" sx={{ color: "text.secondary" }} />
                        )}
                    </IconButton>
                )}

                <div
                    className={is480 ? "uk-navbar-nav" : "uk-navbar-nav uk-flex-center"}
                    style={is480 ? mobileStyles : null}>
                    {
                        // <li style={{ marginTop: is480 ? "3rem" : 0 }}>
                        //     <a
                        //         onClick={() => (window.location.href = `tel:${whatsAppNumber}`)}
                        //         target="_blank"
                        //         rel="noreferrer"
                        //         style={{
                        //             height: "50px",
                        //             minHeight: "unset",
                        //             padding: "0 10px",
                        //             borderRadius: "50px",
                        //         }}
                        //         className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button">
                        //         <span style={{ width: "6rem" }} className="uk-text-center">
                        //             Contact us
                        //         </span>
                        //     </a>
                        // </li>
                    }

                    {id !== undefined && (
                        <>
                            <li style={{ marginTop: is480 ? "3rem" : 0 }}>
                                <a
                                    onClick={() => (window.location.href = `/seller`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button hover:shadow-xl">
                                    <span style={{ width: "auto" }} className="uk-text-center">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={tshirt} />
                                        </span>
                                    </span>
                                </a>
                            </li> 

                            <li style={{ marginTop: is480 ? "3rem" : 0 }}>      
                                <a
                                    onClick={() => (window.location.href = `/wallet`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button hover:shadow-xl">
                                        <span style={{ width: "auto" }} className="uk-text-center">
                                            <span className="uk-icon uk-icon-navbar">
                                                <ReactSVG src={wallet} />
                                            </span>
                                        </span>
                                </a>
                            </li>

                            <li style={{ marginTop: is480 ? "3rem" : 0 }}>
                                <a
                                    onClick={() => (window.location.href = `/bookings`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button hover:shadow-xl">
                                    <span style={{ width: "auto" }} className="uk-text-center">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={calender} />
                                        </span>
                                    </span>
                                </a>
                            </li>

                            <li style={{ marginTop: is480 ? "3rem" : 0 }}>
                                <a
                                    onClick={() => (window.location.href = `/wishlist`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button hover:shadow-xl">
                                    <span style={{ width: "auto" }} className="uk-text-center">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={heartregular} />
                                        </span>
                                    </span>
                                </a>
                            </li> 

                            <li style={{ marginTop: is480 ? "3rem" : 0 }}>
                                <a
                                    onClick={() => (window.location.href = `/inbox`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button hover:shadow-xl">
                                    <span style={{ width: "auto" }} className="uk-text-center">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={messages} />
                                        </span>
                                    </span>
                                </a>
                            </li>

                            {/*<li style={{ marginTop: is480 ? "3rem" : 0 }}>
                                <a
                                    onClick={() => (window.location.href = `/profile`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button hover:shadow-xl">
                                    <span style={{ width: "auto" }} className="uk-text-center">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={avatar} />
                                        </span>
                                    </span>
                                </a>
                            </li>*/}

                            <li onClick={() => setPopup("profile")}>
                                    <span className="uk-icon uk-icon-navbar" style={{cursor: "pointer"}}>
                                        <ReactSVG src={avatar} />
                                    </span>
                                    {popup === "profile" ? (
                                        <Profile
                                            onClose={function () {
                                                setPopup(false);
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </li>
                        </>
                    )}
                    {id === undefined && (
                        <>
                            <li style={{ marginTop: is480 ? "3rem" : 0 }}>
                                <a
                                    onClick={() => (window.location.href = `/login`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-width-1-1 uk-button hover:shadow-xl">
                                    <span style={{ width: "auto" }} className="uk-text-center">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={righttobracket} />
                                        </span>
                                    </span>
                                </a>
                            </li>
                        </>
                    )}
                    {id !== undefined && (
                        <>
                            <li style={{ marginTop: is480 ? "3rem" : 0 }}>
                                <a
                                    onClick={() => (window.location.href = `/logout`)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        height: "35px",
                                        minHeight: "unset",
                                        padding: "0 10px",
                                        justifyContent: "left",
                                        borderRadius: "20px",
                                    }}
                                    className="uk-height-small uk-padding-small uk-flex uk-flex-left uk-width-1-1 uk-button hover:shadow-xl">
                                    <span style={{ width: "auto" }} className="uk-text-center">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={rightfrombracket} />
                                        </span>
                                    </span>
                                </a>
                            </li>
                        </>
                    )}

                    {/* <li style={{ marginLeft: is480 ? 0 : "50px", padding: "0 1rem" }}>
            <a
              href={"https://api.anywherecloths.com/api/v1/get-app"}
              target="_blank"
              rel="noreferrer"
              style={{
                height: "50px",
                minHeight: "unset",
                padding: "0 20px",
                borderRadius: "50px",
              }}
              className="uk-height-small uk-padding-small uk-flex uk-flex-center uk-button uk-button-primary"
            >
              <a
                target="_blank"
                href="https://api.anywherecloths.com/api/v1/get-app"
              >
                <span className="uk-color-white uk-text-center">
                  Download App
                </span>
              </a>
            </a>
          </li> */}
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
