import React, { useEffect } from "react";
import Header from "../Components/Helpers/Header";
import { Footer } from "../Components/Footer/Footer";

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Header/>
      <div className="uk-section">
        <div className="uk-container">
          <div className="uk-wysiwyg-default">
            <h1 className="uk-text-center">Terms and Conditions</h1>
            <p>
              These terms and conditions govern your use of our e-commerce
              Website & Application. Please read these Terms carefully before
              using the Website & Application. By accessing or using the Website
              & Application\, you agree to be bound by these Terms. If you do
              not agree with any part of these Terms, you may not use the
              Website & Application.
            </p>
            <ol className="uk-list uk-list-decimal">
              <li>
                <strong>Acceptance of Terms:</strong> By using our Website &
                Application, you acknowledge that you have read, understood, and
                agreed to be bound by these Terms. These Terms constitute a
                legally binding agreement between you and Bhelsa Rental Pvt.
                Ltd. .
              </li>
              <li>
                <strong>Agreement Period:</strong> This Agreement begins when
                you first login FLYROBE by logging in with your Facebook or
                Google account or by any other way and accept it remains in
                effect indefinitely unless terminated.
              </li>
              <li>
                <strong>Age Restriction:</strong> You must be at least 18 years
                old to use our Website & Application. By using the Website &
                Application, you represent and warrant that you are at least 18
                years old.
              </li>
              <li>
                <strong>User Accounts:</strong>
                <ul className="uk-list uk-list-disc">
                  <li>
                    In order to access certain features of the Website, you may
                    be required to create a user account. You are responsible
                    for maintaining the confidentiality of your account
                    information and password. You agree to accept responsibility
                    for all activities that occur under your account.
                  </li>
                  <li>
                    You agree to provide accurate, current, and complete
                    information during the registration process and to update
                    such information as necessary to ensure its accuracy. The
                    Company reserves the right to suspend or terminate your
                    account if any information provided is found to be
                    inaccurate, incomplete, or misleading.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Content:</strong> Parts of the Bhelsa Rental Pvt. Ltd.
                Platform enables you to provide feedback, text, photos, audio,
                video, information, and other content (collectively, “Content”).
                By providing Content, in whatever form and through whatever
                means, you grant Bhelsa Rental Pvt. Ltd. a non-exclusive,
                worldwide, royalty-free, irrevocable, perpetual, sub-licensable
                and transferable licence to copy, modify, prepare derivative
                works of, distribute, publish and otherwise exploit, that
                Content, without limitation. If Content includes personal
                information, our Privacy Policy describes how we use that
                personal information. Where Bhelsa Rental Pvt. Ltd. pays for the
                creation of Content or facilitates its creation, Bhelsa Rental
                Pvt. Ltd. may own that Content, in which case supplemental terms
                or disclosures will say that. You are solely responsible for all
                Content that you provide and warrant that you either own it or
                are authorised to grant Bhelsa Rental Pvt. Ltd. the rights
                described in these Terms. You are responsible and liable if any
                of your Content violates or infringes the intellectual property
                or privacy rights of any third party. Content must comply with
                our Content Policy and Nondiscrimination Policy, which prohibit,
                among other things, discriminatory, obscene, harassing,
                deceptive, violent, and illegal content. You agree that Bhelsa
                Rental Pvt. Ltd. may make available services or automated tools
                to translate Content and that your Content may be translated
                using such services or tools. Bhelsa Rental Pvt. Ltd. does not
                guarantee the accuracy or quality of translations and Members
                are responsible for confirming the accuracy of such
                translations.
              </li>
              <li>
                <strong>Product Listings and Orders:</strong>
                <ul className="uk-list uk-list-disc">
                  <li>
                    The Website may display product listings, including
                    descriptions, prices, and availability. While we strive to
                    provide accurate information, we do not guarantee the
                    accuracy, completeness, or timeliness of the product
                    listings
                  </li>
                  <li>
                    Placing an order through the Website constitutes an offer to
                    purchase the products listed. The Company reserves the right
                    to accept or reject any order in its sole discretion. In the
                    event of order rejection, we will promptly refund any
                    payments made
                  </li>
                  <li>
                    Prices displayed on the Website are subject to change
                    without notice. The Company is not responsible for any
                    typographical or pricing errors on the Website
                  </li>
                </ul>
              </li>
              <li>
                <strong>Payments:</strong>
                <ul className="uk-list uk-list-disc">
                  <li>
                    Payment for products purchased through the Website must be
                    made using the available payment methods provided. By
                    providing payment information, you represent and warrant
                    that you are authorised to use the selected payment method.
                  </li>
                  <li>
                    The Company uses secure third-party payment processors to
                    process transactions. We do not store or have access to your
                    credit card or other financial information on our Server.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Restriction on Placement of Orders:</strong> Bhelsa
                Rental Pvt. Ltd. may impose limitations or restrictions on the
                maximum number of orders placed by a single user for any of the
                products listed on the site/app through his/her account. Bhelsa
                Rental Pvt. Ltd. further has the right to cancel any order
                placed by the user or prohibit any user from taking on rent any
                of the products (s) listed for sale, for any reason, including
                but not limited to any likelihood that the user, as per Bhelsa
                Rental Pvt. Ltd. discretion, will abuse the services or any of
                the reasons or circumstances specified in the Cancellation
                Clause hereunder.
              </li>
              <li>
                <strong>Fraudulent Transactions:</strong> Our fraud detection
                team constantly monitors the user's account in order to avoid
                fraudulent accounts and transactions. Users with more than one
                account or availing referral vouchers fraudulently shall be
                liable for legal actions under law and Bhelsa Rental Pvt. Ltd.
                reserves the right to recover the cost of goods, collection
                charges and lawyers fees from persons using the site/app
                fraudulently. Bhelsa Rental Pvt. Ltd. reserves the right to
                initiate legal proceedings against such persons for fraudulent
                use of the site/app and any other unlawful acts or omissions in
                breach of these terms.
              </li>
              <li>
                <strong>Privacy:</strong>
                <ul className="uk-list uk-list-disc">
                  <li>
                    Your privacy is important to us. Our collection and use of
                    personal information are governed by our Privacy Policy,
                    which is incorporated into these Terms by reference.
                  </li>
                  <li>
                    By using the Website, you consent to the collection, use,
                    and disclosure of your personal information as described in
                    our Privacy Policy. For further detail read our Privacy
                    Policy.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Modifications and Termination:</strong>
                <ul className="uk-list uk-list-disc">
                  <li>
                    The Company reserves the right to modify or terminate the
                    Website, these Terms, or any part thereof at any time
                    without prior notice
                  </li>
                </ul>
              </li>
              <li>
                <strong>Governing Law and Jurisdiction:</strong>
                These Terms and Conditions and the relationship between you and
                Bhelsa Rental Pvt. Ltd. will be governed by the laws as
                applicable in India. Any disputes arising will be handled in the
                competent courts of Vidisha, Madhya Pradesh
              </li>
              <li>
                <strong>Shipping: </strong>
                You agree and understand that the company doesn’t deal in any
                kind of delivery process (to and fro) any kind of shipping
                charges are to be borne by the user
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Terms;
