import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { ReactSVG } from "react-svg";
import Cookies from "universal-cookie";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
// import info from "../../assets/svg_icons/info-light.svg";
import FileUpload from "../Helpers/FileUpload";
import AadhaarUpload from "../Helpers/AadhaarUpload";
import { useState } from "react";
import axios from "axios";
import { routes, urls } from "../../Global";
import Alert from "../Helpers/Alert";
import { useTranslation } from 'react-i18next';


function CompleteKYC() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        } else {
          i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

	const navigate = useNavigate();

	const cookies = new Cookies();
	const id = cookies.get("id");

	const [aadhaarFront, setAadhaarFront] = useState(false);
	const [aadhaarBack, setAadhaarBack] = useState(false);
	const [face, setFace] = useState(false);
	const [aadhaarNumber, setAadhaarNumber] = useState("");
	const [responseMessage, setResponseMessage] = useState({
		type: false,
		message: false,
	});

	async function submitKYC(e) {
		e.preventDefault();
		try {
			if (!aadhaarFront.base64) {
				setResponseMessage({
					type: "danger",
					message: "Upload front image of adhaar!",
				});

				return;
			}
			if (!aadhaarBack.base64) {
				setResponseMessage({
					type: "danger",
					message: "Upload back image of adhaar!",
				});

				return;
			}

			if (!face[0]) {
				setResponseMessage({
					type: "danger",
					message: "Upload your photo!",
				});

				return;
			}
			const data = await axios.patch(
				urls.serverUrl + routes.kycUserProfile + `/${id}`,
				{
					kyc_face_photo: face[0].base64,
					front_kyc_aadhaar_photo: aadhaarFront.base64,
					back_kyc_aadhaar_photo: aadhaarBack.base64,
					aadhaar_number: aadhaarNumber,
				}
			);
			if (data.data.status == 200) {
				setResponseMessage({
					type: "success",
					message: "KYC Updated Successfully",
				});
			}
		} catch (err) {
			// console.log(err);
			setResponseMessage({
				type: "danger",
				message: "Some error occured please try again",
			});
		}
	}

	function goBack() {
		navigate(-1);
	}

	return (
		<>
			<div
				className="uk-section-signin uk-section-profile uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center uk-flex-column">
					<img
						src="/images/profile_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
					<p className="uk-margin-large-top uk-text-4 uk-width-large uk-text-center uk-text-fw-600">
						Lorem ipsum dolor sit amet consectetur.
					</p>
				</div>
				<div className="uk-flex uk-flex-center uk-flex-middle">
					<div className="uk-container-signup-form uk-section">
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div className="uk-flex uk-flex-middle">
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left-small uk-icon-black"
									/>
								</button>
								<p className="uk-text-5-small uk-text-fw-600 uk-margin-left">
									{t('completeKYC')},
								</p>
							</div>
							{/* <ReactSVG src={info} /> */}
						</div>
						<form className="uk-margin-large-top" onSubmit={submitKYC}>
							<AadhaarUpload
								setParentFiles={(e) => setAadhaarFront(e)}
								text={t('uploadAadhaarFront')}
							/>
							<br />
							<AadhaarUpload
								setParentFiles={(e) => setAadhaarBack(e)}
								text={t('uploadAadhaarBack')}
							/>
							<p className="uk-text-muted uk-text-8-big uk-margin-large-top">
								{t('uploadYourImages')}*
							</p>
							<div style={{ marginTop: "6px" }}>
								<FileUpload maxFiles={1} setParentFiles={(e) => setFace(e)} />
							</div>
							<div className="uk-margin-top">
								<label className="uk-form-label" htmlFor="aadhaar_number">
									{t('governmentID')}
								</label>
								<div className="uk-form-controls">
									<input
										type="text"
										name="aadhaar_number"
										defaultValue={aadhaarNumber}
										onChange={(e) => setAadhaarNumber(e.target.value)}
										id="aadhaar_number"
										className="uk-input"
										required
									/>
								</div>
							</div>
							<Alert
								message={responseMessage}
								className={"uk-margin-top"}
								reset={function () {
									setResponseMessage({
										type: false,
										message: false,
									});
								}}
							/>
							<div>
								<button
									type="submit"
									name="update_profile"
									className="uk-button uk-button-primary">
									{t('save')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default CompleteKYC;
