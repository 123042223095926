import { React } from "react";
import { ProductContextProvider } from "../context/addProductContext";
import { Outlet } from "react-router-dom";

function AddProduct() {
    return (
        <>
            <ProductContextProvider>
                <Outlet />
            </ProductContextProvider>
        </>
    )
}

export default AddProduct;
