import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import AuthContext from "../../context/authContext";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
// import infoLight from "../../assets/svg_icons/info-light.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from 'react-i18next';
import Cookies from "universal-cookie";


function ResetPassword() {
  const { getLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const cookies = new Cookies();

  const [otp, setOtp] = useState("");

  const [isVerifyOtp, setIsVerifyOtp] = useState(false);

  const inputRefs = useRef([]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    const newOtp = otp.slice(0, index) + value + otp.slice(index + 1);
    setOtp(newOtp);
    if (value.length > 0 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    const value = e.target.value;
    if (e.key === "Backspace" && value.length === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  function goBack() {
    navigate(-1);
  }

  function verifyOtp(e) {
    e.preventDefault();
  }

  const storedLanguage = localStorage.getItem('language');
  // console.log(storedLanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
      if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
      } else {
          i18n.changeLanguage('en');
      }
  }, [storedLanguage, i18n]);

  return (
    <>
      <div
        className="uk-section-signin uk-section-reset uk-grid uk-child-width-1-2@m uk-height-1-1"
        data-uk-grid>
        <div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
          <img
            src="/images/side_banner.png"
            alt="Side Banner"
            className="uk-width-side-banner"
          />
        </div>
        <div className="uk-flex uk-flex-center uk-flex-middle">
          <div className="uk-container uk-container-signup-form uk-section">
            <div className="uk-flex uk-flex-middle uk-flex-between">
              <button onClick={goBack} className="uk-button-noeffect">
                <ReactSVG
                  src={arrowLeft}
                  className="uk-icon-left-small uk-icon-black"
                />
              </button>
              {/* <div>
                <ReactSVG
                  src={infoLight}
                  className="uk-icon-info-light uk-icon-black"
                />
              </div> */}
            </div>
            <p
              className="uk-text-4 uk-text-fw-600"
              style={{ marginTop: "35px" }}>
              {t('resetPassword')}
            </p>
            <form className="uk-margin-large-top" onSubmit={verifyOtp}>
              {isVerifyOtp ? (
                <>
                  <p className="uk-text-center uk-text-muted uk-text-8-big">
                    {t('enter4DigitCodeForReset')}
                    <br />
                    <strong className="uk-color-primary">
                      youname@domain.com
                    </strong>
                  </p>
                  <div
                    className="uk-grid uk-child-width-1-4 uk-grid-otp"
                    data-uk-grid
                    style={{ marginTop: "28px" }}>
                    {[0, 1, 2, 3].map((index) => (
                      <div key={index}>
                        <input
                          key={index}
                          className="uk-width-1-1"
                          type="text"
                          maxLength={1}
                          onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          ref={(ref) => (inputRefs.current[index] = ref)}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <label className="uk-form-label" htmlFor="password">
                      {t('password')}
                    </label>
                    <div className="uk-form-controls">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="uk-input"
                      />
                    </div>
                  </div>
                  <div className="uk-margin-medium-top">
                    <label className="uk-form-label" htmlFor="cpassword">
                      {t('repeatNewPassword')}
                    </label>
                    <div className="uk-form-controls">
                      <input
                        type="password"
                        name="cpassword"
                        id="cpassword"
                        className="uk-input"
                      />
                    </div>
                  </div>
                </>
              )}
              <div>
                <button
                  style={{
                    padding: "16px",
                    marginTop: "114px",
                  }}
                  type="submit"
                  className="uk-button uk-button-white uk-text-7">
                  {t('signInWithNewPassword')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;