import React, {useEffect} from "react";
import { ReactSVG } from "react-svg";
import confirmation from "../../assets/svg_icons/confirmation.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function BookingItem(props) {
  const storedLanguage = localStorage.getItem('language');
  // console.log(storedLanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
    } else {
        i18n.changeLanguage('en');
    }
  }, [storedLanguage, i18n]);

  const item = props.item;
  const productDetails = item.product;
  const bookingDetails = item.booking_statu;
  const status = props.status;
  const navigate = useNavigate();

  if (!productDetails) {
    return (
      <div className="uk-card uk-card-booking-item" style={{height: '100%'}}>
        <div className="uk-card-body">
          <p className="uk-text-danger uk-text-center">Product details not available.</p>
        </div>
      </div>
    );
  }

  const viewDetails = () => {
    props.viewDetails(item);
  };

  const cancelBooking = () => {
    props.cancelBooking(item.booking_id);
  };

  function raiseComplain(e) {
    navigate(`/raise-complaint/${item.product_id}`, {
      state: {
        booking_id: item.booking_id,
      },
    });
  }

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString().slice(2);
    return `${day}/${month}/${year}`;
  }

  function calculateDayDifference(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; 
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffDays = Math.round(Math.abs((start - end) / oneDay)) + 1;
    return diffDays;
  }


  return (
    <div className="uk-card uk-card-booking-item">
      <div className="uk-card-body">
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-2-5@m">
            {productDetails.product_images && productDetails.product_images[0] && (
              <img
                src={productDetails.product_images[0].image_link}
                alt={productDetails.product_title}
              />
            )}
          </div>
          <div className="uk-width-3-5@m" data-uk-margin="margin: uk-margin-small-top">
            <p className="uk-text-8-big">
              <strong>{productDetails.product_title}</strong>
            </p>

            {item.booking_statu ? (
              <>
                {[1, 3, 4].includes(item.booking_statu.booking_status_id) ? (
                  <div className="uk-flex uk-margin-booking-item uk-color-green">
                    <ReactSVG src={confirmation} />
                    <span className="uk-color-green">{item.booking_statu.booking_status_name}</span>
                  </div>
                ) : [2, 5].includes(item.booking_statu.booking_status_id) ? (
                  <div className="uk-flex uk-margin-booking-item uk-color-red">
                    <ReactSVG src={confirmation} />
                    <span className="uk-color-red">{item.booking_statu.booking_status_name}</span>
                  </div>
                ) : ("")}
              </>
            ) : (
              ""
            )}

            {bookingDetails.booking_payments_status ? (
              <div className="uk-flex uk-margin-booking-item">
                <ReactSVG src={confirmation} />
                <span className="uk-color-red">{bookingDetails.booking_payments_status}</span>
              </div>
            ) : (
              ""
            )}

            <div
              className="uk-text-10 uk-margin-booking-item"
              data-uk-margin="margin: uk-margin-booking-item">
              <p>
                <strong className="uk-margin-small-right">{t('size')}:</strong>{" "}
                <span className="uk-button-pill uk-text-center" style={{padding: '1px'}}>
                  {t('thighs')}: {productDetails.thighs_size_low}, {t('chest')}: {productDetails.chest_size_low}, {t('waist')}: {productDetails.waist_size_low}, {t('shoulder')}: {productDetails.shoulder_size_low}
                </span>
              </p>
              <p>
                <strong className="uk-margin-small-right">{t('price')}:</strong>{" "}
                <strong className="uk-margin-small-right">₹{item.total_price}</strong>{" "}
                <span className="uk-text-muted">(For {calculateDayDifference(item.booked_current_date, item.booked_end_date)} {t('days')})</span>
              </p>
              <p>
                <strong className="uk-margin-small-right">{t('date')}:</strong>{" "}
                <span>{formatDate(item.booked_current_date)} TO {formatDate(item.booked_end_date)}</span>
              </p>
            </div>
          </div>
        </div>
        <div
          className="uk-grid-small uk-child-width-1-2@m"
          data-uk-grid
          style={{ marginTop: "18px" }}>
          <div>
            <button className="uk-button uk-width-1-1 uk-button-primary uk-text-default uk-padding-small-vertical uk-padding-remove-horizontal" onClick={viewDetails}>
              {t('viewDetails')}
            </button>
          </div>
          <div>
            {/*{status === "ongoing" ? (
              <button className="uk-button uk-width-1-1 uk-button-danger uk-text-default uk-padding-small-vertical uk-padding-remove-horizontal" onClick={cancelBooking}>
                {t('cancelBooking')}
              </button>
            ) : (*/}
              <button onClick={raiseComplain} className="uk-button uk-width-1-1 uk-button-danger uk-text-default uk-padding-small-vertical uk-padding-remove-horizontal">
                {t('raiseComplain')}
              </button> 
            {/*)}*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingItem;
