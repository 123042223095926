import { React, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg";
import EditProductContext from "../../context/editProductContext";
import { useTranslation } from 'react-i18next'; 


function EditDetails() {
    const { product_id } = useParams();
    const { productDetails, resources } = useContext(EditProductContext)
    const navigate = useNavigate();

    const location = useLocation();

    function goBack() {
        navigate(-1);
    }

    function save(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        const product = location.state.product;
		const int_fields = [
			"product_instock",
			"brand_name",
			"product_colors",
			"product_fabric",
			"p_recomm",
		];
        formData.forEach((value, name) => {

			if (int_fields.includes(name)) {
				product[name] = parseInt(value);
			}else{
				product[name] = value;
			}

            
        });

        navigate(`/seller/edit-product/${product_id}/sizes`, {
            state: { product },
        });
    }
    
	const handleClick = () => {
		navigate("/seller/guide");
	};

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    if (productDetails === undefined) return
    if (resources === undefined) return


    return (
			<>
				<div
					className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
					data-uk-grid>
					<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
						<img
							src="/images/side_banner.png"
							alt="Side Banner"
							className="uk-width-side-banner"
						/>
					</div>
					<div className="uk-flex uk-flex-center uk-flex-middle">
						<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
							<div className="uk-flex uk-flex-between uk-flex-middle">
								<div className="uk-flex">
									<button onClick={goBack} className="uk-button-noeffect">
										<ReactSVG
											src={arrowLeft}
											className="uk-icon-left uk-icon-black"
										/>
									</button>
									<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
										{t('listProduct')}
									</h1>
								</div>
								<ReactSVG src={info} className="uk-icon-info" onClick={handleClick} />
							</div>
							<form
								onSubmit={save}
								style={{ marginTop: "50px" }}
								className="uk-grid-small uk-child-width-1-1"
								data-uk-grid>
								<div>
									<label className="uk-form-label" htmlFor="product_title">
										{t('productTitle')}
									</label>
									<div className="uk-form-controls">
										<input
											name="product_title"
											id="product_title"
											className="uk-input"
											placeholder={t('enterProductTitle')}
											rows={4}
											defaultValue={productDetails.product_title}
                                            required
										/>
									</div>
								</div>
								<div>
									<label className="uk-form-label" htmlFor="product_desc">
										{t('itemDetails')}
									</label>
									<div className="uk-form-controls">
										<textarea
											name="product_desc"
											className="uk-textarea"
											id="product_desc"
											placeholder={t('writeBasicDetails')}
											rows={4}
											defaultValue={productDetails.product_desc}
                                            required
										/>
									</div>
								</div>
								<div>
									<label className="uk-form-label" htmlFor="brand_name">
										{t('brand')}
									</label>
									<div className="uk-form-controls">
										<ul className="uk-list uk-list-sort">
											<li>
												<select
													name="brand_name"
													id="brand_name"
													className="uk-select" required>
													{resources.brands_details.map((brand, index) => (
														<option key={index} value={brand.brand_id}>
															{brand.brand_name}
														</option>
													))}
												</select>
											</li>
										</ul>
									</div>
								</div>
								<div>
									<label className="uk-form-label" htmlFor="product_instock">
										{t('productStock')}
									</label>
									<div className="uk-form-controls">
										<input
											type="number"
											name="product_instock"
											id="product_instock"
											className="uk-input"
											defaultValue={productDetails.product_instock}
											placeholder={t('enterProductStock')}
                                            required
										/>
									</div>
								</div>
								<div>
									<div className="uk-grid uk-child-width-1-2" data-uk-grid>
										<div>
											<label className="uk-form-label" htmlFor="product_colors">
												{t('color')}
											</label>
											<div className="uk-form-controls">
												<ul className="uk-list uk-list-sort">
													<li>
														<select
															name="product_colors"
															id="product_colors"
															className="uk-select"
                                                            required
                                                            >
															{resources.colors_details.map((color, index) => (
																<option key={index} value={color.color_id}>
																	{color.color_name}
																</option>
															))}
														</select>
													</li>
												</ul>
											</div>
										</div>
										<div>
											<label className="uk-form-label" htmlFor="product_fabric">
												{t('fabric')}
											</label>
											<div className="uk-form-controls">
												<ul className="uk-list uk-list-sort">
													<li>
														<select
															name="product_fabric"
															id="product_fabric"
															className="uk-select" required>
															{resources.fabric_details.map((fabric, index) => (
																<option key={index} value={fabric.fabric_id}>
																	{fabric.fabric_name}
																</option>
															))}
														</select>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div>
									<label className="uk-form-label" htmlFor="p_recomm">
										{t('recommendedFor')}
									</label>
									<div className="uk-form-controls">
										<ul className="uk-list uk-list-sort">
											<li>
												<select
													name="p_recomm"
													id="p_recomm"
													className="uk-select"
                                                    required
													defaultValue={
														productDetails.product_recommendations_filter_option
															.recomm_id
													}>
													{resources.recommendation_details.map(
														(option, index) => (
															<option key={index} value={option.recomm_id}>
																{option.recomm_name}
															</option>
														)
													)}
												</select>
											</li>
										</ul>
									</div>
								</div>
								<div>
									<button
										type="submit"
										name="continue"
										className="uk-button uk-button-primary">
										{t('continue')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
}

export default EditDetails;
