import React, { useEffect, useRef, useState } from "react";
import Header from "../Helpers/Header";
import { ReactSVG } from "react-svg";
import star from "../../assets/svg_icons/star.svg";
import error from "../../assets/svg_icons/error.svg";
import search from "../../assets/svg_icons/search.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { routes, urls } from "../../Global";
import { useTranslation } from 'react-i18next'; 


function Ratings() {
    const [searchTerm, setSearchTerm] = useState(false);

    const timeoutRef = useRef(null);
    const [reviews, setReviews] = useState([]);
    const cookies = new Cookies();
    const user_id = cookies.get("id");

    const [bookingId, setBookingId] = useState(false);
    const [productId, setProductId] = useState(false);
    const [product, setProduct] = useState(false);
    const [booking, setBooking] = useState(false);
    const [selectedRating, setSelectedRating] = useState(null);
    const reviewTag = useRef(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const limit = 100;
    const offset = 0;

    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const reviews = await axios.get(
                urls.serverUrl + routes.productReviewByBooking + `/${user_id}`
            );
            setReviews(reviews.data.product_review_details);
        } catch (error) {
            console.error("Error fetching data:", error.response);
            setReviews([]);
        }
    };

    async function changeSearch(e) {
        setSearchTerm(e.target.value);

        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            fetchData(e.target.value);
        }, 500); // Adjust the delay as needed (in milliseconds)
    }

    async function submitReview() {
        const reviewText = reviewTag.current.value;

        try {
            const reviewSend = await axios.post(
                urls.serverUrl + routes.createProductReview + `/${user_id}`,
                {
                    product_id: productId,
                    booking_id: bookingId,
                    product_rating: selectedRating,
                    review_user_desc: reviewText,
                }
            );
            console.log(reviewSend);
            if (reviewSend.data.status === 200) {
                fetchData();
                setProductId(false);
                setBookingId(false);
                setSelectedRating(false);
                setProduct(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(function () {
        fetchData();
    }, []);

    useEffect(
        function () {
            if (selectedRating > 0) {
                setSubmitDisabled(false);
            } else {
                setSubmitDisabled(true);
            }
        },
        [selectedRating]
    );

    const openReview = (e) => {
        setBookingId(e.booking_id);
        setProductId(e.product_id);
        setProduct(e.product);
        setBooking(e);
    };

    const calculateDateDiff = (start, end) => {
        return Math.ceil(Math.abs(start.getTime() - end.getTime()) / (1000 * 3600 * 24));
    };

    const formDate = (start, end) => {
        return `${start.getDate()} ${start.toLocaleString("default", {
            month: "short",
        })} - ${end.getDate()} ${end.toLocaleString("default", { month: "short" })}`;
    };

    const handleItemClick = (rating) => {
        setSelectedRating(rating);
    };

    const [isConfirmationVisible, setConfirmationVisible] = useState(false);

    const openConfirmation = () => {
        setConfirmationVisible(true);
    };

    const closeConfirmation = () => {
        setConfirmationVisible(false);
    };

    const confirmSubmit = () => {
        submitReview();
        closeConfirmation();
    };

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return (
        <>
            <Header />
            <div className="uk-section-inbox chat-screen uk-width-1-1">
                <div className="uk-height-1-1 uk-width-1-1">
                    <div className="uk-grid uk-grid-inbox uk-height-1-1 uk-width-1-1" data-uk-grid>
                        <div className="uk-width-2-5@m uk-overflow-hidden">
                            <div className="uk-section-otp uk-flex uk-flex-between uk-flex-middle uk-hidden@m">
                                <div>
                                    <a
                                        href="#inbox-menu"
                                        data-uk-toggle=""
                                        className="uk-hamburger uk-style-tertiary uk-hidden@m uk-text-center uk-margin-left"
                                        aria-expanded="false">
                                        <span className="uk-navbar-toggle-icon">
                                            <svg
                                                className=""
                                                width="26"
                                                height="20"
                                                viewBox="0 0 26 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect y="0" width="26" height="2"></rect>
                                                <rect y="9" width="26" height="2"></rect>
                                                <rect y="18" width="26" height="2"></rect>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                <div></div>
                            </div>
                            <div className="uk-container uk-padding-horizontal-remove" style={{paddingLeft: '60px', paddingRight: '30px'}}>
                                <div className="uk-visible@m uk-height-1-1">
                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                        <div>
                                            <p className="uk-text-4 uk-text-fw-600">{t('inbox')}</p>
                                        </div>
                                        <div>
                                            <div className="uk-input-search">
                                                <ReactSVG src={search} />
                                                <input
                                                    className="uk-input"
                                                    placeholder={t('search')}
                                                    onChange={changeSearch}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        data-uk-slider
                                        className="uk-overflow-visible uk-margin-medium-top">
                                        <div className="uk-grid uk-slider-items uk-flex uk-flex-row uk-flex-center" data-uk-grid>
                                            <div>
                                                <NavLink
                                                    to="/inbox"
                                                    className="uk-button uk-button-white uk-button-rounded uk-text-default" style={{ padding: '8px 16px' }}>
                                                    {t('messages')}
                                                </NavLink>
                                            </div>
                                            <div>
                                                <NavLink
                                                    to="/inbox/notifications"
                                                    className="uk-button uk-button-white uk-button-rounded uk-text-default" style={{ padding: '8px 16px' }}>
                                                    {t('notifications')}
                                                </NavLink>
                                            </div>
                                            <div>
                                                <NavLink
                                                    to="/inbox/ratings"
                                                    className="uk-button uk-button-primary uk-button-rounded uk-text-default" style={{ padding: '8px 16px' }}>
                                                    {t('ratings')}
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="uk-list uk-list-chats">
                                        {reviews.map((review, index) => (
                                            <li
                                                key={index}
                                                onClick={(e) => {
                                                    openReview(review);
                                                }}>
                                                <div
                                                    className="uk-grid uk-grid-reviews uk-flex-middle"
                                                    data-uk-grid>
                                                    <div>
                                                        <img
                                                            src={
                                                                review.product.product_images[0].image_link ??
                                                                `/images/avatar.png`
                                                            }
                                                        />
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <p>
                                                            <strong>
                                                                {review.product.product_title ?? "John Doe"}
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-2-5@m uk-margin-remove uk-position-relative">
                            {product ? (
                                <div className="uk-width-review uk-section-review uk-margin-xlarge-left">
                                    <p className="uk-text-6-big uk-text-fw-600 uk-margin-remove">
                                        {t('writeReviewsFor')}
                                    </p>
                                    <div
                                        className="uk-flex uk-flex-wrap"
                                        style={{ marginTop: "20px" }}>
                                        <div>
                                            <img
                                                src={product.product_images[0].image_link}
                                                alt="Dress Pic"
                                            />
                                        </div>
                                        <div className="uk-width-expand">
                                            <p className="uk-text-fw-600 uk-text-6-big">
                                                {product.product_title}
                                            </p>
                                            <p className="uk-text-light uk-text-6">
                                                {calculateDateDiff(
                                                    new Date(booking.booked_current_date),
                                                    new Date(booking.booked_end_date)
                                                ) > 0
                                                    ? "Daily"
                                                    : "Once"}
                                                ,{" "}
                                                {formDate(
                                                    new Date(booking.booked_current_date),
                                                    new Date(booking.booked_end_date)
                                                )}
                                            </p>
                                            <div className="uk-card-review">
                                                <div className="uk-card-body">
                                                    <p>{t('rateProduct')}</p>
                                                    <div
                                                        className="uk-flex uk-flex-center uk-flex-middle"
                                                        style={{ marginTop: "14px" }}>
                                                        {[1, 2, 3, 4, 5].map((rating) => (
                                                            <ReactSVG
                                                                src={star}
                                                                key={rating}
                                                                onClick={() =>
                                                                    handleItemClick(rating)
                                                                }
                                                                className={`${
                                                                    selectedRating &&
                                                                    rating <= selectedRating
                                                                        ? "selected"
                                                                        : ""
                                                                } uk-icon-star uk-icon`}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <textarea
                                        className="uk-textarea uk-text-6-big"
                                        ref={reviewTag}
                                        placeholder={t('writeYourReviewHere')}></textarea>
                                    <div className="uk-text-right uk-margin-top">
                                        <button className="uk-button uk-button-error">
                                            <ReactSVG
                                                src={error}
                                                className="uk-margin-small-right"
                                            />
                                            {t('report')}
                                        </button>
                                        <button
                                            className="uk-button uk-button-primary uk-margin-small-left"
                                            onClick={openConfirmation}
                                            disabled={submitDisabled}>
                                            {t('submit')}
                                        </button>
                                    </div>
                                    {isConfirmationVisible && (
                                        <div className="uk-flex uk-flex-center uk-flex-middle" style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                            <div className="uk-card uk-card-default uk-card-body uk-text-center uk-flex-row" style={{ minWidth: '300px', maxWidth: '600px', width: '30%', zIndex: 1000 }}>
                                                <h3 className="uk-card-title uk-text-muted">{t('confirmSubmission')}</h3>
                                                <p style={{ margin: '20px 0px 20px 0px' }}><p>{t('doYouWantToSubmitReview')}</p></p>
                                                <button className="uk-button uk-button-primary uk-margin-small-right" 
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        confirmSubmit();
                                                    }}>
                                                    {t('submitButton')}
                                                </button>
                                                <button className="uk-button uk-button-danger uk-margin-small-left" onClick={closeConfirmation}>
                                                    {t('cancel')}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <p className="uk-text-center">{t('noReviewSelected')}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div id="inbox-menu" data-uk-offcanvas="overlay: true">
                    <div className="uk-offcanvas-bar mobile-menu">
                        <button
                            className="uk-offcanvas-close uk-color-primary"
                            type="button"
                            data-uk-close></button>
                        <ul className="uk-list uk-margin-large-top">
                            <li>
                                <NavLink to="/inbox" className="uk-color-primary">
                                    {t('messages')}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/inbox/notifications" className="uk-color-primary">
                                    {t('notifications')}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/inbox/ratings" className="uk-color-primary">
                                    {t('ratings')}
                                </NavLink>
                            </li>
                        </ul>
                        <ul className="uk-list uk-list-chats uk-margin-large-top">
                            {reviews.map((review, index) => (
                                <li
                                    key={index}
                                    onClick={(e) => {
                                        openReview(review);
                                    }}>
                                    {/* <div className="uk-grid uk-grid-chat uk-flex-top" data-uk-grid>
                    <div>
                      <img src={chat.user_photo ?? `/images/avatar.png`} />
                    </div>
                    <div className="uk-width-expand">
                      {chat.user_name ? (
                        <p>
                          <strong>{chat.user_name ?? "John Doe"}</strong>
                        </p>
                      ) : ("")}
                      <p className="uk-text-muted">
                        {chat.message.substring(0, 50)}...
                      </p>
                    </div>
                  </div> */}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ratings;
