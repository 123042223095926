import { React, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg";
import {calculateRent} from "../../lib/helper";
import { useTranslation } from 'react-i18next';

function Price() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

	const navigate = useNavigate();
	const [formState, setformState] = useState({
		product_price: "",
		product_age: "",
		mrp: "",
		number_of_usage: "",
	});

	const location = useLocation();

	const suggestedRentRange = calculateRent(
		formState.mrp,
		formState.product_age,
		formState.number_of_usage
	);

	const handleChange = (e) => {
		setformState((prev) => {
			const newd = { ...prev };
			newd[e.target.name] = e.target.value;
			return newd;
		});
	};

	function goBack() {
		navigate(-1);
	}

	function save(e) {
		e.preventDefault();
		const formData = new FormData(e.target);
		const product = location.state.product;
		formData.forEach((value, name) => {
			product[name] = value;
		});

		// product.suggested_amount_low = suggestedRentRange[0];
		// product.suggested_amount_high = suggestedRentRange[1];

		navigate("/seller/add-product/add", {
			state: { product },
		});
	}

	const handleClick = () => {
		navigate("/seller/Guide");
	};

	return (
		<>
			<div
				className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
					<img
						src="/images/side_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
				</div>
				<div className="uk-flex uk-flex-center uk-flex-middle">
					<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div className="uk-flex">
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left uk-icon-black"
									/>
								</button>
								<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
									{t('listProduct')}
								</h1>
							</div>
							<ReactSVG src={info} className="uk-icon-info" onClick={handleClick} />
						</div>
						<form
							onSubmit={save}
							style={{ marginTop: "50px" }}
							className="uk-grid-small uk-child-width-1-1"
							data-uk-grid>
							<div>
								<label className="uk-form-label" htmlFor="mrp">
									{t('enterMRP')}
								</label>
								<div className="uk-form-controls">
									<input
										type="number"
										name="mrp"
										id="mrp"
										placeholder={t('enterMRPInRupees')}
										className="uk-input"
										value={formState.mrp}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="product_age">
									{t('productAge')}
								</label>
								<div className="uk-form-controls">
									<input
										type="number"
										name="product_age"
										id="product_age"
										placeholder={t('enterProductAge')}
										className="uk-input"
										min={0}
										value={formState.product_age}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="number_of_usage">
									{t('numberOfUsage')}
								</label>
								<div className="uk-form-controls">
									<input
										type="number"
										name="number_of_usage"
										id="number_of_usage"
										placeholder={t('enterNumberOfTimesUsed')}
										className="uk-input"
										min={0}
										value={formState.number_of_usage}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="product_price">
									{t('enterRent')}
								</label>
								<div className="uk-form-controls">
									<input
										type="number"
										name="product_price"
										id="product_price"
										placeholder={t('enterRentInRupees')}
										className="uk-input"
										value={formState.product_price}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							{suggestedRentRange ? (
								<div>
									<p className="uk-text-success">
										{t('suggestedRentAmount')}{" "}
										<strong>₹{suggestedRentRange[0]}</strong> to{" "}
										<strong>₹{suggestedRentRange[1]}</strong>
									</p>
								</div>
							) : null}
							<div>
								<button
									type="submit"
									name="continue"
									className="uk-button uk-button-primary">
									{t('continue')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Price;
