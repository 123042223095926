import React from "react";

import Header from "../Helpers/Header";
import Trending from "../Content/Trending";
import { Footer } from "../Footer/Footer";

function Home() {
  return (
    <>
      <Header />
      <Trending />
      <Footer />
    </>
  );
}

export default Home;
