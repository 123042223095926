import React, { useState, useRef } from "react";
import camera from "../../assets/svg_icons/camera.svg";
import { ReactSVG } from "react-svg";
import { useEffect } from "react";

const FileUpload = ({ maxFiles, setParentFiles, accept = false }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  }; 

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const newFiles = [...e.dataTransfer.files];
    handleFileSelect(newFiles);
  };

  const handleFileSelect = (selectedFiles) => {
    setFiles((prevFiles) => {
      if (maxFiles && prevFiles.length >= maxFiles) return prevFiles;

      const filesWithBase64 = [];
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Url = event.target.result;
        filesWithBase64.push({
          file: selectedFiles[filesWithBase64.length],
          base64: base64Url.split(',')[1],
          base64UnCut: base64Url
        });
        if (filesWithBase64.length === selectedFiles.length)
          setFiles([...prevFiles, ...filesWithBase64]);
        else reader.readAsDataURL(selectedFiles[filesWithBase64.length]);
      };

      reader.readAsDataURL(selectedFiles[0]);

      return prevFiles;
    });
  };

  useEffect(
    function () {
      if (setParentFiles) {
        setParentFiles(files);
      }
    },
    [files, setParentFiles]
  );

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const fileList = files.map((file, index) => (
    <li key={file.file.name}>
      {file.file.name}
      <button
        data-uk-close
        onClick={(e) => {
          e.stopPropagation();
          removeFile(index);
        }}></button>
    </li>
  ));

  return (
    <div
      className={`dropzone ${dragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}>
      <div className="uk-flex uk-flex-center">
        <ReactSVG src={camera} />
        <p className="uk-color-green uk-margin-small-left">Upload here</p>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        onChange={(e) => handleFileSelect(Array.from(e.target.files))}
        multiple
        accept={accept}
        style={{ display: "none" }}
      />
      {fileList.length > 0 && <ul className="uk-list-files">{fileList}</ul>}
    </div>
  );
};

export default FileUpload;
