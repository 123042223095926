import React, { useEffect } from "react";
import Header from "../Components/Helpers/Header";
import { Footer } from "../Components/Footer/Footer";
import { useTranslation } from 'react-i18next';

export default function RentalTerms() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        } else {
          i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
        <Header/>
            <div className="uk-section">
                <div className="uk-container">
                    <div className="uk-wysiwyg-default">
                        <h1 className="uk-text-center">{t('rentalTerms')}</h1>
                        <p>{t('policiesGovernsReturn')} {t('userAbideByReturnPolicy')} {t('returnPolicyAppliedPostDelivery')} {t('referenceToUserAndWe')}</p>
                        <ol className="uk-list uk-list-decimal">
                            <li>
                                {t('companyPolicyAscertainProduct')}
                            </li>
                            <li>
                                {t('failureToReturnBySpecifiedDate')}
                            </li>
                            <li>
                                {t('failureToReturnAfterSpecifyDays')}
                            </li>
                            <li>
                                {t('productProperlyPacked')}
                            </li>
                            <li>
                                {t('companyReservesRight')}
                            </li>
                            <li>
                                {t('userChargedAsPenalty')}
                            </li>
                            <li>
                                {t('companyHoldsRightRegardingForfeiting')}
                            </li>
                            <li>
                                {t('refundableSecurityDepositFullyRefunded')}
                            </li>
                            <li>
                                {t('userAgreesAndUnderstands')}
                            </li>
                            <li>
                                {t('noRefundAfter3Hours')}
                            </li>
                            <li>
                                {t('userAndCompanyGovernedByPolicies')}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}