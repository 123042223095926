import { useEffect } from "react";
import ReactDOM from "react-dom";

const ModalPortal = (props) => {
  const modalRoot = document.createElement("div");
  modalRoot.setAttribute("data-uk-modal", "bg-close: false");
  modalRoot.id = props.id;
  modalRoot.classList = props.className;

  useEffect(() => {
    document.body.appendChild(modalRoot);
    return () => {
      document.body.removeChild(modalRoot);
    };
  });

  return ReactDOM.createPortal(props.children, modalRoot);
};

export default ModalPortal;
