import React, { useEffect, useRef, useState } from "react";
import Header from "../Helpers/Header";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import bookedIcon from "../../assets/svg_icons/booked.svg";
import wallet from "../../assets/svg_icons/wallet.svg";
import report from "../../assets/svg_icons/report.svg";
import error from "../../assets/svg_icons/error.svg";
import tick from "../../assets/svg_icons/tick.svg";
import star from "../../assets/svg_icons/star.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import UIkit from "uikit";
import Cookies from "universal-cookie";
import { NavLink } from "react-router-dom";
import { calculateDateDiff, getCoins } from "../../lib/helper";
import axios from "axios";
import { urls, routes } from "../../Global";
import { useTranslation } from 'react-i18next';


function Booking() {

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        } else {
          i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    const [product, setProduct] = useState(false);
    const [bookingDetails, setBookingDetails] = useState(false);
    const [storeDetails, setStoreDetails] = useState(false);
    const [dateDiff, setDateDiff] = useState(false);
    const [bookingData, setBookingData] = useState(false);
    const [booked, setBooked] = useState(false);
    const [rate, setRate] = useState(false);
    const [totalPrice, setTotalPrice] = useState(false);
    const [payButtonDisabled, setPayButtonDisabled] = useState(true);
    const [payLink, setPayLink] = useState("");

    const [validateBooking, setValidateBooking] = useState({
        terms: false,
        paymentMethod: true,
        disabled: true,
    });
    const location = useLocation();
    const navigate = useNavigate();
    const coinsRef = useRef();
    const cookies = new Cookies();

    const id = cookies.get("id");

    let calculated_price = product.product_price
        ? calculateBookingPrice(
              calculateDayCountBetweenDates(bookingDetails.startDate, bookingDetails.endDate),
              product.product_price
          )
        : product.product_price;

    useEffect(
        function () {
            if (location.state) {
                setProduct(location.state.product);
                setStoreDetails(location.state.storeDetails);
                setBookingDetails(location.state.bookingDetails);
            }
        },
        [location.state]
    );

    useEffect(
        function () {
            setTotalPrice(parseInt(product.product_price) + parseInt(calculated_price));
        },
        [product, calculated_price]
    );

    useEffect(
        function () {
            bookingDetails &&
                setDateDiff(
                    calculateDateDiff(
                        bookingDetails.startDate.getTime() - bookingDetails.endDate.getTime()
                    )
                );
        },
        [bookingDetails]
    );

    useEffect(
        function () {
            async function book() {
                if (bookingData) {
                    navigate("/payments", {
                        state: bookingData,
                    });
                }
            }
            // async function book() {
            //     const booking = await axios.post(
            //         urls.serverUrl + routes.bookingAdd + `/${bookingData.user_id}`,
            //         bookingData
            //     );

            //     const payment = await axios.post(
            //         urls.serverUrl + routes.paymentAdd + `/${bookingData.user_id}`,
            //         {
            //             booking_id: booking.data.booking_id,
            //             product_id: bookingData.product_id,
            //             user_id: bookingData.user_id,
            //             paid_amount: bookingData.total_price,
            //             paid_status: bookingData.booking_payments_status,
            //             upi_pay_status: "upi",
            //             payment_method: "phonepe",
            //             reseller_id: bookingData.reseller_id,
            //             coin: bookingData.coin,
            //         }
            //     );

            //     const ccavlink = await axios.post(urls.serverUrl + routes.ccavLink, {
            //         billing_name: bookingData.user_id,
            //         order_id: booking.data.booking_id,
            //         amount: bookingData.total_price,
            //     });

            //     if (ccavlink.data) {
            //         console.log(ccavlink.data.payLink);
            //         const payLinkUrl = ccavlink.data.payLink;
            //         setPayLink(ccavlink.data.payLink);
            //     }
            // }

            bookingData && book();
        },
        [bookingData]
    );

    useEffect(
        function () {
            if (booked) {
                setTimeout(function () {
                    const modal = UIkit.modal("#booking_on_hold");
                    if (modal) {
                        modal.show();
                    }
                }, 1000);
            }
        },
        [booked]
    );

    useEffect(
        function () {
            if (rate) {
                setTimeout(function () {
                    const modal = UIkit.modal("#rate");
                    if (modal) {
                        modal.show();
                    }
                }, 1000);
            }
        },
        [rate]
    );

    function goBack() {
        navigate(-1);
    }

    function handleTermsChecked(e) {
        const { checked } = e.target;

        setValidateBooking((prevBooking) => ({
            ...prevBooking,
            terms: checked,
        }));
    }

    // async function handlePaymentMethodChange(e) {
    //   const { value } = e.target;

    //   if (value == "wallet-coins") {
    //     coinsRef.current.textContent = "You can only use upto 5% of the total amount"
    //   } else {
    //     coinsRef.current.textContent = "Use wallet coins to pay ₹2000"
    //   }

    //   setValidateBooking((prevBooking) => ({
    //     ...prevBooking,
    //     paymentMethod: value,
    //   }));
    // }
const startDate = parseDateString(bookingDetails.startDate);
const endDate = parseDateString(bookingDetails.endDate);

const formattedStartDate = formatDate(startDate);
const formattedEndDate = formatDate(endDate);

async function bookProduct() {
    if (validateAllProperties(validateBooking)) {
        var acceptedCoins = 0;
        if (validateBooking.paymentMethod == "wallet-coins") {
            const availableCoins = await getCoins();
            if (availableCoins >= totalPrice * 0.05) {
                acceptedCoins = totalPrice * 0.05;
            } else {
                acceptedCoins = availableCoins;
            }
        }

        setBookingData({
            product_id: product.product_id,
            user_id: id,
            product_price: product.product_price,
            total_price: totalPrice,
            booking_location: "kolkata",
            payment_method: "Phonepe",
            booking_payments_status: 2,
            booked_current_date: formattedStartDate,
            booked_end_date: formattedEndDate,
            reseller_id: storeDetails.reseller_id,
            coin: acceptedCoins,
        });
    }
}

console.log("this2", formattedStartDate, formattedEndDate);
console.log("this", bookingDetails.startDate, bookingDetails.endDate);

function parseDateString(dateString) {
    if (!dateString) {
        console.error("Invalid date string");
        return null;
    }

    const dateObject = new Date(dateString);

    if (isNaN(dateObject.getTime())) {
        console.error("Invalid date format:", dateString);
        return null;
    }

    return dateObject;
}

function formatDate(date) {
    if (!date) {
        console.error("Invalid date");
        return null;
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}





    const validateAllProperties = (obj) => {
        return Object.values(obj).every((value) => value);
    };

    useEffect(
        function () {
            if (
                validateBooking.paymentMethod != false &&
                validateBooking.terms != false &&
                validateBooking.disabled != false
            ) {
                setPayButtonDisabled(false);
            } else {
                setPayButtonDisabled(true);
            }
        },
        [validateBooking]
    );

    return (
        <>
            <Header />
            <div className="uk-section uk-section-booking">
                {product ? (
                    <>
                        <div className="uk-container">
                            <div className="uk-flex uk-flex-between uk-flex-middle">
                                <div className="uk-flex uk-flex-middle">
                                    <button onClick={goBack} className="uk-button-noeffect">
                                        <ReactSVG
                                            src={arrowLeft}
                                            className="uk-icon-left uk-icon-black"
                                        />
                                    </button>
                                    <p className="uk-text-4 uk-text-fw-600 uk-margin-left">
                                        {t('booking')}
                                    </p>
                                </div>
                                <div>
                                    <button onClick={goBack} className="uk-button-noeffect">
                                        <p className="uk-text-5-small uk-color-red uk-text-fw-600">
                                            {t('clearAll')}
                                        </p>
                                    </button>
                                </div>
                            </div>
                            <div
                                className="uk-grid uk-grid-booking uk-child-width-1-2@m"
                                data-uk-grid>
                                <div>
                                    <div className="uk-card uk-card-cart-item">
                                        <div className="uk-card-body uk-flex uk-flex-between" style={{padding: '12px'}} >
                                            <div>
                                                <img
                                                    src={product.product_images[0].image_link}
                                                    alt={product.product_title}
                                                    style={{ objectFit: "contain", width: "160px" }}
                                                />
                                            </div>
                                            <div className="uk-flex uk-flex-between uk-flex-column uk-width-expand">
                                                <div>
                                                    <p className="uk-text-6-big">
                                                        <strong>{product.product_title}</strong>
                                                    </p>
                                                    <p
                                                        className="uk-text-6"
                                                        style={{
                                                            marginTop: "10px",
                                                            fontWeight: "300",
                                                        }}>
                                                        {t('size')} 
                                                        <span style={{ border: '1px solid #000', padding: '1px', borderRadius: '4px', fontSize: '12px', marginLeft: '4px' }}>
                                                            
                                                            {t('thighs')}: {product.thighs_size_low},
                                                            {t('chest')}: {product.chest_size_low},
                                                            {t('waist')}: {product.waist_size_low},
                                                            {t('shoulder')}: {product.shoulder_size_low}
                                                        </span>
                                                    </p>
                                                    <p
                                                        className="uk-text-6"
                                                        style={{
                                                            marginTop: "10px",
                                                            fontWeight: "300",
                                                        }}>
                                                        {dateDiff > 0 ? "Daily" : "Once"},
                                                        {" " +
                                                            bookingDetails.startDate.getDate() +
                                                            " " +
                                                            bookingDetails.startDate.toLocaleString(
                                                                "default",
                                                                {
                                                                    month: "short",
                                                                }
                                                            )}{" "}
                                                        -{" "}
                                                        {bookingDetails.endDate.getDate() +
                                                            " " +
                                                            bookingDetails.endDate.toLocaleString(
                                                                "default",
                                                                {
                                                                    month: "short",
                                                                }
                                                            )}
                                                    </p>
                                                    <p
                                                        className="uk-pill"
                                                        style={{ marginTop: "12px" }}>
                                                        {product.product_size}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Link
                                                        to={`/product/${product.product_id}`}
                                                        className="uk-button uk-button-primary"
                                                        style={{ marginTop: "10px" }}>
                                                        {t('editAvailability')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <p className="uk-input-wrapper uk-margin-medium-top">
                                        <input
                                            type="checkbox"
                                            id="terms"
                                            name="terms"
                                            onChange={handleTermsChecked}
                                        />
                                        <label
                                            htmlFor="terms"
                                            className="uk-margin-xsmall-left uk-text-6-big uk-text-fw-600">
                                            {t('acceptRentalTerms')}
                                        </label>
                                    </p>
                                    <a
                                        href="/rental_policy.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="uk-button uk-button-success">
                                        <ReactSVG src={report} />
                                        <span className="uk-margin-small-left">{t('rentalTerms')}</span>
                                    </a>
                                </div>
                                <div>
                                    <p className="uk-flex uk-flex-between">
                                        <span className="uk-text-muted uk-text-6-xbig">
                                            <strong className="uk-text-5-small uk-color-primary uk-text-fw-600 uk-margin-small-right">
                                                {t('price')}
                                            </strong>
                                            {t('asPerSelectedDates')}
                                        </span>
                                        <span className="uk-color-green uk-text-3-small">
                                            <strong className="uk-text-fw-600">
                                                ₹{calculated_price}
                                            </strong>
                                        </span>
                                    </p>
                                    <p className="uk-flex uk-flex-between uk-margin-top">
                                        <span className="uk-text-muted uk-text-6-xbig">
                                            <strong className="uk-text-5-small uk-color-primary uk-text-fw-600 uk-margin-small-right">
                                                {t('securityAmount')}
                                            </strong>
                                            {t('standardRates')}
                                        </span>
                                        <span className="uk-color-green uk-text-3-small">
                                            <strong className="uk-text-fw-600">
                                                ₹{product.product_price}
                                            </strong>
                                        </span>
                                    </p>
                                    <hr />
                                    {/* <ul className="uk-list uk-list-booking-options">
                    <li>
                      <input
                        type="radio"
                        name="payment_options"
                        value="wallet-coins"
                        checked={
                          validateBooking.paymentMethod === "wallet-coins"
                        }
                        onChange={handlePaymentMethodChange}
                      />
                      <ReactSVG src={wallet} className="uk-icon-wallet" />
                      <div>
                        <p className="uk-text-fw-600 uk-text-5-xsmall">
                          Wallet Coins
                        </p>
                        <p className="uk-text-muted uk-text-6-big" ref={coinsRef}>
                          Use wallet coins to pay ₹2000
                        </p>
                      </div>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="payment_options"
                        value="other"
                        checked={validateBooking.paymentMethod === "other"}
                        onChange={handlePaymentMethodChange}
                      />
                      <div>
                        <p className="uk-text-fw-600 uk-text-5-xsmall">
                          Don't use wallet coins
                        </p>
                      </div>
                    </li>
                  </ul> */}
                                    {/* <div>
                    <p
                      className="uk-text-5-xsmall uk-text-fw-600"
                      style={{ marginTop: "68px" }}>
                      Coupon Code:
                    </p>
                    <div className="uk-flex uk-input-wrapper uk-margin-small-top">
                      <input
                        type="text"
                        name="number"
                        id="phone"
                        placeholder="Enter code here..."
                        className="uk-input"
                      />
                      <button className="uk-button uk-button-black-bg uk-margin-small-left">
                        Apply
                      </button>
                    </div>
                  </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="uk-section uk-section-booking fixed">
                            <div className="uk-container">
                                <div className="uk-flex uk-flex-between uk-flex-middle">
                                    <div>
                                        <p className="uk-text-6-big uk-text-fw-600">
                                            {t('totalPayable')}:
                                        </p>
                                        <p style={{ marginTop: "5px" }}>
                                            <span className="uk-color-green uk-text-5-small">
                                                <strong className="uk-text-fw-600">
                                                    ₹{totalPrice}
                                                </strong>
                                            </span>
                                        </p>
                                    </div>

                                    <button
                                        className="uk-button uk-button-primary"
                                        onClick={bookProduct}
                                        disabled={payButtonDisabled}>
                                        {t('payNow')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            id="booking_on_hold"
                            className="uk-modal-booking-hold"
                            data-uk-modal="bg-close: false;">
                            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                                <ReactSVG src={bookedIcon} />
                                <p className="uk-text-6-big uk-text-fw-600">BOOKING ON HOLD</p>
                                <p className="uk-text-6 uk-text-muted">
                                    {t('waitingForConfirmation')},
                                </p>
                                <p className="uk-text-9 uk-text-muted">
                                    {t('exploreMoreProducts')}
                                </p>
                                <NavLink to="/" className="uk-button uk-button-primary">
                                    {t('exploreMore')}
                                </NavLink>
                            </div>
                        </div>
                        <div
                            id="rate"
                            className="uk-modal-booking-rate"
                            data-uk-modal="bg-close: false;">
                            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                                <p className="uk-text-6 uk-text-fw-600 uk-text-center">
                                    Rate the product
                                </p>
                                <div className="uk-grid uk-grid-rate" data-uk-grid>
                                    <div>
                                        <img
                                            src={product.product_image}
                                            alt={product.product_title}
                                        />
                                    </div>
                                    <div>
                                        <p className="uk-text-6-xbig uk-text-fw-600">
                                            {product.product_title}
                                        </p>
                                        <p className="uk-text-muted uk-text-8-big uk-text-fw-400">
                                            {dateDiff > 0 ? "Daily" : "Once"},
                                            {" " +
                                                bookingDetails.startDate.getDate() +
                                                " " +
                                                bookingDetails.startDate.toLocaleString("default", {
                                                    month: "short",
                                                })}{" "}
                                            -{" "}
                                            {bookingDetails.endDate.getDate() +
                                                " " +
                                                bookingDetails.endDate.toLocaleString("default", {
                                                    month: "short",
                                                })}
                                        </p>
                                        <div className="uk-flex ">
                                            <ReactSVG src={tick} />
                                            <p className="uk-color-green uk-margin-xsmall-left">
                                                Successfully Rented
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-flex uk-flex-center uk-stars">
                                    <ReactSVG src={star} />
                                    <ReactSVG src={star} />
                                    <ReactSVG src={star} />
                                    <ReactSVG src={star} />
                                    <ReactSVG src={star} />
                                </div>

                                <textarea
                                    rows="1"
                                    className="uk-textarea"
                                    placeholder="Write your review here..."></textarea>
                                <div className="uk-text-right" style={{ marginTop: "18px" }}>
                                    <button className="uk-button uk-button-error">
                                        <ReactSVG src={error} className="uk-margin-small-right" />
                                        Report
                                    </button>
                                    <button className="uk-button uk-button-primary uk-margin-small-left">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="uk-container">
                        <h1 className="uk-text-center">No Product Selected</h1>
                    </div>
                )}
            </div>
        </>
    );
}

export default Booking;

function calculateBookingPrice(bookingDays = 0, originalPrice = 0) {
    let calculatedPrice = originalPrice;
    if (bookingDays > 3) {
        // Calculate the number of extra days.
        const extraDays = bookingDays - 3;

        // Limit the number of extra days to 3.
        const limitedExtraDays = extraDays > 3 ? 3 : extraDays;

        // Declare the interest rate.
        let interestRate = 0.25;

        // Initialize the interest variable.
        let interest = 0.0;

        // Calculate the interest for each extra day.
        for (let i = 1; i <= limitedExtraDays; i++) {
            interest += originalPrice * interestRate;
            interestRate -= 0.05;
        }

        // Add the interest to the calculated price.
        calculatedPrice += interest;
    }

    if (bookingDays > 6) {
        // Calculate the number of extra days.
        const extraDays = bookingDays - 6;

        // Add the extra days discount to the calculated price.
        calculatedPrice += 0.15 * originalPrice * extraDays;
    }

    return calculatedPrice;
}

function calculateDayCountBetweenDates(startDate, endDate) {
    // Calculate the difference in milliseconds between the two dates.
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

    // Convert the difference in milliseconds to days.
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Return the difference in days.
    return differenceInDays + 1;
}
