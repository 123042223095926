import React, { useEffect, useState } from "react";
import Header from "../Helpers/Header";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info.svg";
import copy from "../../assets/svg_icons/copy.svg";
import whatsApp from "../../assets/svg_icons/whatsApp.svg";
import tshirt from "../../assets/svg_icons/tshirt.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { routes, urls } from "../../Global";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next';

function Wallet() {
    const [walletAmount, setWalletAmount] = useState(false);
    const [transaction, setTransaction] = useState("renting");
    const [transactions, setTransactions] = useState([]);
    const [dateDiff, setDateDiff] = useState(false);

    const navigate = useNavigate();
    const cookies = new Cookies();
    const id = cookies.get("id"); 


    function goBack() {
        navigate(-1);
    }

    useEffect(function () {
        async function fetchWallet() {
            try {
                let wallet = await axios.get(urls.serverUrl + routes.userVerified + `/${id}`);
                console.log(wallet);
                setWalletAmount(wallet.data.user_details[0].coin);
            } catch (err) {
                // console.log(err)
                setWalletAmount(0);
            }
        }

        async function fetchTransactionHistory() {
            try {
                let transactions = await axios.get(
                    urls.serverUrl + routes.transactionHistory + `/${id}`
                );
                console.log(transactions);
                setTransactions(transactions.data.data);
            } catch (err) {
                // console.log(err)
            }
        }
        fetchWallet();
        fetchTransactionHistory();
    }, []);

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return (
        <>
            <Header />
            <div className="uk-section uk-section-wallet uk-background-green uk-light uk-padding-remove-bottom">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <button onClick={goBack} className="uk-button-noeffect">
                                <ReactSVG src={arrowLeft} className="uk-icon-left" />
                            </button>
                        </div>
                        <div>
                            <ReactSVG src={info} className="uk-icon-info" />
                        </div>
                    </div>
                    <div style={{ marginTop: "100px" }} className="uk-text-center">
                        <p className="uk-text-center uk-text-1">{walletAmount} {t('coins')}</p>
                        <p className="uk-margin-xsmall-top uk-text-3">{t('balance')}</p>
                        <img src="/images/coin.png" alt="Coin" className="uk-width-coin" />
                    </div>
                </div>
            </div>
            <div className="uk-section uk-section-wallet uk-background-muted uk-padding-remove">
                <div className="uk-container uk-container-wallet">
                    <div className="uk-padding uk-background-default">
                        <div className="uk-card uk-card-watchdemo">
                            <div className="uk-card-body uk-text-right@m">
                                <p className="uk-text-5-xsmall uk-text-fw-600">
                                    {t('howToUseCoins')}
                                </p>
                                <p className="uk-margin-medium-top">
                                    <a href="#" className="uk-button uk-button-black">
                                        {t('watchDemo')}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="uk-card uk-card-invite uk-background-muted">
                            <div className="uk-card-body uk-text-center">
                                <p className="uk-text-5 uk-text-fw-600">{t('inviteFriends')}</p>
                                <p
                                    className="uk-text-muted uk-text-5-xsmall uk-text-fw-500"
                                    style={{ marginTop: "5px" }}>
                                    {t('getBonusCoins')}
                                </p>
                                <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-medium-top">
                                    <div className="uk-share uk-width-expand">
                                        <span className="uk-text-6-xbig">
                                            demoapp.com/invitefriends/keshave3254...
                                        </span>
                                        <ReactSVG src={copy} style={{ marginLeft: "30px" }} />
                                    </div>
                                    <div className="uk-share uk-margin-left square">
                                        <ReactSVG src={whatsApp} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="uk-list uk-list-transactions-title">
                            <li
                                className={
                                    transaction === "renting"
                                        ? "uk-active uk-text-5"
                                        : "uk-text-muted uk-text-5-xsmall"
                                }>
                                {t('rentingTransactions')}
                            </li>
                            <li
                                className={
                                    transaction === "affiliate"
                                        ? "uk-active uk-text-5"
                                        : "uk-text-muted uk-text-5-xsmall"
                                }>
                                {t('affiliateTransactions')}
                            </li>
                        </ul>
                        {transactions ? (
                            <ul className="uk-list uk-list-transactions">
                                {transactions.map((transaction, index) => (
                                    <li data-uk-margin="margin: uk-margin-top" key={index}>
                                        <ReactSVG src={tshirt} className="uk-icon-tshirt" />
                                        <div>
                                            <p className="uk-text-5 uk-text-fw-600">
                                                {transaction.product_title}
                                            </p>
                                            <p className="uk-text-5-xsmall uk-text-muted">
                                                {new Date(
                                                    transaction.transaction_dt
                                                ).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "short",
                                                    year: "numeric",
                                                })}
                                            </p>
                                        </div>
                                        <div className="uk-width-expand uk-flex uk-flex-right@m uk-flex-middle">
                                            <img
                                                src="/images/coin-full.png"
                                                className="uk-width-coin-full"
                                                alt="Coin"
                                            />
                                            <p className="uk-text-4 uk-text-fw-600 uk-margin-xsmall-left">
                                                {transaction.coins}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Wallet;
