import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { routes, urls } from "../../Global";
import Cookies from "universal-cookie";
import Header from "../Helpers/Header";
import CartItem from "./CartItem";
import search from "../../assets/svg_icons/search.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from 'react-i18next';


function Wishlist() {
  const [cart, setCart] = useState([]);
  const cookies = new Cookies();
  const id = cookies.get("id");
  const [searchTerm, setSearchTerm] = useState(false);
  const timeoutRef = useRef(null);
  const [searchFound, setSearchFound] = useState(null);

  function removeCartItem(id) {
    const newcart = cart.filter(function (item) {
      if (item.cart_id !== id) {
        return item;
      }
      return false;
    });
    setCart(newcart);
  }

  async function changeSearch(e) {
    if (e.target.value === "") {
      await getCart();
      return;
    }
    clearTimeout(timeoutRef.current);

    setSearchTerm(e.target.value);

    timeoutRef.current = setTimeout(() => {
      fetchData(e.target.value);
    }, 500); // Adjust the delay as needed (in milliseconds)
  }

  const fetchData = async (value) => {
    try {
      const wishlist = await axios.get(
        urls.serverUrl +
          routes.getCart +
          `/${id}?limit=10&offset=0&search_term=${searchTerm}`
      );
      setCart(wishlist.data.booking);
      setSearchFound(true);
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
      setSearchFound(false);
    }
  };

  async function getCart() {
    try {
      const wishlist = await axios.get(
        urls.serverUrl +
          routes.getCart +
          `/${id}?limit=10&offset=0&wishlistSearch=false`
      );
      setCart(wishlist.data.booking);
      setSearchFound(true);
    } catch (err) {
      console.log(err.response);
    }
  }

  useEffect(
    function () {
      getCart();
    },
    [id]
  );

  const storedLanguage = localStorage.getItem('language');
  // console.log(storedLanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
    } else {
        i18n.changeLanguage('en');
    }
  }, [storedLanguage, i18n]);

  return (
    <>
      <Header />
      <div className="uk-section">
        <div className="uk-container">
          {cart.length > 0 ? (
            <>
              <div className="uk-flex uk-flex-between uk-flex-middle">
                <div>
                  <p className="uk-text-4 uk-text-fw-600">{t('wishlist')}</p>
                </div>
                <div>
                  <div className="uk-input-search">
                    <ReactSVG src={search} />
                    <input
                      className="uk-input"
                      placeholder={t('search')}
                      onChange={changeSearch}
                    />
                  </div>
                </div>
              </div>
              {searchFound === false ? (
                <p className="uk-text-center">
                  {t('couldNotFindSearchTerm')}
                </p>
              ) : (
                <div
                  className="uk-grid uk-child-width-1-2@m uk-child-width-1-3@l uk-grid-wishlist"
                  data-uk-grid
                >
                  {cart.map((cartItem, index) => (
                    <div key={index}>
                      <CartItem item={cartItem} remove={removeCartItem} />
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              <h1 className="uk-text-center">{t('cartIsEmpty')}</h1>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Wishlist;
