import { useRef, useState, useEffect } from "react";
import { formatDate } from "../lib/helper";
import axios from "axios";
import { routes, urls } from "../Global"; 
import { useTranslation } from 'react-i18next';


const NotificationItem = ({ notification, reset, disabled = false }) => {
    const startDate = new Date(notification.booked_current_date);
    const endDate = new Date(notification.booked_end_date);
    const userId = notification.user_id;
    const resellerId = notification.reseller_id;
    const bookingId = notification.booking_id;
    console.log("notification ", notification);

    const [bookingDisabled, setBookingDisabled] = useState(disabled)

    const confirmRef = useRef(null)
    const declineRef = useRef(null)

    async function acceptBooking() {
        try {
            const data = await axios.patch(urls.serverUrl + routes.bookingStatusChange + `/${resellerId}`, {
                bookingid: bookingId,
                bookingstatus: 1,
                user_id: userId
            })
            console.log("accepted", data)
            if (data.data.status === 200) {
                confirmRef.current.textContent = "Order Confirmed";
                setBookingDisabled(true)

                setTimeout(async function () {
                    await reset()
                }, 2000)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function declineBooking() {
        try {
            console.log("declined", bookingId, userId)
            const data = await axios.patch(urls.serverUrl + routes.bookingStatusChange + `/${resellerId}`, {
                bookingid: bookingId,
                bookingstatus: 5,
                user_id: userId
            })
            console.log("declined", data)
            if (data.data.status === 200) {
                declineRef.current.textContent = "Order Declined";
                setBookingDisabled(true)

                setTimeout(async function () {
                    await reset()
                }, 2000)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [isConfirmationVisible, setConfirmationVisible] = useState(false);

    const openConfirmation = () => {
        setConfirmationVisible(true);
    };

    const closeConfirmation = () => {
        setConfirmationVisible(false);
    };

    const confirmSubmit = () => {
        acceptBooking();
        closeConfirmation();
    };

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);


    return (
        <>
        <div className="uk-card uk-card-notification-item uk-position-relative">
            {bookingDisabled ? (
                <div className="uk-background-wash uk-position-cover"></div>
            ) : ("")}
            <div className="uk-card-body">
                <div data-uk-grid className="uk-grid uk-grid-small">
                    <div className="uk-width-1-4@m">
                        <img src={notification?.product?.product_images?.[0]?.image_link || `/images/avatar.png`} alt={notification?.product_title || 'Default Alt Text'} />
                    </div>
                    <div className="uk-width-expand" data-uk-margin="margin: uk-margin-xsmall-top">
                        <p>{t('newBookingRequest')}</p>
                        <p className="uk-text-muted">{notification.user.user_name ?? "John Doe"} {t('wantsToRentThisProductFrom')}</p>
                        <p className="uk-text-bold">
                            {formatDate(startDate) + " to " + formatDate(endDate)}
                        </p>
                    </div>
                </div>
                <div className="uk-grid uk-grid-small uk-child-width-1-2" data-uk-grid style={{ marginTop: "30px" }}>
                    <div>
                        <button className="uk-button uk-width-1-1 uk-button-primary" onClick={openConfirmation}  ref={confirmRef}>{t('accept')}</button>
                    </div>
                    <div>
                        <button className="uk-button uk-width-1-1 uk-button-danger" onClick={declineBooking} ref={declineRef}>{t('decline')}</button>
                    </div>
                </div>
                {isConfirmationVisible && (
                    <div className="uk-flex uk-flex-center uk-flex-middle" style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <div className="uk-card uk-card-default uk-card-body uk-text-center uk-flex-row" style={{ minWidth: '300px', maxWidth: '600px', width: '30%', zIndex: 1000, padding: '20px', borderRadius: '5px' }}>
                            <h3 className="uk-card-title uk-text-muted">{t('confirmBooking')}</h3>
                            <p style={{ margin: '20px 0px 20px 0px' }}>{t('doYouWantToAcceptTheBooking')}</p>
                            <button className="uk-button uk-button-primary uk-margin-small-right" 
                                onClick={acceptBooking} >
                                {t('confirm')}
                            </button>
                            <button className="uk-button uk-button-danger uk-margin-small-left" onClick={closeConfirmation}>
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </>
    );
}

export default NotificationItem;
