import Calender from "./Calender";
import AddProduct from "./AddProduct";


function Seller() {
    return (
        <>
            <Calender />
            <AddProduct />
        </>
    );
}

export default Seller;
