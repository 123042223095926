import React from "react";
import { useNavigate } from "react-router-dom";


const Welcome = () => {

	const navigate=useNavigate()
	navigate("/")

	return (
		<div
			style={{
				backgroundColor: "#F4FFF9",
				height: "100vh",
			}}>
			<div className="uk-container">
				<div className="uk-section uk-flex uk-flex-column uk-flex-center">
					<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
						<img
							src="/images/side_banner.png"
							alt="Side Banner"
							className="uk-width-side-banner"
						/>
					</div>
					<div>
						<h1 className="uk-text-center uk-text-4 uk-text-fw-600">
							Coming Soon!
						</h1>
						{/* <h3 className="uk-text-center uk-text-2 uk-text-fw-600 uk-text-primary">
							Anywhere Cloths
						</h3> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Welcome;
