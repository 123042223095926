import { React, useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg";
import Alert from "../../Components/Helpers/Alert";
import EditProductContext from "../../context/editProductContext";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlacesSearch from "../../Components/PlaceSearch/PlacesSearch";
import { useTranslation } from 'react-i18next';


function EditCategory() {

    const { productDetails, resources } = useContext(EditProductContext)

    const { product_id } = useParams();
    const navigate = useNavigate();

    const [responseMessage, setResponseMessage] = useState({
        type: "",
        message: "",
    });

    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    // });

    const [clickedLocation, setClickedLocation] = useState({
        lat: parseFloat(productDetails.latitude),
        lng: parseFloat(productDetails.longitude),
		address: productDetails.address
    });
    const [currentLocation, setCurrentLocation] = useState({
        lat: parseFloat(productDetails.latitude),
        lng: parseFloat(productDetails.longitude)
    });

    const mapStyles = {
        height: "400px",
        width: "100%"
    };

    const handleMapClick = (event) => {
        setClickedLocation({
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        });
    };

    function goBack() {
        navigate(-1);
    }

    function save(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        const product = {};
        if (!clickedLocation) {
            setResponseMessage({type:"danger", message:"Please select location!"});
			return;
        }

		product.latitude = clickedLocation.lat.toString();
		product.longitude = clickedLocation.lng.toString();
		product.address = clickedLocation.address;
		
		// product.product_videos = productDetails.product_videos;

        formData.forEach((value, name) => {
            product[name] = parseInt(value);
        });

        navigate(`/seller/edit-product/${product_id}/details`, {
            state: { product },
        });
    }

    const handleClick = async () => {
        try {
    		navigate("/seller/guide");
            const response = await fetch('https://api.anywherecloths.com/api/v1/guide/get?limit=1&offset=0');
            if (response.ok) {
                const data = await response.json();
                console.log(data);
            } else {
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return (
			<>
				<div  
					className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
					data-uk-grid> 
					<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
						<img
							src="/images/side_banner.png"
							alt="Side Banner"
							className="uk-width-side-banner"
						/>
					</div>
					<div className="uk-flex uk-flex-center uk-flex-middle">
						<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
							<div className="uk-flex uk-flex-between uk-flex-middle">
								<div className="uk-flex">
									<button onClick={goBack} className="uk-button-noeffect">
										<ReactSVG
											src={arrowLeft}
											className="uk-icon-left uk-icon-black"
										/>
									</button>
									<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
										{t('listProduct')}
									</h1>
								</div>
								<ReactSVG src={info} className="uk-icon-info" onClick={handleClick}
            style={{ cursor: 'pointer' }}/> 
							</div>
							<form
								onSubmit={save}
								style={{ marginTop: "50px" }}
								className="uk-grid-small uk-child-width-1-1"
								data-uk-grid>
								<div>
									<label className="uk-form-label" htmlFor="gender">
										{t('gender')}
									</label>
									<div className="uk-form-controls">
										<ul className="uk-list uk-list-sort">
											<li>
												<select
													name="gender"
													id="gender"
													className="uk-select"
													defaultValue={productDetails.product_gender}
													required>
													<option value="0">{t('male')}</option>
													<option value="1">{t('female')}</option>
												</select>
											</li>
										</ul>
									</div>
								</div>
								<div>
									<label className="uk-form-label" htmlFor="phone">
										{t('category')}
									</label>
									<div className="uk-form-controls">
										<ul className="uk-list uk-list-sort">
											<li>
												<select
													name="category_id"
													className="uk-select"
													defaultValue={productDetails.category_id}
													data-default={productDetails.category_id}
													required>
													<option value="">Select...</option>
													{resources.category_details.map((category, index) => (
														<option key={index} value={category.category_id}>
															{category.category_name}
														</option>
													))}
												</select>
											</li>
										</ul>
									</div>
								</div>
								<div>
									<label className="uk-form-label" htmlFor="product_location">
										{t('locationDetails')}
									</label>
									{/* <div className="uk-form-controls">
                                    <textarea name="product_location" id="product_location" className="uk-textarea" placeholder="Enter detailed product address with house no., street, pincode and landmark..." rows={4} defaultValue={productDetails.product_location} />
                                </div> */}
									{/* {isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        zoom={10}
                                        center={currentLocation}
                                        onClick={handleMapClick}
                                    >
                                        {clickedLocation && (
                                            <Marker
                                                position={{ lat: clickedLocation.lat, lng: clickedLocation.lng }}
                                            />
                                        )}
                                    </GoogleMap>
                                ) : (
                                    <></>
                                )} */}
									<PlacesSearch
										id="product_location"
										onSelect={setClickedLocation}
                                        lat={productDetails.latitude}
                                        lng={productDetails.longitude}
									/>
								</div>
								<Alert
									message={responseMessage}
									reset={function () {
										setResponseMessage({
											type: false,
											message: false,
										});
									}}
								/>
								<div>
									<button
										type="submit"
										name="continue"
										className="uk-button uk-button-primary">
										{t('continue')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
}

export default EditCategory;
