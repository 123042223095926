import { React, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import EditProductContext from "../../context/editProductContext"
import Alert from "../../Components/Helpers/Alert"
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next'; 

function EditSizes() {

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    const { productDetails, resources } = useContext(EditProductContext)
    const { product_id } = useParams();
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState({
			type: "",
			message: "",
		});
    const [shoulderSize, setShoulderSize] = useState({
        min: productDetails.shoulder_size_low,
        max: productDetails.shoulder_size_high
    })

    const [chestSize, setChestSize] = useState({
        min: productDetails.chest_size_low,
        max: productDetails.chest_size_high
    })

    const [waistSize, setWaistSize] = useState({
        min: productDetails.waist_size_low,
        max: productDetails.waist_size_high
    })

    const [thighSize, setThighSize] = useState({
        min: productDetails.thighs_size_low,
        max: productDetails.thighs_size_high
    })

    const location = useLocation();

    function goBack() {
        navigate(-1);
    }

    if (!resources) return;

    const product = location.state.product;

    console.log("s", productDetails.shoulder_size_high, "c", productDetails.chest_size_high, "w", productDetails.waist_size_high, "t", productDetails.thighs_size_high)

    console.log("sh", shoulderSize, "ch", chestSize, "wa", waistSize, "th", thighSize)
    
    function save(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        formData.forEach((value, name) => {
            product[name] = value;
        });

        product["shoulder_size_low"] = shoulderSize.max
        product["shoulder_size_high"] = shoulderSize.max

        product["chest_size_low"] = chestSize.max
        product["chest_size_high"] = chestSize.max

        product["waist_size_low"] = waistSize.max
        product["waist_size_high"] = waistSize.max

        product["thighs_size_low"] = thighSize.max
        product["thighs_size_high"] = thighSize.max

        if (!product.bodytype) {
            setResponseMessage({message:(t('selectBodyType')), type:"danger"});
            return;
		}

		product.bodytype = parseInt(product.bodytype);

        navigate(`/seller/edit-product/${product_id}/media`, {
            state: { product },
        });
    	console.log("navigate sizes", product)
    }

	const handleClick = () => {
		navigate("/seller/guide");
	};

    return (
			<>
				<div
					className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
					data-uk-grid>
					<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
						<img
							src="/images/side_banner.png"
							alt="Side Banner"
							className="uk-width-side-banner"
						/>
					</div>
					<div className="uk-flex uk-flex-center uk-flex-middle">
						<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
							<div className="uk-flex uk-flex-between uk-flex-middle">
								<div className="uk-flex">
									<button onClick={goBack} className="uk-button-noeffect">
										<ReactSVG
											src={arrowLeft}
											className="uk-icon-left uk-icon-black"
										/>
									</button>
									<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
										{t('listProduct')}
									</h1>
								</div>
								<ReactSVG src={info} className="uk-icon-info" onClick={handleClick} />
							</div>
							<form
								onSubmit={save}
								style={{ marginTop: "50px" }}
								className="uk-grid-small uk-child-width-1-1"
								data-uk-grid>
								<div>
									<label className="uk-form-label" htmlFor="shoulder-size">
										{t('shoulderSize')}
									</label>
									{/*<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={20}
											maxValue={30}
											value={shoulderSize}
											onChange={(value) => {
												setShoulderSize(value);
											}}
										/>
									</div>*/}
									<Slider
									    value={shoulderSize.max}
									    onChange={(e, value) => setShoulderSize({ min: 20, max: value })}
									    valueLabelDisplay="on"
									    valueLabelFormat={(value) => `${value}`}
									    min={20}
									    max={30}
									    step={1}
									    style={{color: "#46766e"}}
									/>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="chest-size">
										{t('chestSize')}
									</label>
									{/*<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={34}
											maxValue={56}
											value={chestSize}
											onChange={(value) => {
												setChestSize(value);
											}}
										/>
									</div>*/}
									<Slider
									    value={chestSize.max}
									    onChange={(e, value) => setChestSize({ min: 34, max: value })}
									    valueLabelDisplay="on"
									    valueLabelFormat={(value) => `${value}`}
									    min={34}
									    max={56}
									    step={1}
									    style={{color: "#46766e"}}
									/>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="phone">
										{t('waistSize')}
									</label>
									{/*<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={32}
											maxValue={50}
											value={waistSize}
											onChange={(value) => {
												setWaistSize(value);
											}}
											draggableTrack={false}
										/>
									</div>*/}
									<Slider
									    value={waistSize.max}
									    onChange={(e, value) => setWaistSize({ min: 32, max: value })}
									    valueLabelDisplay="on"
									    valueLabelFormat={(value) => `${value}`}
									    min={32}
									    max={50}
									    step={1}
									    style={{color: "#46766e"}}
									/>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="phone">
										{t('thighsSize')}
									</label>
									{/*<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={20}
											maxValue={30}
											value={thighSize}
											onChange={(value) => {
												setThighSize(value);
											}}
										/>
									</div>*/}
									<Slider
									    value={thighSize.max}
									    onChange={(e, value) => setThighSize({ min: 20, max: value })}
									    valueLabelDisplay="on"
									    valueLabelFormat={(value) => `${value}`}
									    min={20}
									    max={30}
									    step={1}
									    style={{color: "#46766e"}}
									/>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="bodytype">
										{t('bodyType')}
									</label>
									<div
										className="uk-form-controls uk-slider-size-picker"
										data-uk-slider>
										<div className="uk-slider-container">
											<ul className="uk-slider-items uk-child-width-1-4">
												{product.gender == 0 ? (
													<>
														{resources.bodytype_for_man.map((size, index) => (
															<li key={index}>
																<input
																	id={size.bodytype_name}
																	type="radio"
																	defaultChecked={
																		size.bodytype_id == productDetails.bodytype
																	}
																	name="bodytype"
																	value={size.bodytype_id}
																/>
																<label htmlFor={size.bodytype_name}>
																	<img src={`${size.body_type_image}`} />
																</label>
															</li>
														))}
													</>
												) : (
													<>
														{resources.bodytype_for_woman.map((size, index) => (
															<li key={index}>
																<input
																	id={size.bodytype_name}
																	type="radio"
																	defaultChecked={
																		size.bodytype_id == productDetails.bodytype
																	}
																	name="bodytype"
																	value={size.bodytype_id}
																/>
																<label htmlFor={size.bodytype_name}>
																	<img src={`${size.body_type_image}`} />
																</label>
															</li>
														))}
													</>
												)}
											</ul>
										</div>
									</div>
								</div>
								<Alert
									message={responseMessage}
									reset={function () {
										setResponseMessage({
											type: false,
											message: false,
										});
									}}
								/>
								<div>
									<button
										type="submit"
										name="continue"
										className="uk-button uk-button-primary">
										{t('continue')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
}

export default EditSizes;
