// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      hello: 'Hello, World!',
      submitButton: 'Submit',
      personalInformation: 'Personal Information',
      name: 'Name',
      email: 'Email Id',
      phone: 'Phone',
      address: 'Address',
      city: 'City',
      state: 'State',
      governmentId: 'Government ID (Aadhaar)',
      photo: 'Photo',
      switchToHindi: 'Switch to Hindi',
      switchToEnglish: 'Switch to English',
      save: 'Save',

      // user profile
      rentOutYourOutfitsNow: 'RENT OUT YOUR OUTFITS NOW',
      accountSettings: 'Account Settings',
      personalInformation: 'Personal Information',
      loginAndSecurity: 'Login & Security',
      payouts: 'Payouts',
      switchToSellerAccount: 'Switch to seller account',
      support: 'Support',
      customerSupport: 'Customer Support',
      giveUsFeedback: 'Give us feedback',
      legal: 'Legal',
      termsOfServices: 'Terms of services',
      privacyPolicies: 'Privacy Policies',
      switchToHindi: 'Switch to Hindi',

      // wallet
      coins: 'coins',
      balance: 'Balance',
      howToUseCoins: 'How to use coins to make your renting experience more amazing?',
      watchDemo: 'Watch Demo',
      inviteFriends: 'Invite Friends',
      getBonusCoins: 'And get bonus of 500 Coins',
      rentingTransactions: 'Renting Transactions',
      affiliateTransactions: 'Affiliate Transactions',

      // seller profile
      switchToUserAccount: 'Switch to user account',

      // user messages
      otpSection: 'OTP Section',
      inbox: 'Inbox',
      search: 'Search',
      messages: 'Messages',
      notifications: 'Notifications',
      ratings: 'Ratings',
      noMessagesYet: 'No Messages Yet',
      lastSeenOn: 'Last seen on',

      // user notifications
      writeReviewsFor: 'Write reviews for:',
      report: 'Report',
      submit: 'Submit',
      noNotificationSelected: 'No Notification Selected',

      // ratings
      rateThisUser: 'Rate this user',
      rateProduct: 'Rate Product',
      successfullyRented: 'Successfully Rented',
      writeYourReviewHere: 'Write your review here...',
      confirmSubmission: 'Confirm Submission',
      doYouWantToSubmitReview: 'Do you want to submit the review?',
      cancel: 'Cancel',
      noReviewSelected: 'No Review Selected',

      // google login
      login: 'Login',

      // login
      welcomeBack: 'Welcome Back!',
      phone: 'Phone',
      iAccept: 'I accept',
      terms: 'Terms',
      and: 'and',
      conditions: 'Conditions',

      // otp confirmation
      codeConfirmation: 'Code Confirmation',
      enter4DigitCode: 'Please enter the 4-digit code sent to',
      didntGetCode: "Didn't get the code?",
      resendOTPIn: 'Resend OTP in',
      sendAgain: 'Send Again',
      verifyOTP: 'Verify OTP',

      // register
      hiThere: 'Hi There!',

      // reset password
      resetPassword: 'Reset Password',
      password: 'Password',
      repeatNewPassword: 'Repeat New Password',
      signInWithNewPassword: 'Sign in with new password',
      enter4DigitCodeForReset: 'Please enter the 4-digit code sent to Reset Password',

      // featured
      notEverythingNeedsToBeOwned: 'Not everything needs to be owned, rent now!!!',
      exploreNow: 'Explore Now',

      // trending
      trendingProducts: 'Trending Products',

      // productItem
      mrp: 'MRP',
      rent: 'RENT',
      
      // footer
      quickLinks: 'Quick Links',
      privacyPolicy: 'Privacy Policy',
      termsAndConditions: 'Terms and Conditions',
      rentalTerms: 'Rental Terms',
      feedback: 'Feedback',
      connectWithUs: 'Connect With Us',

      // wishlist
      wishlist: 'Wishlist',
      couldNotFindSearchTerm: 'Could not find your search term',
      cartIsEmpty: 'Your cart is empty',

      // cartItem
      price: 'Price',
      rentNow: 'Rent Now',

      // guide
      productGuide: 'Product Guide',

      // sort
      highToLow: 'High to low',
      lowToHigh: 'Low to high',
      selectRating: 'Select Rating',
      star: 'Star',
      apply: 'Apply',
      clear: 'Clear',

      // filter
      filterItemsBy: 'Filter Items By',
      male: 'Male',
      female: 'Female',
      priceRange: 'Price Range',
      distance: 'Distance',
      brand: 'Brand',
      select: 'Select',
      rating: 'Rating',
      color: 'Color',
      fabric: 'Fabric',
      bodyType: 'Body Type',
      shoulderSize: 'Shoulder Size',
      chestSize: 'Chest Size',
      waistSize: 'Waist Size',
      thighsSize: 'Thighs Size',
      applyFilter: 'Apply Filter',
      clear: 'Clear',
      size: 'Size',

      // seller calendar
      calendar: 'Calendar',
      addItem: 'Add Item',
      itemBookings: 'Item Bookings',
      noBookingsFound: 'No bookings found',
      confirmDeleteProduct: 'Are you sure you want to delete this product?',
      deleteProduct: 'Delete Product',

      // productItem
      ongoingBookings: 'Ongoing Bookings',
      viewBookings: 'View Bookings',
      deleteProduct: 'Delete Product',
      productIsLive: 'Product is Live',
      productIsDeleted: 'Product is Deleted',
      productIsOnHold: 'Product is On Hold',
      productIsDisapproved: 'Product is Disapproved',

      // category
      listProduct: 'List Product',
      gender: 'Gender',
      category: 'Category',
      locationDetails: 'Location Details',
      continue: 'Continue',

      // details
      productTitle: 'Product Title',
      itemDetails: 'Item Details',
      brand: 'Brand',
      productStock: 'Product Stock',
      color: 'Color',
      fabric: 'Fabric',
      recommendedFor: 'Recommended For',
      enterProductTitle: 'Enter Product Title',
      writeBasicDetails: 'Write basic product details to get more detailed info',
      enterProductStock: 'Enter Product Stock',

      // sizes
      shoulderSize: 'Shoulder Size',
      chestSize: 'Chest Size',
      waistSize: 'Waist Size',
      thighsSize: 'Thighs Size',
      bodyType: 'Body Type',
      selectBodyType: 'Please select body type',

      // media
      uploadProductImages: 'Upload product images',
      uploadVideo: 'Upload Video',
      select3Images: 'Select 3 images',

      // price
      enterMRP: 'Enter MRP',
      enterMRPInRupees: 'Enter MRP in ₹',
      productAge: 'Product Age',
      enterProductAge: 'Enter product age in years',
      numberOfUsage: 'Number Of Usage',
      enterNumberOfTimesUsed: 'Enter number of times used',
      enterRent: 'Enter Rent',
      enterRentInRupees: 'Enter Rent in ₹',
      suggestedRentAmount: 'Suggested rent amount is from',

      // edit
      updatingProduct: 'We are updating the product',
      productUpdatedSuccessfully: 'Your Product is updated successfully',

      // add
      addingProduct: 'We are adding the product. Please wait',
      productListedSuccessfully: 'Your Product is listed successfully',

      // notificationItem
      newBookingRequest: 'New Booking Request',
      wantsToRentThisProductFrom: 'wants to rent this product from',
      accept: 'Accept',
      decline: 'Decline',
      confirmBooking: 'Confirm Booking',
      doYouWantToAcceptTheBooking: 'Do you want to accept the booking?',
      confirm: 'Confirm',
      cancel: 'Cancel',

      // bookings
      thighs: 'Thigh',
      chest: 'Chest',
      waist: 'Waist',
      shoulder: 'Shoulder',
      asPerSelectedDates: '(As per the dates selected)',
      securityAmount: 'Security Amount',
      standardRates: '(Standard Rates)',

      // bookingItem
      viewDetails: 'View Details',
      cancelBooking: 'Cancel Booking',
      raiseComplain: 'Raise Complain',

      // policy
      privacyPolicy: 'Privacy Policy',
      anywhereClothes: 'At Anywhere Clothes, we value and respect your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you access and use our cloth and fashion rental services. By using our platform, you agree to the practices described in this Privacy Policy.',
      collectTypesInfo: 'We may collect the following types of personal information',
      contactInfo: 'Contact Information: Name, email address, phone number, and shipping address.',
      profileInfo: 'Profile Information: Size preferences, style preferences, and clothing measurements.',
      transactionInfo: 'Transaction Information: Details of rentals, purchases, and returns made through our platform.',
      communicationInfo: 'Communication Information: Any communication you have with our customer support team or other users.',
      paymentInfo: 'Payment Information: Credit card details or other payment method information.',
      useYourInfo: 'How We Use Your Information',
      useYourPersonalInfo: 'We use your personal information for the following purposes:',
      fulfillRentalOrders: 'To facilitate and fulfill your rental orders and purchases.',
      communicateWithYou: 'To communicate with you regarding your orders, account updates, and promotional offers.',
      improveServicesAndExperience: 'To improve our services, platform, and user experience.',
      ensureSecurityAndIntegrity: 'To ensure the security and integrity of our platform and prevent fraud.',
      personalizeExperience: 'To personalize your experience by providing tailored recommendations and marketing materials.',
      complyWithLegalObligations: 'To comply with legal obligations and enforce our Terms of Service.',
      informationWeCollect: 'Information We Collect',
      howWeUseYourInformation: 'How We Use Your Information',
      informationSharing: 'Information Sharing',
      serviceProviders: 'We may share your personal information with',
      thirdPartyVendors: 'Service Providers: Third-party vendors and partners who assist in providing our services, such as payment processors and delivery companies.',
      legalCompliance: 'Legal Compliance: When required by law or in response to a valid request from a government authority.',
      businessTransfers: 'Business Transfers: In the event of a merger, acquisition, or sale of assets, your personal information may be transferred to the new entity.',
      withYourConsent: 'With Your Consent: We may share your information with your explicit consent.',
      dataSecurity: 'Data Security',
      takeReasonableMeasures: 'We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.',
      dataTransmissionWarning: 'However, no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.',
      yourChoices: 'Your Choices',
      reviewUpdateDeleteInfo: 'You can review, update, and delete your personal information by accessing your account settings.',
      optOutMarketing: 'You can also opt-out of receiving marketing communications from us by following the instructions in our emails or contacting our customer support team.',
      cookiesAndTracking: 'Cookies and Tracking Technologies',
      useCookiesToEnhance: 'We use cookies and similar tracking technologies to enhance your experience on our platform and collect usage data.',
      modifyBrowserSettings: 'You can modify your browser settings to manage cookies preferences, although this may affect certain features of our platform.',
      thirdPartyLinks: 'Third-Party Links',
      platformMayContainLinks: 'Our platform may contain links to third-party websites or services.',
      notResponsibleForPrivacy: 'We are not responsible for the privacy practices or content of these third parties.',
      encourageReviewPrivacyPolicies: 'We encourage you to review their privacy policies before providing any personal information.',
      childrensPrivacy: "Children's Privacy",
      servicesNotIntendedFor: 'Our services are not intended for individuals under the age of 18.',
      notKnowinglyCollectPersonalInfo: 'We do not knowingly collect personal information from children.',
      awareThatCollectedInfoFromChild: 'If we become aware that we have collected personal information from a child without parental consent, we will take steps to delete that information.',
      changesToPrivacyPolicy: 'Changes to the Privacy Policy',
      mayUpdatePrivacyPolicy: 'We may update this Privacy Policy from time to time.',
      continuedUseAfterChanges: 'Your continued use of our platform after the changes are made constitutes your acceptance of the revised Privacy Policy.',
      contactUs: 'Contact Us',
      questionsConcernsRequests: 'If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at.',
      termsAndConditions: 'Terms and Conditions',
      visitTermsAndConditionsSection: 'Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at.',
      acknowledgeReadUnderstoodAgree: 'By using our platform, you acknowledge that you have read, understood, and agree to this Privacy Policy.',

      // rental terms
      rentalTerms: 'Rental Terms',
      policiesGovernsReturn: 'The policy(s) mentioned below governs the return of Order(s) for product(s) ordered by you as the user of the (COMPANY NAME) while renting Product(s).',
      userAbideByReturnPolicy: 'The user has to abide by the return policy.',
      returnPolicyAppliedPostDelivery: 'The return policy(s) is applied post-delivery of the product(s).',
      referenceToUserAndWe: 'The reference of "you" or "User" or "User\'s" in the policy mentioned below is of you the User of the (WEBSITE OR STORE) and the reference of "we" "us" or "our" is for "(COMPANY NAME)".',
      companyPolicyAscertainProduct: 'As a company policy, the user must ascertain the product(s) is in conformity before receiving it, the user will be held liable for any defect/damage whatsoever after the delivery of the product(s).',
      failureToReturnBySpecifiedDate: 'Failure of the user to return the Product(s) by the specified date shall invite a daily penalty of (AMOUNT SPECIFY) of the rent price.',
      failureToReturnAfterSpecifyDays: 'Failure to return the product(s) after (specify days) shall result in a formal police complaint; furthermore, the act will be treated as theft.',
      productProperlyPacked: 'The Product(s) shall be properly packed, any damage sustained during such return shall be the sole liability of the User.',
      companyReservesRight: 'The company reserves the right to hold the whole security deposit in case of any damage caused to the product(s) after delivery.',
      userChargedAsPenalty: 'The user will be charged as a penalty (2x of rent price) of the product(s) in addition to holding of the security deposit in case of any substantial damage which is beyond repair i.e. fully torn, hole in the outfit, burnt, liquid or food stains that can\'t be dry cleaned.',
      companyHoldsRightRegardingForfeiting: 'The company holds the right regarding forfeiting the security deposit in case of damage; furthermore, the user agrees not to dispute the decision made by the company in determination of substantial damage/loss done to the product(s).',
      refundableSecurityDepositFullyRefunded: 'The refundable security deposit will only be fully refunded within (specify no. of days) working days after the User returns the product(s) in good condition, without damages and after a quality check.',
      userAgreesAndUnderstands: 'You agree and understand that the company doesn\'t deal in any kind of delivery process (to and fro), any kind of shipping charges are to be borne by the user.',
      noRefundAfter3Hours: 'No refund will be provided if the order is cancelled by the User after 3 hours of receiving it.',
      userAndCompanyGovernedByPolicies: 'The user and the company will be governed by the above-mentioned policies and the applicable law in India. Any disputes arising will be handled in the competent courts of Vidisha, Madhya Pradesh.',

      // product
      location: 'Location',
      details: 'Details',
      booking: 'Booking',
      checkAvailability: 'Check Availability',
      close: 'Close',
      book: 'Book',
      reviews: 'Reviews',
      all: 'All',
      noReviewsFound: 'No Reviews Found',
      rentalTerms: 'Rental Terms',
      report: 'Report',

      // booking
      booking: 'Booking',
      clearAll: 'Clear all',
      editAvailability: 'Edit Availability',
      acceptRentalTerms: 'By clicking you accept Rental Terms',
      totalPayable: 'Total Payable',
      payNow: 'Pay Now',

      // payment
      pay: 'Pay',
      totalPayableAmount: 'Total Payable Amount',
      paymentMethods: 'Payment Methods',
      payUsingUPI: 'Pay using UPI ID',
      recommendedMethod: 'Recommended Method',
      payUsingDebitOrCreditCard: 'Pay using Debit or Credit Card',
      extraTransactionCharges: 'Needs to pay extra transaction charges',
      netBanking: 'Net Banking',
      paymentsWallets: 'Payments Wallets',
      bookingOnHold: 'BOOKING ON HOLD',
      exploreMoreProducts: 'Till then explore more products.',
      exploreMore: 'Explore More',
      waitingForSellerConfirmation: 'Waiting for the confirmation from the seller, we will notify you once confirmed.',

      // completeKyc
      completeKYC: 'Complete KYC',
      uploadAadhaarFront: 'Upload Aadhaar Front',
      uploadAadhaarBack: 'Upload Aadhaar Back',
      uploadYourImages: 'Upload your images with a clear face',
      governmentID: 'Government ID (Aadhaar)',
      save: 'Save',

      // feedback
      feedback: 'Feedback',
      rateUs: 'How would you rate us?',
      notLikelyAtAll: 'Not likely at all',
      extremelyLikely: 'Extremely likely',
      facingAnyIssue: 'Facing any issue or tell us how we can improve?',
      writeSomethingHere: 'Write something here...',
      submit: 'Submit',

      // security
      loginAndSecurity: 'Login & Security',
      logOut: 'Log Out',
      deactivateAccount: 'Deactivate Account',

      // search
      searchItems: 'Search Items',
      male: 'Male',
      female: 'Female',
      where: 'Where',
      what: 'What',
      selectCategory: 'Select Category',
      when: 'When',
      searchProducts: 'Search Products',
      clear: 'Clear',

      // raise complaint
      raisingComplain: 'Raising Complain',
      viewDetails: 'View Details',
      tellUsYourProblem: 'Tell us your problem',
      gotDamagedItem: 'Got the damaged item',
      productWasIncorrect: 'Product was incorrect',
      delayInDelivery: 'Delay in delivery',
      stain: 'Stain',
      other: 'Other',
      writeYourMessageHere: 'Write your message here...',
      uploadItemImages: 'Upload item images if any',
      raiseComplain: 'Raise Complaint',

      // payouts
      payouts: 'Payouts',
      savedUPIID: 'Saved UPI ID',
      addNew: 'Add New',
      savedAccountDetails: 'Saved account details',
      addBank: 'Add Bank',
      checkPaymentHistory: 'Check Payment History',
      paymentHistory: 'Payment History',
      customerName: 'Customer Name',
      received: 'Received',
      date: 'Date',
      viewBookingDetails: 'View Booking Details',

    },
  },
  hi: {
    translation: {
      hello: 'नमस्ते, दुनिया!',
      submitButton: 'प्रस्तुत करें',
      personalInformation: 'व्यक्तिगत जानकारी',
      name: 'नाम',
      email: 'ईमेल आईडी',
      phone: 'फ़ोन',
      address: 'पता',
      city: 'शहर',
      state: 'राज्य',
      governmentId: 'सरकारी पहचान पत्र (आधार)',
      photo: 'फ़ोटो',
      switchToHindi: 'हिंदी में बदलें',
      switchToEnglish: 'अंग्रेज़ी में बदलें',
      save: 'सहेजें',

      // user profile
      rentOutYourOutfitsNow: 'अपने आउटफिट को किराए पर दें',
      accountSettings: 'खाता सेटिंग्स',
      personalInformation: 'व्यक्तिगत जानकारी',
      loginAndSecurity: 'लॉगिन और सुरक्षा',
      payouts: 'पेआउट्स',
      switchToSellerAccount: 'विक्रेता खाते में स्विच करें',
      support: 'समर्थन',
      customerSupport: 'ग्राहक समर्थन',
      giveUsFeedback: 'हमें सुझाव दें',
      legal: 'कानूनी',
      termsOfServices: 'सेवा की शर्तें',
      privacyPolicies: 'गोपनीयता नीतियां',
      switchToHindi: 'हिंदी में बदलें',

      // wallet
      coins: 'सिक्कों',
      balance: 'शेष',
      howToUseCoins: 'सिक्कों का उपयोग कैसे करें ताकि आपका किराया अनुभव अद्भुत हो?',
      watchDemo: 'डेमो देखें',
      inviteFriends: 'दोस्तों को आमंत्रित करें',
      getBonusCoins: 'और 500 सिक्कों का बोनस पाएं',
      rentingTransactions: 'किराए पर लेने का लेन-देन',
      affiliateTransactions: 'सहायक लेन-देन',

      // seller profile
      switchToUserAccount: 'उपयोगकर्ता खाते में स्विच करें',

      // user messages
      otpSection: 'ओटीपी सेक्शन',
      inbox: 'इनबॉक्स',
      search: 'खोजें',
      messages: 'संदेश',
      notifications: 'सूचनाएं',
      ratings: 'रेटिंग्स',
      noMessagesYet: 'अब तक कोई संदेश नहीं',
      lastSeenOn: 'अंतिम बार देखा गया',

      // user notifications
      writeReviewsFor: 'के लिए समीक्षा लिखें:',
      report: 'रिपोर्ट',
      submit: 'प्रस्तुत करें',
      noNotificationSelected: 'कोई सूचना चयन नहीं की गई',

      // ratings
      rateThisUser: 'इस उपयोगकर्ता को रेट करें',
      rateProduct: 'उत्पाद को रेट करें',
      successfullyRented: 'किराया सफलतापूर्वक हुआ',
      writeYourReviewHere: 'यहां अपनी समीक्षा लिखें...',
      confirmSubmission: 'प्रस्तुति की पुष्टि करें',
      doYouWantToSubmitReview: 'क्या आप समीक्षा प्रस्तुत करना चाहते हैं?',
      cancel: 'रद्द करें',
      noReviewSelected: 'कोई समीक्षा चयन नहीं की गई',

      // google login
      login: 'लॉग इन',

      // login
      welcomeBack: 'वापसी पर स्वागत है!',
      phone: 'फ़ोन',
      iAccept: 'मैं स्वीकार करता हूँ',
      terms: 'शर्तें',
      and: 'और',
      conditions: 'नियम',

      // otp confirmation
      codeConfirmation: 'कोड पुष्टि',
      enter4DigitCode: 'कृपया वह 4-अंक कोड डालें जो भेजा गया है',
      didntGetCode: 'कोड प्राप्त नहीं हुआ?',
      resendOTPIn: 'ओटीपी पुनः भेजें',
      sendAgain: 'फिर से भेजें',
      verifyOTP: 'ओटीपी सत्यापित करें',

      // register
      hiThere: 'नमस्ते!',

      // reset password
      resetPassword: 'पासवर्ड रीसेट करें',
      password: 'पासवर्ड',
      repeatNewPassword: 'नया पासवर्ड दोहराएं',
      signInWithNewPassword: 'नए पासवर्ड के साथ साइन इन करें',
      enter4DigitCodeForReset: 'कृपया पासवर्ड रीसेट करने के लिए भेजे गए 4-अंक कोड डालें',
      
      // featured
      notEverythingNeedsToBeOwned: 'सब कुछ को स्वामित्व में नहीं होना चाहिए, अब किराए पर लो!!!',
      exploreNow: 'अब अन्वेषण करें',

      // trending
      trendingProducts: 'लोकप्रिय उत्पाद',

      // productItem
      mrp: 'एमआरपी',
      rent: 'किराया',

      // footer
      quickLinks: 'त्वरित लिंक्स',
      privacyPolicy: 'गोपनीयता नीति',
      termsAndConditions: 'शर्तें और नियम',
      rentalTerms: 'किराए पर लेने की शर्तें',
      feedback: 'प्रतिक्रिया',
      connectWithUs: 'हमसे जुड़ें',

      // wishlist
      wishlist: 'इच्छा-सूची',
      couldNotFindSearchTerm: 'आपके खोज शब्द को नहीं मिला',
      cartIsEmpty: 'आपकी कार्ट खाली है',

      // cartItem
      price: 'मूल्य',
      rentNow: 'किराए पर लो',

      // guide
      productGuide: 'उत्पाद मार्गदर्शिका',

      // sort
      highToLow: 'उच्च से कम',
      lowToHigh: 'कम से अधिक',
      selectRating: 'रेटिंग चयन करें',
      star: 'स्टार',
      apply: 'लागू करें',
      clear: 'साफ़ करें',

      // filter
      filterItemsBy: 'आइटम फ़िल्टर करें',
      male: 'पुरुष',
      female: 'महिला',
      priceRange: 'मूल्य सीमा',
      distance: 'दूरी',
      brand: 'ब्रांड',
      select: 'चयन करें',
      rating: 'रेटिंग',
      color: 'रंग',
      fabric: 'कपड़ा',
      bodyType: 'शारीरिक प्रकार',
      shoulderSize: 'कंधे का आकार',
      chestSize: 'छाती का आकार',
      waistSize: 'कमर का आकार',
      thighsSize: 'जांघों का आकार',
      applyFilter: 'फ़िल्टर लागू करें',
      clear: 'साफ़ करें',
      size: 'साइज़',

      // seller calendar
      calendar: 'कैलेंडर',
      addItem: 'आइटम जोड़ें',
      itemBookings: 'आइटम बुकिंग',
      noBookingsFound: 'कोई बुकिंग नहीं मिली',
      confirmDeleteProduct: 'क्या आप इस उत्पाद को हटाना चाहते हैं?',
      deleteProduct: 'उत्पाद हटाएं',

      // productItem
      ongoingBookings: 'चल रही बुकिंगें',
      viewBookings: 'बुकिंगें देखें',
      deleteProduct: 'उत्पाद हटाएं',
      productIsLive: 'उत्पाद सक्रिय है',
      productIsDeleted: 'उत्पाद हटा दिया गया है',
      productIsOnHold: 'उत्पाद होल्ड पर है',
      productIsDisapproved: 'उत्पाद अनुमोदित नहीं है',

      // catgeory
      listProduct: 'सूची उत्पाद',
      gender: 'लिंग',
      category: 'श्रेणी',
      locationDetails: 'स्थान विवरण',
      continue: 'जारी रखें',

      // details
      productTitle: 'उत्पाद शीर्षक',
      itemDetails: 'आइटम विवरण',
      brand: 'ब्रांड',
      productStock: 'उत्पाद स्टॉक',
      color: 'रंग',
      fabric: 'कपड़ा',
      recommendedFor: 'सिफारिश की गई है',
      enterProductTitle: 'उत्पाद शीर्षक दर्ज करें',
      writeBasicDetails: 'और विस्तृत जानकारी प्राप्त करने के लिए मौजूदा उत्पाद विवरण लिखें',
      enterProductStock: 'उत्पाद स्टॉक दर्ज करें',

      // sizes
      shoulderSize: 'कंधा का आकार',
      chestSize: 'छाती का आकार',
      waistSize: 'कमर का आकार',
      thighsSize: 'जांघ का आकार',
      bodyType: 'शरीर का प्रकार',
      selectBodyType: 'कृपया शरीर का प्रकार चुनें',

      // media
      uploadProductImages: 'उत्पाद छवियाँ अपलोड करें',
      uploadVideo: 'वीडियो अपलोड करें',
      select3Images: '3 छवियाँ चुनें',

      // price
      enterMRP: 'एमआरपी दर्ज करें',
      enterMRPInRupees: '₹ में एमआरपी दर्ज करें',
      productAge: 'उत्पाद आयु',
      enterProductAge: 'उत्पाद की आयु को वर्षों में दर्ज करें',
      numberOfUsage: 'उपयोग की संख्या',
      enterNumberOfTimesUsed: 'बार उपयोग की संख्या दर्ज करें',
      enterRent: 'किराया दर्ज करें',
      enterRentInRupees: '₹ में किराया दर्ज करें',
      suggestedRentAmount: 'सुझाए गए किराया राशि है',

      // edit
      updatingProduct: 'हम उत्पाद को अपडेट कर रहे हैं',
      productUpdatedSuccessfully: 'आपका उत्पाद सफलता पूर्वक अपडेट हुआ है',

      // add
      addingProduct: 'हम प्रोडक्ट जोड़ रहे हैं। कृपया प्रतीक्षा करें',
      productListedSuccessfully: 'आपका प्रोडक्ट सफलतापूर्वक सूचीबद्ध है',

      // notificationItem
      newBookingRequest: 'नई बुकिंग अनुरोध',
      wantsToRentThisProductFrom: 'इस उत्पाद को किराए पर लेना चाहता/चाहती है',
      accept: 'स्वीकृत करें',
      decline: 'अस्वीकृत करें',
      confirmBooking: 'बुकिंग की पुष्टि करें',
      doYouWantToAcceptTheBooking: 'क्या आप बुकिंग स्वीकार करना चाहते हैं?',
      confirm: 'पुष्टि करें',
      cancel: 'रद्द करें',

      // bookings
      ongoingBookings: 'चल रही बुकिंगें',
      pastBookings: 'पिछली बुकिंगें',
      fetch: 'फ़ेच करें',
      size: 'साइज़',
      thighs: 'जांघ',
      chest: 'छाती',
      waist: 'कमर',
      shoulder: 'कंधा',
      price: 'मूल्य',
      days: 'दिन',
      date: 'तारीख',
      location: 'स्थान',
      asPerSelectedDates: '(चयनित तारीखों के अनुसार)',
      securityAmount: 'सुरक्षा राशि',
      standardRates: '(मानक दरें)',
      walletCoinsUsed: 'वॉलेट सिक्के उपयोग किए गए',
      totalPaidAmount: 'कुल भुगतान राशि',

      // bookingItem
      viewDetails: 'विवरण देखें',
      cancelBooking: 'बुकिंग कैंसिल करें',
      raiseComplain: 'शिकायत दर्ज करें',
      
      // policy
      privacyPolicy: 'गोपनीयता नीति',
      anywhereClothes: 'Anywhere Clothes में हम आपकी गोपनीयता की मूल्यवानता और सम्मान करते हैं। इस गोपनीयता नीति में हमने यह बताया है कि हम जब आप हमारी कपड़ा और फैशन किराए की सेवाएं उपयोग करते हैं, तो आपकी व्यक्तिगत जानकारी कैसे एकत्र करते हैं, उपयोग करते हैं, उसे उजागर करते हैं और सुरक्षित रखते हैं। हमारे प्लेटफ़ॉर्म का उपयोग करके, आप इस गोपनीयता नीति में वर्णित अभ्यासों से सहमत होते हैं।',
      collectTypesInfo: 'हम निम्नलिखित प्रकार की व्यक्तिगत जानकारी एकत्र कर सकते हैं',
      contactInfo: 'संपर्क जानकारी: नाम, ईमेल पता, फ़ोन नंबर, और शिपिंग पता।',
      profileInfo: 'प्रोफ़ाइल जानकारी: साइज पसंदीदा, शैली पसंदीदा, और कपड़े की माप।',
      transactionInfo: 'लेन-देन जानकारी: हमारे प्लेटफ़ॉर्म के माध्यम से किए गए किराए, खरीददारी, और लौटाने के विवरण।',
      communicationInfo: 'संवाद जानकारी: आपके हमारी ग्राहक सहायता टीम या अन्य उपयोगकर्ताओं के साथ किए गए किसी भी संवाद।',
      paymentInfo: 'भुगतान जानकारी: क्रेडिट कार्ड विवरण या अन्य भुगतान विधि की जानकारी।',
      useYourInfo: 'हम आपकी जानकारी का उपयोग निम्नलिखित उद्देश्यों के लिए करते हैं',
      useYourPersonalInfo: 'हम आपकी व्यक्तिगत जानकारी का उपयोग निम्नलिखित उद्देश्यों के लिए करते हैं:',
      fulfillRentalOrders: 'आपके किराए के आदेश और खरीदारी को सुगम और पूरा करने के लिए।',
      communicateWithYou: 'आपके आदेश, खाता अपडेट और प्रचार प्रस्तुतियों के बारे में आपसे संवाद करने के लिए।',
      improveServicesAndExperience: 'हमारी सेवाएं, प्लेटफ़ॉर्म, और उपयोगकर्ता अनुभव को सुधारने के लिए।',
      ensureSecurityAndIntegrity: 'हमारे प्लेटफ़ॉर्म की सुरक्षा और अखंडता सुनिश्चित करने और धनधारा को रोकने के लिए।',
      personalizeExperience: 'आपके अनुभव को विशेष रूप से अनुकूलित सुझाव और विपणि सामग्री प्रदान करके।',
      complyWithLegalObligations: 'कानूनी दायित्वों का पालन करने और हमारी सेवा की शर्तों को प्रवर्तन करने के लिए।',
      informationWeCollect: 'हम जो जानकारी एकट्रेक्ट करते हैं',
      howWeUseYourInformation: 'आपकी जानकारी का हम कैसे उपयोग करते हैं',
      informationSharing: 'जानकारी साझा करना',
      serviceProviders: 'हम आपकी व्यक्तिगत जानकारी साझा कर सकते हैं',
      thirdPartyVendors: 'सेवा प्रदाताओं: तृतीय-पक्ष विक्रेता और सहयोगी जो हमारी सेवाओं की प्रदान में सहायक होते हैं, जैसे कि भुगतान प्रोसेसर्स और वितरण कंपनियां।',
      legalCompliance: 'कानूनी अनुपालन: कभी-कभी, क़ानूनी आदेश के अनुसार या सरकारी प्राधिकृति से एक मान्य अनुरोध के प्रतिसाद में।',
      businessTransfers: 'व्यापार स्थानांतरण: अगर किसी मर्ज, अधिग्रहण या संपत्तियों के बिक्री के मौके पर, तो आपकी व्यक्तिगत जानकारी नए कारण को स्थानांतरित की जा सकती है।',
      withYourConsent: 'आपकी सहमति के साथ: हम आपकी जानकारी को आपकी स्पष्ट सहमति के साथ साझा कर सकते हैं।',
      dataSecurity: 'डेटा सुरक्षा',
      takeReasonableMeasures: 'हम आपकी व्यक्तिगत जानकारी को अनधिकृत पहुंच, परिवर्तन, फासी, या नष्ट होने से बचाने के लिए उचित कदम उठाते हैं।',
      dataTransmissionWarning: 'हालांकि, इंटरनेट या इलेक्ट्रॉनिक स्टोरेज पर डेटा प्रेषण का कोई पूर्ण रूप सुरक्षित नहीं है, और हम पूर्ण सुरक्षा की गारंटी नहीं कर सकते हैं।',
      yourChoices: 'आपके विकल्प',
      reviewUpdateDeleteInfo: 'आप अपनी व्यक्तिगत जानकारी की समीक्षा, अपडेट, और हटा सकते हैं अपने खाता सेटिंग्स तक पहुंचकर।',
      optOutMarketing: 'आप हमसे विपणी संबंधित संवाद प्राप्त करना बंद कर सकते हैं, हमारे ईमेलों में दी गई निर्देशों का पालन करके या हमारी ग्राहक समर्थन टीम से संपर्क करके।',
      cookiesAndTracking: 'कुकीज और ट्रैकिंग तकनीकें',
      useCookiesToEnhance: 'हम कुकीज और समान ट्रैकिंग तकनीकें उपयोग करते हैं ताकि हमारे प्लेटफ़ॉर्म पर आपके अनुभव को बढ़ावा मिले और उपयोग की जानकारी जुटा सके।',
      modifyBrowserSettings: 'आप अपने ब्राउज़र सेटिंग्स को संचालित करने के लिए कुकीज पसंदीदागी को प्रबंधित कर सकते हैं, हालांकि इससे हमारे प्लेटफ़ॉर्म के कुछ सुविधाओं पर प्रभाव पड़ सकता है।',
      thirdPartyLinks: 'तिसरे-पक्ष कड़ियां',
      platformMayContainLinks: 'हमारे प्लेटफ़ॉर्म पर तिसरे-पक्ष की वेबसाइट या सेवाओं के लिए लिंक हो सकते हैं।',
      notResponsibleForPrivacy: 'हम इन तिसरे-पक्षों की गोपनीयता अभ्यास या सामग्री के लिए जिम्मेदार नहीं हैं।',
      encourageReviewPrivacyPolicies: 'हम आपको सुनिश्चित करते हैं कि आप उनकी गोपनीयता नीतियों की समीक्षा करें पहले किसी भी व्यक्तिगत जानकारी प्रदान करने से।',
      childrensPrivacy: "बच्चों की गोपनीयता",
      servicesNotIntendedFor: 'हमारी सेवाएं 18 वर्ष से कम आयु के व्यक्तियों के लिए नहीं हैं।',
      notKnowinglyCollectPersonalInfo: 'हम बच्चों से जानकर व्यक्तिगत जानकारी जानकर नहीं करते हैं।',
      awareThatCollectedInfoFromChild: 'अगर हमें यह अवगत होता है कि हमने बिना माता-पिता की सहमति के किसी बच्चे से व्यक्तिगत जानकारी जुटाई है, तो हम उस जानकारी को हटाने के लिए कदम उठाएंगे।',
      changesToPrivacyPolicy: 'गोपनीयता नीति में परिवर्तन',
      mayUpdatePrivacyPolicy: 'हम समय-समय पर इस गोपनीयता नीति को अपडेट कर सकते हैं।',
      continuedUseAfterChanges: 'इसमें परिवर्तन के बाद हमारे प्लेटफ़ॉर्म का आपका जारी उपयोग आपकी स्वीकृति का प्रतीक है।',
      contactUs: 'हमसे संपर्क करें',
      questionsConcernsRequests: 'यदि आपके पास इस गोपनीयता नीति या आपकी व्यक्तिगत जानकारी के संबंध में कोई प्रश्न, चिंता या अनुरोध है, तो कृपया हमसे संपर्क करें।',
      termsAndConditions: 'नियम और शर्तें',
      visitTermsAndConditionsSection: 'कृपया हमारी वेबसाइट के उपयोग, अस्वीकृतियों, और दायित्व की सीमाओं की स्थापना के लिए हमारे नियम और शर्तों खंड पर भी जाएं।',
      acknowledgeReadUnderstoodAgree: 'हमारे प्लेटफ़ॉर्म का उपयोग करके, आप स्वीकार करते हैं कि आपने इस गोपनीयता नीति को पढ़ा, समझा है, और इससे सहमत हैं।',

      // rental terms
      rentalTerms: 'रेंटल शर्तें',
      policiesGovernsReturn: 'नीचे उल्लिखित नीति(एस) आपके द्वारा आदेश किए जाने वाले उत्पाद(ओं) के लौटने का नियम करती है, जब आप उत्पाद(ओं) को किराए पर लेने वाले (कंपनी का नाम) के उपयोगकर्ता होते हैं।',
      userAbideByReturnPolicy: 'उपयोगकर्ता को लौटने की नीति का पालन करना होगा।',
      returnPolicyAppliedPostDelivery: 'उत्पाद(ओं) की पोस्ट-डिलीवरी के बाद ही वापसी नीति(एस) का अनुप्रयोग होता है।',
      referenceToUserAndWe: 'नीचे उल्लिखित नीति में "आप" या "उपयोगकर्ता" या "उपयोगकर्ता का" संदर्भ आपका है, जो (वेबसाइट या स्टोर) के उपयोगकर्ता के रूप में है, और "हम", "हम" या "हमारे" का संदर्भ "(कंपनी का नाम)" के लिए है।',
      companyPolicyAscertainProduct: 'कंपनी की नीति के अनुसार, उपयोगकर्ता को उत्पाद(ओं) को प्राप्त होने से पहले इसकी अनुरूपता को सत्यापित करना होगा, उपयोगकर्ता को उत्पाद(ओं) की वितरण के बाद किसी भी कमी/क्षति के लिए जिम्मेदार ठहराया जाएगा।',
      failureToReturnBySpecifiedDate: 'यदि उपयोगकर्ता ने निर्दिष्ट तिथि तक उत्पाद(ओं) को वापस नहीं किया, तो इससे किराए कीमत का (राशि निर्दिष्ट करें) दैहिक जुर्माना होगा।',
      failureToReturnAfterSpecifyDays: '(निर्दिष्ट दिनों) के बाद उत्पाद(ओं) को वापस न करना एक साक्षर पुलिस शिकायत का परिणाम होगा, इसके अलावा कृपया ध्यान दें कि यह क्रिया चोरी के रूप में व्यवहार किया जाएगा।',
      productProperlyPacked: 'उत्पाद(ओं) को सही ढंग से पैक किया जाना चाहिए, ऐसी वापसी के दौरान होने वाली किसी भी क्षति का एकमात्र उपयोगकर्ता की जिम्मेदारी होगी।',
      companyReservesRight: 'कंपनी को सभी सुरक्षा जमा को उत्पाद(ओं) के प्रदान के बाद किए गए किसी भी क्षति के लिए धारित करने का अधिकार है।',
      userChargedAsPenalty: 'उपयोगकर्ता को जुर्माना के रूप में (2x किराए कीमत) के रूप में दायित्व निभाना होगा और इसके अलावा उपयोगकर्ता को जिम्मेदार ठहराया जाएगा कि किसी भी सुस्त नुकसान की गई है जो मरम्मत से पारित नहीं हो सकता है, जैसे कि पूरी तरह से फटी, आउटफिट में गड्ढा, जला हुआ, ऐसी कोई शारीरिक या भोजन दाग जो सुखा किया नहीं जा सकता है।',
      companyHoldsRightRegardingForfeiting: 'कंपनी को हानि/क्षति के मामले में सुरक्षा जमा को छोड़ने का अधिकार है; इसके अलावा, उपयोगकर्ता सहमत होता है कि कंपनी द्वारा जिनकी कोई निर्णय की जाती है, उसमें विवाद न करें।',
      refundableSecurityDepositFullyRefunded: 'वापसी योग्य सुरक्षा जमा को केवल (निर्दिष्ट दिनों) काम के दिनों के भीतर पूरी तरह से वापस किया जाएगा जब उपयोगकर्ता उत्पाद(ओं) को अच्छी स्थिति में, किसी भी क्षति के बिना और गुणवत्ता की जाँच के बाद।',
      userAgreesAndUnderstands: 'आप सहमत हैं और समझते हैं कि कंपनी किसी भी प्रकार की वितरण प्रक्रिया (आगे और पीछे) में शामिल नहीं है, किसी भी प्रकार के शिपिंग शुल्क का उपयोगकर्ता द्वारा बोर्न किया जाना चाहिए।',
      noRefundAfter3Hours: 'उपयोगकर्ता द्वारा इसे प्राप्त करने के 3 घंटे बाद यदि आदेश रद्द किया जाता है, तो कोई वापसी नहीं की जाएगी।',
      userAndCompanyGovernedByPolicies: 'उपयोगकर्ता और कंपनी को ऊपर उल्लिखित नीतियों और भारतीय कानून द्वारा शासित किया जाएगा। उत्पन्न होने वाले किसी भी विवाद का समाधान विदिशा, मध्य प्रदेश के साक्षर न्यायालयों में होगा।',

      // product
      location: 'स्थान',
      details: 'विवरण',
      booking: 'बुकिंग',
      checkAvailability: 'उपलब्धता देखें',
      close: 'बंद करें',
      book: 'बुक करें',
      reviews: 'समीक्षाएँ',
      all: 'सभी',
      noReviewsFound: 'कोई समीक्षा नहीं मिली',
      rentalTerms: 'किराए पर लेने की शर्तें',
      report: 'रिपोर्ट',

      // booking
      booking: 'बुकिंग',
      clearAll: 'सभी साफ़ करें',  
      editAvailability: 'उपलब्धता संपादित करें',  
      acceptRentalTerms: 'क्लिक करके आप किराए की शर्तें स्वीकृत करते हैं',
      totalPayable: 'कुल भुगतान',
      payNow: 'अब भुगतान करें',

      // payment
      pay: 'भुगतान करें',
      totalPayableAmount: 'कुल भुगतान राशि',
      paymentMethods: 'भुगतान के तरीके',
      payUsingUPI: 'UPI ID का उपयोग करें',
      recommendedMethod: 'सिफारिश की गई मेथड',
      payUsingDebitOrCreditCard: 'डेबिट या क्रेडिट कार्ड का उपयोग करें',
      extraTransactionCharges: 'अतिरिक्त लेन-देन शुल्क देना होगा',
      netBanking: 'नेट बैंकिंग',
      paymentsWallets: 'भुगतान वॉलेट्स',
      bookingOnHold: 'बुकिंग होल्ड पर',
      exploreMoreProducts: 'तब तक और उत्पादों का अन्वेषण करें',
      exploreMore: 'और अधिक अन्वेषण करें',
      waitingForSellerConfirmation: 'विक्रेता से पुष्टि की प्रतीक्षा कर रहे हैं, हम आपको सूचित करेंगे एक बार पुष्टि हो जाएगी।',

      // completeKyc
      completeKYC: 'पूरा करें KYC',
      uploadAadhaarFront: 'अपने आधार का सामना अपलोड करें',
      uploadAadhaarBack: 'अपने आधार का पीछा अपलोड करें',
      uploadYourImages: 'स्पष्ट चेहरे के साथ अपनी छवियाँ अपलोड करें',
      governmentID: 'सरकारी पहचान पत्र (आधार)',
      save: 'सहेजें',

      // feedback
      feedback: 'प्रतिपुष्टि',
      rateUs: 'हमें आपकी रेटिंग कैसी लगी?',
      notLikelyAtAll: 'बिल्कुल भी नहीं',
      extremelyLikely: 'बहुत अधिक',
      facingAnyIssue: 'कोई समस्या है या बताएं कैसे हम सुधार सकते हैं?',
      writeSomethingHere: 'यहाँ कुछ लिखें...',
      submit: 'सबमिट',

      // security
      loginAndSecurity: 'लॉगिन और सुरक्षा',
      logOut: 'लॉग आउट',
      deactivateAccount: 'खाता निष्क्रिय करें',

      // search
      searchItems: 'आइटम्स खोजें',
      male: 'पुरुष',
      female: 'महिला',
      where: 'कहाँ',
      what: 'क्या',
      selectCategory: 'श्रेणी चयन करें',
      when: 'कब',
      searchProducts: 'उत्पाद खोजें',
      clear: 'साफ करें',

      // raise complaint
      raisingComplain: 'शिकायत दर्ज कर रहे हैं',
      viewDetails: 'विवरण देखें',
      tellUsYourProblem: 'हमें अपनी समस्या बताएं',
      gotDamagedItem: 'चीज घातक हो गई है',
      productWasIncorrect: 'उत्पाद गलत था',
      delayInDelivery: 'वितरण में देरी',
      stain: 'दाग',
      other: 'अन्य',
      writeYourMessageHere: 'यहां अपना संदेश लिखें...',
      uploadItemImages: 'यदि कोई है, तो आइटम छवियाँ अपलोड करें',
      raiseComplain: 'शिकायत दर्ज करें',

      // payouts
      payouts: 'पेआउट्स',
      savedUPIID: 'सहेजे गए UPI ID',
      addNew: 'नया जोड़ें',
      savedAccountDetails: 'सहेजे गए खाता विवरण',
      addBank: 'बैंक जोड़ें',
      checkPaymentHistory: 'भुगतान इतिहास देखें',
      paymentHistory: 'भुगतान इतिहास',
      customerName: 'ग्राहक का नाम',
      received: 'प्राप्त',
      date: 'तारीख',
      viewBookingDetails: 'बुकिंग विवरण देखें',


      },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
