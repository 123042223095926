import React, { useEffect, useState } from "react";
import axios from "axios";
import { urls, routes, defaultId } from "../../Global";
import ProductItem from "./ProductItem";
import Cookies from "universal-cookie";
import Featured from "./Featured";
import Filters from "./Filters";
import { ReactSVG } from "react-svg";
import filter from "../../assets/svg_icons/filter.svg";
import sort from "../../assets/svg_icons/sort.svg";
import search from "../../assets/svg_icons/search.svg";
import Sort from "./Sort";
import Search from "./Search";
import { jsonToQueryString } from "../../lib/helper";
import useObserver from "../../hooks/useObserver";
import ExtraFilters from "./ExtraFilters";
import { useTranslation } from 'react-i18next';

function Trending() {
    const [trending, setTrending] = useState([]);
    const [popup, setPopup] = useState(false);
    const cookies = new Cookies();
    const id = cookies.get("id") || defaultId;
    const [hasMore, setHasMore] = useState(false);
    const [category, setCategory] = useState(false);
    const [categoryChange, setCategoryChange] = useState(false);
    const [sortChange, setSortChange] = useState(false);
    const [searchChange, setSearchChange] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [categories, setCategories] = useState([]);

    const [filterData, setFilterData] = useState({
        // sortHighToLow: true,
        itemsearch: false,
        // selectedRating: false,
        // selectedPoularity: false,

        // start_time: false,
        // end_time: false,
        // product_gender: 0,
        // latitude: false,
        // longitude: false,
        limit: 100,
        offset: 0,
    });

    function applySort(e) {
        setFilterData((prevState) => ({
            ...prevState,
            sortHighToLow: e.sortHighToLow,
            selectedRating: e.rating,
            selectedPoularity: e.popularity,
            offset: 0,
        }));
        setSortChange(true);
    }

    function applySearch(e) {
        setFilterData((prevState) => ({
            ...prevState,
            product_gender: e.gender,
            offset: 0,
            ...(e.category ? { cat_id: e.category } : {}),
            ...(e.longitude ? { longitude: e.longitude, latitude: e.latitude } : {}),
            ...(e.start_time ? { start_time: e.start_time } : {}),
            ...(e.end_time ? { end_time: e.end_time } : {}),
        }));
        setSearchChange(true);
    }

    function applyFilters(f = {}) {
        setFilterData((prevState) => ({
            ...prevState,
            offset: 0,
            ...f,
        }));
        setSearchChange(true);
    }

    function clearFilters() {
        setFilterData((prevState) => {
            const nd = { ...prevState };
            delete nd.selectedRating;
            delete nd.bodytype;
            delete nd.brand_name;
            delete nd.product_colors;
            delete nd.product_fabric;
            delete nd.low_price;
            delete nd.high_price;
            delete nd.distance_high;
            delete nd.waist_size;
            delete nd.thighs_size;
            delete nd.shoulder_size;
            return {
                ...nd,
                offset: 0,
            };
        });
        setSearchChange(true);
    }

    function clearSearchFilter() {
        setFilterData((prevState) => {
            const nd = { ...prevState };
            delete nd.cat_id;
            delete nd.longitude;
            delete nd.latitude;
            delete nd.start_time;
            delete nd.end_time;

            return {
                ...nd,
                offset: 0,
            };
        });
        setSearchChange(true);
    }

    function handleCategory(e) {
        setCategory(e);
        setFilterData((prevState) => ({
            ...prevState,
            offset: 0,
            cat_id: e,
        }));

        setCategoryChange(true);
    }

    useEffect(
        function () {
            if (categoryChange) {
                fetchProducts();
                setCategoryChange(false);
            }
        },
        [categoryChange]
    );

    useEffect(
        function () {
            if (sortChange) {
                fetchProducts();
                setSortChange(false);
            }
        },
        [sortChange]
    );

    useEffect(
        function () {
            if (searchChange) {
                fetchProducts();
                setSearchChange(false);
            }
        },
        [searchChange]
    );

    /*async function fetchProducts() {
        try {
            const query = jsonToQueryString(filterData);
            let demo = "itemsearch=false&limit=100&offset=0";
            let products = await axios.get(urls.serverUrl + routes.getProducts + `/${id}?${demo}`);
            // itemsearch=false&limit=6&offset=0 , 'query' in place of demo
            console.log("showing products: ", id, query);
            setTrending(products.data.products);
            setFilterData((prevState) => ({
                ...prevState,
                limit: 24,

                offset: prevState.offset,
            }));
            setHasMore(true);
        } catch (err) {
            setTrending([]);
            setHasMore(false);
        }
    }*/

    
    async function fetchProducts() {
        try {
            const query = jsonToQueryString(filterData);
            let products = await axios.get(urls.serverUrl + routes.getProducts + `/${id}?${query}`);
            setTrending(products.data.products); 
            setFilterData((prevState) => ({
                ...prevState,
                offset: prevState.offset + prevState.limit,
            }));
            setHasMore(true);
        } catch (err) {
            setTrending([]);
            setHasMore(false);
        }
    }

    async function fetchCategory() {
        try {
            const res = await axios.get(
                "https://api.anywherecloths.com/api/v1/resources/gender-category"
            );
            setCategories(res.data.items_gender_details);
        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {
        if (!filterData.itemsearch) return;

        clearTimeout(timeoutId);

        const newTimeoutId = setTimeout(() => {
            fetchProducts();
        }, 1000);

        setTimeoutId(newTimeoutId);
    }, [filterData.itemsearch]);

    function changeSearch(e) {
        if (e.target.value !== "") {
            setFilterData({
                ...filterData,
                offset: 0,
                iteamsearch: e.target.value,
            });
        } else {
            setFilterData({
                ...filterData,
                offset: 0,
                iteamsearch: false,
            });
        }
    }

    function togglePopup(name) {
        if (popup === name) {
            setPopup(false);
        } else {
            setPopup(name);
        }
    }

    async function fetchTrending() {
        try {
            if (!hasMore) {
                return;
            }
            const query = jsonToQueryString(filterData);

            let products = await axios.get(urls.serverUrl + routes.getProducts + `/${id}?${query}`);

            setTrending((prevTrending) => [...prevTrending, ...products.data.products]);

            if (products.data.products.length) {
                setFilterData((prevState) => ({
                    ...prevState,
                    offset: prevState.offset + prevState.limit,
                }));
                setHasMore(true);
            }

            setHasMore(false);
        } catch (err) {
            console.log(err);
            setHasMore(false);
        }
    }

    const observerTargetRef = useObserver(fetchTrending);

    useEffect(function () {
        fetchCategory();
        fetchProducts();
    }, []);

    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
      if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
      } else {
          i18n.changeLanguage('en');
      }
    }, [storedLanguage, i18n]);


    return (
        <>
            <Featured />
            {categories.length > 0 ? (
                <Filters
                    active={category}
                    categories={categories}
                    changeCategory={handleCategory}
                />
            ) : (
                ""
            )}
            <div className="uk-section uk-section-products uk-position-relative">
                <div className="uk-container">
                    <div
                        className="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap"
                        data-uk-margin="margin: uk-margin-top">
                        <div>
                            <p className="uk-text-6 uk-text-fw-600">{t('trendingProducts')}</p>
                        </div>
                        <div className="uk-flex uk-flex-middle uk-section-filter-cnt">
                            <div className="uk-input-search">
                                <ReactSVG src={search} />
                                <input
                                    className="uk-input"
                                    onFocus={(e) => setPopup("search")}
                                    placeholder={t('search')}
                                    onChange={changeSearch}
                                />
                            </div>
                            <div>
                                {popup === "sort" ? (
                                    <Sort
                                        onClose={function () {
                                            setPopup(false);
                                        }}
                                        submit={applySort}
                                        filterData={filterData}
                                    />
                                ) : popup === "search" ? (
                                    <Search
                                        onClose={function () {
                                            setPopup(false);
                                        }}
                                        submit={applySearch}
                                        categories={categories}
                                        filterData={filterData}
                                        clear={clearSearchFilter}
                                    />
                                ) : (
                                    ""
                                )}

                                {popup === "filters" ? (
                                    <ExtraFilters
                                        onClose={function () {
                                            setPopup(false);
                                        }}
                                        submit={applyFilters}
                                        filterData={filterData}
                                        clearFilters={clearFilters}
                                    />
                                ) : null}

                                <ReactSVG
                                    src={sort}
                                    onClick={() => togglePopup("sort")}
                                    className="uk-margin-medium-left uk-button"
                                />
                            </div>
                            <ReactSVG
                                src={filter}
                                onClick={() => togglePopup("filters")}
                                className="uk-margin-medium-left uk-button"
                            />
                        </div>
                    </div>
                    {trending.length > 0 ? (
                        <>
                            <div
                                className="uk-child-width-1-3@m uk-grid-products"
                                id="scrollableDiv"
                                data-uk-grid>
                                {trending.map((item, index) => (
                                    <div key={index}>
                                        <ProductItem item={item} />
                                    </div>
                                ))}
                            </div>
                            {hasMore ? (
                                <div className="uk-text-center uk-margin-large-top">
                                    <span data-uk-spinner></span>
                                </div>
                            ) : (
                                ""
                            )}
                            <div ref={observerTargetRef}></div>
                        </>
                    ) : (
                        <>
                            <div className="uk-margin-medium-top">
                                <p>No Products Found</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Trending;
