import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./Calendar.css";

export const Calendar = ({ handleDateRangeChange }) => {
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });

    const handleChange = (ranges) => {
        const { startDate, endDate } = ranges.selection || {
            startDate: new Date(),
            endDate: new Date(),
        };
        const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

        // Calculate the start and end dates for the three-day range
        const beforeStartDate = new Date(startDate.getTime() - oneDay);
        const afterEndDate = new Date(endDate.getTime() + oneDay);

        const newDateRange = {
            startDate: beforeStartDate,
            endDate: afterEndDate,
            key: "selection",
        };

        setDate(newDateRange);
        handleDateRangeChange(newDateRange);
    };

    return (
        <div>
            <DateRangePicker ranges={[date]} onChange={handleChange} minDate={new Date()}>
                {({ calendar }) => {
                    const today = new Date();
                    const currentMonth = today.getMonth();
                    const currentYear = today.getFullYear();

                    calendar.setFocusedInput("startDate");
                    calendar.setShownDate(today);

                    return null;
                }}
            </DateRangePicker>
        </div>
    );
};
