import React, { useState, useRef } from "react";
import videoIcon from "../../assets/svg_icons/camera.svg";
import { ReactSVG } from "react-svg";
import { useEffect } from "react";

const VideoUpload = ({ maxFiles, setParentFiles, onVideoLengthExceded }) => {
    const [dragging, setDragging] = useState(false);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const MAX_VIDEO_DURATION = 60;

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const newFiles = [...e.dataTransfer.files];
        handleFileSelect(newFiles);
    };

    const handleFileSelect = (selectedFiles) => {
        setFiles((prevFiles) => {
            if (maxFiles && prevFiles.length >= maxFiles) return prevFiles;

            const filesWithBase64 = [];
            const reader = new FileReader();

            const supportedVideoTypes = ["video/mp4", "video/webm", "video/ogg"]; // Add more video types as needed

            reader.onload = (event) => {
                const videoElement = document.createElement("video");
                videoElement.src = event.target.result;

                videoElement.onloadedmetadata = () => {
                    const videoDuration = videoElement.duration;

                    if (videoDuration > MAX_VIDEO_DURATION) {
                        // Handle video duration exceeding the allowed limit
                        alert("Video duration exceeds the allowed limit (1 minute). Please select a shorter video.");
                    } else {
                        filesWithBase64.push({
                            file: selectedFiles[filesWithBase64.length],
                            base64: event.target.result,
                        });
                        if (filesWithBase64.length === selectedFiles.length)
                            setFiles([...prevFiles, ...filesWithBase64]);
                        else reader.readAsDataURL(selectedFiles[filesWithBase64.length]);
                    }
                };
            };

            if (supportedVideoTypes.includes(selectedFiles[0].type)) {
                reader.readAsDataURL(selectedFiles[0]);
            }

            return prevFiles;
        });
    };


    useEffect(
        function () {
            if (setParentFiles) {
                setParentFiles(files);
            }
        },
        [files, setParentFiles]
    );

    const removeFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            fileInputRef.current.value = "";
            return newFiles;
        });
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const fileList = files.map((file, index) => (
        <li key={file.file.name}>
            {file.file.name}
            <button
                data-uk-close
                onClick={(e) => {
                    e.stopPropagation();
                    removeFile(index);
                }}
            ></button>
        </li>
    ));

    return (
        <div
            className={`dropzone ${dragging ? "dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
        >
            <div className="uk-flex uk-flex-center">
                <ReactSVG src={videoIcon} />
                <p className="uk-color-green uk-margin-small-left">Upload video here</p>
            </div>
            <input
                ref={fileInputRef}
                type="file"
                accept="video/*"
                onChange={(e) => handleFileSelect(Array.from(e.target.files))}
                multiple
                style={{ display: "none" }}
            />
            {fileList.length > 0 && <ul className="uk-list-files">{fileList}</ul>}
        </div>
    );
};

export default VideoUpload;