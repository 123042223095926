import axios from "axios";
import { urls, routes, defaultImage } from "../Global";
import Cookies from "universal-cookie";
import { setDefaults, RequestType, geocode } from "react-geocode";

export async function getCoins() {
	const cookies = new Cookies();
	const id = cookies.get("id");
	var coins = 0;
	try {
		const res = await axios.get(
			urls.serverUrl + routes.userVerified + `/${id}`
		);
		coins = res.data.user_details[0].coin;
	} catch (err) {
		console.log(err);
		coins = 0;
	}
	return coins;
}

export async function calculateDateDiff(startDate, endDate) {
	return Math.ceil(Math.abs(startDate - endDate) / (1000 * 3600 * 24));
}

export function formatDate(date) {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
	const year = date.getFullYear();

	return `${month}/${day}/${year}`;
}

export const jsonToQueryString = (json) => {
	return Object.keys(json)
		.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(json[key]))
		.join("&");
};

export function calculateRent(mrp = 0, productAge = 0, numberOfUsage = 0) {
	const rate = 4.5;

	const mrpValue = parseFloat(mrp) || 0;

	const productAgeValue = parseFloat(productAge) || 0;

	const numberOfUsageValue = parseFloat(numberOfUsage) || 0;

	if (mrpValue !== 0 && productAgeValue !== 0 && numberOfUsageValue !== 0) {
		const amount = mrpValue * Math.pow(1.045, productAgeValue);

		const suggestedAmmount = amount - numberOfUsageValue * 0.025 * amount;

		const suggestedAmmountLow = suggestedAmmount * 0.12;
		const suggestedAmmountHigh = suggestedAmmount * 0.18;

		return [parseInt(suggestedAmmountLow), parseInt(suggestedAmmountHigh)];
	}

	return null;
}

export async function getAddressFromCoordinates(latitude, longitude) {
	setDefaults({
		key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		language: "en",
		region: "es",
	});

	const { results } = await geocode(
		RequestType.LATLNG,
		`${latitude},${longitude}`
	);

	return results[0].formatted_address;
}

export function addDefaultSrc(ev) {
	ev.target.src = defaultImage;
}
