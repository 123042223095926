import React, { createContext, useEffect, useState } from "react";
import { routes, urls } from "../Global";
import axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";

const EditProductContext = createContext();

function EditProductContextProvider(props) {
    const [resources, setResources] = useState(undefined);
    const [productDetails, setProductDetails] = useState(undefined);
    const [products, setProducts] = useState([]);
    const cookies = new Cookies();

    const { product_id } = useParams();
    const id = cookies.get("id")
    const reseller_id = cookies.get('reseller_id')

    async function fetchResources() {
        try {
            const res = await axios.get(urls.serverUrl + routes.resources)
            setResources(res.data.items_details)
            // console.log(res);
        } catch (err) {
            // console.log("in err res");
            console.log(err)
            setResources(undefined)
        }
    }

    async function fetchProduct() {
        try {
            // const res = await axios.get(urls.serverUrl + routes.getProduct + `/${id}?product_id=${product_id}`)
            const res = await axios.post(
                            urls.serverUrl +
                                routes.getResellerBookings +
                                `/${reseller_id}?limit=1000&offset=0`
                        );
            const desiredProduct = res.data.data.find(product => product.product_id == product_id);
            console.log("desiredProduct", desiredProduct, product_id);

            if (desiredProduct) {
                console.log("Found product:", desiredProduct);
                setProductDetails(desiredProduct);
            } else {
                console.log("Product not found");
                setProductDetails(undefined); 
}
            // console.log(`/${id}?product_id=${product_id}`, res)
        } catch (err) {
            console.log(err)
            setProductDetails(undefined)
        }
    }

    // async function fetch() {
    //     try {
    //         const get = await axios.post(
    //                         urls.serverUrl +
    //                             routes.getResellerBookings +
    //                             `/${reseller_id}?limit=1000&offset=0`
    //                     );
    //         const specificProduct = get.data.data.find(
    //             (product) => product.product_id === 158
    //         );

    //         if (specificProduct) 
    //             setProducts([specificProduct]);
    //         else
    //             setProducts(get.data.data);

    //     } catch (err) {
    //         // console.log(err)
    //         setProducts([])
    //     }
    // }

    // async function fetchProduct() {
    //     try {
    //         const get = await axios.post(
    //                         urls.serverUrl +
    //                             routes.getResellerBookings +
    //                             `/${reseller_id}?limit=1000&offset=0`
    //                     );
    //         // setProducts(get.data.data)
    //     } catch (err) {
    //         // console.log(err)
    //         // setProducts([])
    //     }
    // }

    useEffect(function () {
        fetchResources()
        fetchProduct()
        // eslint-disable-next-line
    }, [])

    if (resources === undefined) return;
    if (productDetails === undefined) return;

    return (
        <EditProductContext.Provider
            value={{ resources, productDetails }}>
            {props.children}
        </EditProductContext.Provider>
    );
}

export default EditProductContext;
export { EditProductContextProvider };