import play from "../assets/svg_icons/play.svg";
import file from "../assets/svg_icons/file.svg";
import { ReactSVG } from "react-svg";

const GuideItem = ({ guide }) => (
    <div className="uk-card uk-card-guide-item">
        <div className="uk-card-body">
            <a href={guide.video_link}>
                <div className="uk-position-relative">
                    <canvas width="400" height="250"></canvas>
                    <img className="uk-cover" src={guide.image_link} />
                    <ReactSVG src={guide.image_link ? file : play} className="uk-icon-guide-item uk-position-center uk-position-z-index" />
                    <div className="uk-background-wash-guide-item uk-position-cover"></div>
                </div>
            </a>
            <p className="uk-text-7 uk-margin-xsmall-top">{guide.guide_decs}</p>
        </div>
    </div>
)

export default GuideItem;