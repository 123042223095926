import {React, useEffect} from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import trash from "../../assets/svg_icons/trash.svg";
import axios from "axios";
import { routes, urls } from "../../Global";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next'; 


function CartItem(props) {
  const item = props.item.product;
  const cart_id = props.item.cart_id;
  const cookies = new Cookies();
  const id = cookies.get("id");
  async function removeFromWishlist() {
    try {
      const remove = await axios.delete(
        urls.serverUrl + routes.removeFromWishlist + `/${id}`, {
          data: {
            "product_id": item.product_id
          }
        }
      );
      props.remove(cart_id);
    } catch (err) {
      console.log(err.response);
    }
  }

  const storedLanguage = localStorage.getItem('language');
  // console.log(storedLanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
    } else {
        i18n.changeLanguage('en');
    }
  }, [storedLanguage, i18n]);


  return (
    <div className="uk-card uk-card-cart-item">
      <div className="uk-card-body uk-flex uk-flex-between uk-flex-wrap">
        <div className="uk-width-1-3@s">
          <img
            src={item.product_images[0].image_link}
            alt={item.product_title}
          />
        </div>
        <div className="uk-flex uk-flex-between uk-flex-column uk-width-expand" data-uk-margin="margin: uk-margin-top">
          <div>
            <p>
              <strong>{item.product_title}</strong>
            </p>
            <p style={{ marginTop: "4px", fontWeight: "300" }}>
              {t('price')}: <strong>₹{item.product_price}</strong>
            </p>
          </div>
          <div data-uk-grid className="uk-grid uk-grid-small">
            <div className="uk-width-expand@m">
              <Link
                to={`/product/${item.product_id}`}
                className="uk-button uk-button-primary">
                {t('rentNow')}
              </Link>
            </div>
            <div>
              <button
                className="uk-button uk-button-danger"
                onClick={removeFromWishlist}>
                <ReactSVG src={trash} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
