import React, { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { ReactSVG } from "react-svg";
import calendar from "../assets/svg_icons/calendar-2.svg";
import calender from "../assets/svg_icons/calender.svg";
import guide from "../assets/svg_icons/guide.svg";
import messages from "../assets/svg_icons/messages.svg";
import avatar from "../assets/svg_icons/avatar.svg";
import SellerProfilePopup from "./ProfilePopup";
 
function Header() {
    const [popup, setPopup] = useState(false);

    return (
        <>
            <header className="uk-position-z-index uk-position-relative">
                <nav className="uk-navbar-container uk-visible@m">
                    <div data-uk-navbar>
                        <div className="uk-navbar-left">
                            <NavLink to="/">
                                <img src="/images/logo.png" decoding="async" loading="lazy" 
                                    style={{ borderRadius: "30px"}} /> 
                            </NavLink>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav uk-flex-middle">
                                <li>
                                    <NavLink to="/seller/calender">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={calendar} />
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/seller/bookings">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={calender} />
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/seller/guide">
                                        <span className="uk-icon uk-icon-navbar uk-icon-guide">
                                            <ReactSVG src={guide} />
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/seller/inbox">
                                        <span className="uk-icon uk-icon-navbar">
                                            <ReactSVG src={messages} />
                                        </span>
                                    </NavLink>
                                </li>
                                <li onClick={() => setPopup("profile")}>
                                    <span className="uk-icon uk-icon-navbar" style={{cursor: "pointer"}}>
                                        <ReactSVG src={avatar} />
                                    </span>
                                    {popup === "profile" ? (
                                        <SellerProfilePopup
                                            onClose={function () {
                                                setPopup(false);
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <nav className="uk-navbar-container uk-hidden@m">
                    <div data-uk-navbar>
                        <div className="uk-navbar-left">
                            <NavLink to="/">
                                <img src="/images/logo.png" decoding="async" loading="lazy" />
                            </NavLink>
                        </div>
                        <div className="uk-navbar-right">
                            <a
                                href="#offcanvas-nav"
                                data-uk-toggle=""
                                className="uk-hamburger uk-style-tertiary uk-text-center uk-margin-left"
                                aria-expanded="false">
                                <span className="uk-navbar-toggle-icon">
                                    <svg
                                        className=""
                                        width="26"
                                        height="20"
                                        viewBox="0 0 26 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0" width="26" height="2"></rect>
                                        <rect y="9" width="26" height="2"></rect>
                                        <rect y="18" width="26" height="2"></rect>
                                    </svg>
                                </span>
                            </a>
                            <div id="offcanvas-nav" data-uk-offcanvas="overlay: true">
                                <div className="uk-offcanvas-bar mobile-menu">
                                    <button
                                        className="uk-offcanvas-close uk-color-primary"
                                        type="button"
                                        data-uk-close></button>
                                    <ul className="uk-navbar-nav">
                                        <li>
                                            <NavLink to="/seller/calender">
                                                <span className="uk-icon uk-icon-navbar">
                                                    <ReactSVG src={calendar} />
                                                </span>
                                                <span>Calender</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/seller/bookings">
                                                <span className="uk-icon uk-icon-navbar">
                                                    <ReactSVG src={calender} />
                                                </span>
                                                <span>Bookings</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/seller/bookings">
                                                <span className="uk-icon uk-icon-navbar uk-icon-guide">
                                                    <ReactSVG src={guide} />
                                                </span>
                                                <span>Product Guide</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/seller/inbox">
                                                <span className="uk-icon uk-icon-navbar">
                                                    <ReactSVG src={messages} />
                                                </span>
                                                <span>Messages</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <Link to="/logout">Logout</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <Outlet />
        </>
    );
}

export default Header;
