import { React, useState, useEffect } from "react";
import axios from "axios";
import { urls, routes } from "../../Global";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg"; 
import FileUpload from "../../Components/Helpers/FileUpload";
import VideoUpload from "../../Components/Helpers/VideoUpload";
import Alert from "../../Components/Helpers/Alert";
import { useTranslation } from 'react-i18next'; 


function EditMedia() {

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

	const { product_id } = useParams();
	const navigate = useNavigate();

	const location = useLocation();

	const [responseMessage, setResponseMessage] = useState({
		type: "",
		message: "",
	});

	const [images, setImages] = useState(false);
	const [video, setVideo] = useState(false);
	const [productImages, setProductImages] = useState(false);

	function goBack() {
		navigate(-1);
	}

	async function getImages() {
		try {
			const images = await axios.get(
				urls.serverUrl + routes.getProductImages + `/${product_id}`
			);
			setProductImages(images.data.data);
		} catch (err) {
			setProductImages(false);
			// console.log(err)
		}
	}

	async function deleteImage(e) {
		try {
			if (!window.confirm("Are you sure you want to delete image?")) {
				return false;
			}
			await axios.delete(
				urls.serverUrl + routes.deleteProduct + `/${product_id}`,
				{
					data: {
						image_id: [e],
					},
				}
			);
			const newArray = productImages.filter((item) => item.image_id !== e);
			setProductImages(newArray);
		} catch (err) {}
	}

	useEffect(function () {
		getImages();
	}, []);

	function save(e) {
		e.preventDefault();
		const product = location.state.product;
		console.log("product.product_videos", product.product_videos);

		
		product.display_image = images[0];
		if (images.length + productImages.length < 3) {
			setResponseMessage({
				type: "danger",
				message: (t('select3Images')),
			});
			return;
		}
		if (video.length) {
			product["product_video"] = video[0].base64.split(",")[1];
		}
		if (images.length) {
			product["image_link"] = images.map(i => i.base64);
		}

		navigate(`/seller/edit-product/${product_id}/price`, {
			state: { product },
		});
    	console.log("navigate media", product)

	}

	const handleClick = () => {
		navigate("/seller/guide");
	};

	return (
		<>
			<div
				className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
					<img
						src="/images/side_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
				</div>
				<div className="uk-flex uk-flex-center uk-flex-middle">
					<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div className="uk-flex">
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left uk-icon-black"
									/>
								</button>
								<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
									{t('listProduct')}
								</h1>
							</div>
							<ReactSVG src={info} className="uk-icon-info" onClick={handleClick} />
						</div>
						{productImages.length > 0 ? (
							<>
								<div data-uk-slider className="uk-margin-top" data-uk-slider="finite: true">
									<div className="uk-slider-container">
										<ul
											className="uk-slider-product-images uk-slider-items uk-grid-small uk-child-width-1-3"
											data-uk-grid>
											{productImages.map((productImage, key) => (
												<li key={key}>
													<button
														type="button"
														onClick={() => deleteImage(productImage.image_id)}
														className="uk-position-top-right"
														data-uk-close="ratio: 0.8"></button>
													<img src={productImage.image_link} />
												</li>
											))}
										</ul>
									</div>
								</div>
							</>
						) : (
							""
						)}
						<form
							onSubmit={save}
							style={{ marginTop: "50px" }}
							className="uk-grid-small uk-child-width-1-1"
							data-uk-grid>
							<div>
								<p className="uk-text-muted uk-text-8-big">
									{t('uploadProductImages')}*
								</p>
								<div style={{ marginTop: "6px" }}>
									<FileUpload
										maxFiles={4}
										accept="image/*"
										setParentFiles={(e) => setImages(e)}
									/>
								</div>
							</div>
							<div>
								<p className="uk-text-muted uk-text-8-big uk-margin-large-top">
									{t('uploadVideo')}
								</p>
								<div style={{ marginTop: "6px" }}>
									<VideoUpload
										maxFiles={1}
										onVideoLengthExceded={(e) => {
											setResponseMessage({
												type: "danger",
												message: "Video length more than 1 min",
											});
										}}
										setParentFiles={(e) => setVideo(e)}
									/>
								</div>
							</div>
							<Alert
								message={responseMessage}
								reset={function () {
									setResponseMessage({
										type: false,
										message: false,
									});
								}}
							/>
							<div>
								<button
									type="submit"
									name="continue"
									className="uk-button uk-button-primary">
									{t('continue')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default EditMedia;
