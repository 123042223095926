import { ReactSVG } from "react-svg";
import pen from "../assets/svg_icons/pen.svg"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";


function SellerProductItem(props) {
    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);
    
    const product = props.product
    const navigate = useNavigate()

    function deleteBooking(product_id) {
        props.deleteBooking(product_id)
    }

    const statusMessages = {
      0: t('productIsLive'),
      1: t('productIsDeleted'),
      2: t('productIsOnHold'),
      3: t('productIsDisapproved'),
    };
    const statusCode = product.product_delete_flag;
    const statusMessage = statusMessages[statusCode] || 'Default Message';

    return (
        <>
            <div>
                <div className="uk-position-relative">
                    <div data-uk-grid className="uk-grid uk-grid-small">
                        <div className="uk-width-1-3@s">
                            <img src={`${product.product_images.length > 0 ? product.product_images[0].image_link : ''}`} alt={product.product_title} />
                        </div>
                        <div className="uk-width-2-3@s uk-position-relative">
                            <div className="uk-position-top-right">
                                <ReactSVG src={pen} className="uk-icon-pen uk-cursor-pointer" onClick={() => { navigate(`/seller/edit-product/${product.product_id}`) }} />
                            </div>
                            <p className="uk-text-bold" style={{ maxHeight: "24px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "calc(100% - 30px)" }}>{product.product_title}</p>
                            <p className="uk-text-muted uk-margin-xxsmall-top">₹{product.product_price}</p>
                            <p className="uk-color-green uk-margin-xxsmall-top uk-text-booking-count">{product.bookings.length > 0 ? product.bookings[0].number_of_bookings : '0'} {t('ongoingBookings')}</p>

                            <p className="uk-color-green uk-margin-xxsmall-top uk-text-booking-count" style={{ display: 'inline-block', border: '1px solid lightgreen', padding: '2px 5px', borderRadius: '10px'}}>{statusMessage}</p>

                            <div className="uk-grid uk-grid-small uk-child-width-1-2 uk-margin-small-top" data-uk-grid>
                                <div>
                                    <button className="uk-button uk-button-primary uk-width-1-1" onClick={() => props.viewBookings(product.product_id)} style={{padding: "4px"}}>
                                        {t('viewBookings')}
                                    </button>
                                </div>
                                <div>
                                    <button className="uk-button uk-button-danger uk-width-1-1" onClick={() => deleteBooking(product.product_id)} style={{padding: "4px"}}>
                                        {t('deleteProduct')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerProductItem;