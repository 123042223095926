const env = "prod";
var urls = {};
if (env === "dev") {
    urls.serverUrl = "http://localhost:3000/api/v1/";
    urls.clientUrl = "http://localhost:8000/";
} else if (env === "prod") {
    urls.serverUrl = "https://rental.setstatetech.com/api/v1/";//"https://api.anywherecloths.com/api/v1/";
    urls.clientUrl = "https://cloth.setstatetech.com/";//"https://rental.setstatetech.com/";//"https://anywherecloths.com/";
}

const defaultToken =
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyTnVtYmVyIjo4NDIwNDE5ODI4LCJpYXQiOjE2OTgzMjg1NzEsImV4cCI6MTY5OTE5MjU3MX0.HkR2Ewh53NbbaJnF0ZoSEAxQxcsA3rl7MoJF-PFah-M";
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyTnVtYmVyIjo4NDIwNDE5ODI4LCJpYXQiOjE3MDA3MzMzNTYsImV4cCI6MTcwMTU5NzM1Nn0.UISYuY8ORYTcFBwaGKrOKaUFnsAQfNhCCH-b-pF-iqs";

const defaultId = 23;

const defaultImage = "/images/def-image.png";

// const routes = {
//   auth: "create-user",
//   login: "number-with-login",
//   userVerified: "get-user-profile",
//   verifyOtp: "otp-verify",
//   getProducts: "get-product",
//   getProduct: "get-details-product",
//   getProductReviews: "get-product-review",
//   checkProductAvailability: "product-check-availability",
//   getWalletAmount: "get-wallet-total-amount",
//   createCart: "create-cart",
//   getCart: "get-all-cart-user",
//   removeCart: "delete-cart",
//   bookingAdd: "booking-add",
//   bookingDelete: "booking-delete",
//   paymentAdd: "payment-add",
//   getAllBookings: "get-all-booking",
//   getAllOngoing: "get-all-ongoing-booking",
//   getPaidBookings: "get-all-booking-paid",
//   getUserProfile: "get-user-profile",
//   updateUserProfile: "update-user-profile",
//   getAllCategories: "get-category",
//   raiseComplain: "raise-complain",
//   kycUserProfile: "kyc-user-profile",
//   userFeedback: "user-feedback",
//   inboxChat: "inbox-chat",
//   chatHistory: "chat-history",
//   messageChat: "message-chat",
//   customerOtpVerification: "customer-otp-verification",
//   resellerOtpVerification: "seller-otp-verification",
//   productReviewByBooking: "product-review-by-booking",
//   userReviewByBooking: "user-product-review-by-booking",
//   userCreateProductReview: "user-create-product-review",
//   createProductReview: "create-product-review",
//   transactionHistory: "transaction-wallets-history",
//   productGuides: "product-guide",
//   getWaitingForConfirmation: "get-waiting-for-confirmation",
//   bookingConfirm: "booking-confirm",
//   resellerOngoingBooking: "reseller-ongoing-booking",
//   resellerPastBooking: "reseller-past-booking",
//   getResellerBookings: "get-reseller-booking",
//   viewBookingDates: "view-booking-product-date",
//   resellerCreateProduct: "reseller-create-product",
//   resources: "resources",
//   resellerDeleteProduct: "reseller-product-delete",
//   editProduct: "edit-product"
// };

const routes = {
    auth: "create-user",
    login: "auth/login",
    userVerified: "profile/get",
    verifyOtp: "auth/otp-verify",
    getProducts: "product/get",
    getProduct: "product/details",
    ccavLink: "payment/ccav-request-handler",
    getProductReviews: "review/get-product-review",
    checkProductAvailability: "product/check-availability",
    addToWishlist: "wishlist/add",
    removeFromWishlist: "wishlist/delete",
    getCart: "wishlist/get",
    bookingAdd: "booking/add",
    bookingDelete: "booking/delete",
    bookingStatusChange: "reseller/booking-status-change",
    paymentAdd: "payment/add",
    verifyPayment: "payment/verify-payment-booking-status",
    getSellerBankDetails: "payment/get-payout-methods",
    upiAdd: "upi/add",
    upiDelete: "upi/delete",
    upiEdit: "upi/edit",
    bankDetailsAdd: "bank_details/add",
    bankDetailsEdit: "bank_details/edit",
    bankDetailsDelete: "bank_details/delete",
    paymentHistory: "payment/get-history",
    getBooking: "booking/get",
    getUserProfile: "profile/get",
    updateUserProfile: "profile/update",
    userBookingComplain: "complain/user-booking-complain",
    kycUserProfile: "profile/kyc-update",
    userFeedback: "feedback/add",
    inboxChat: "inbox/get",
    chatHistory: "messages/get",
    messageChat: "messages/add",
    customerOtpVerification: "inbox/user-otp-verification",
    resellerOtpVerification: "inbox/reseller-otp-verification",
    productReviewByBooking: "review/get-pending-product-reviews-for-user",
    userReviewByBooking: "review/get-pending-user-reviews-for-reseller",
    createUserReview: "review/add-user-review",
    createProductReview: "review/add-product-review",
    transactionHistory: "wallet/get-transaction",
    productGuides: "guide/get",
    getResellerBookings: "reseller/get-product",
    viewBookingDates: "reseller/get-calender-details",
    resellerCreateProduct: "reseller/add-product",
    resources: "resources/get",
    resourcesConfig: "resources/get-configuration",
    resellerDeleteProduct: "product/delete",
    editProduct: "reseller/edit-product",
    deactivateAccount: "profile/deactivate",
    getProductImages: "product/get-images",
    deleteProduct: "product/delete-images",
    likeProduct: "product/like",
    userseenlog: "messages/add-user-log",
    dislikeProduct: "product/dislike",
};

module.exports = { env, urls, routes, defaultId, defaultToken, defaultImage };
