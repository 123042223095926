import { React, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg";
import FileUpload from "../../Components/Helpers/FileUpload";
import VideoUpload from "../../Components/Helpers/VideoUpload";
import Alert from "../../Components/Helpers/Alert";
import { useTranslation } from 'react-i18next'; 


function Media() {

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

	const navigate = useNavigate();

	const location = useLocation();

	const [responseMessage, setResponseMessage] = useState({
		type: "",
		message: "",
	});

	const [images, setImages] = useState([]);
	const [video, setVideo] = useState(false);

	function goBack() {
		navigate(-1);
	}

	function save(e) {
		e.preventDefault();
		const product = location.state.product;
		product.display_image = images[0];

		if (!images.length || images.length < 3) {
			setResponseMessage({
				type: "danger",
				message: "Please select exactly 3 images!",
			});
			return;
		}

		product["image_link"] = images.map(v => v.base64);
		
		if (!video[0]) {
			setResponseMessage({ type: "danger", message: "A Video is required!" });
			return;
		}
		product["video_link"] = video[0].base64.split(",")[1];
		navigate("/seller/add-product/price", {
			state: { product },
		});
	}

	const handleClick = () => {
		navigate("/seller/Guide");
	};

	return (
		<>
			<div
				className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
					<img
						src="/images/side_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
				</div>
				<div className="uk-flex uk-flex-center uk-flex-middle">
					<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div className="uk-flex">
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left uk-icon-black"
									/>
								</button>
								<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
									{t('listProduct')}
								</h1>
							</div>
							<ReactSVG src={info} className="uk-icon-info" onClick={handleClick} />
						</div>
						<form
							onSubmit={save}
							style={{ marginTop: "50px" }}
							className="uk-grid-small uk-child-width-1-1"
							data-uk-grid>
							<div>
								<p className="uk-text-muted uk-text-8-big uk-margin-large-top">
									{t('uploadProductImages')}*
								</p>
								<div style={{ marginTop: "6px" }}>
									<FileUpload
										accept="image/*"
										minFiles={3}
										setParentFiles={(e) => setImages(e)}
									/>
								</div>
							</div>
							<div>
								<p className="uk-text-muted uk-text-8-big uk-margin-large-top">
									{t('uploadVideo')}
								</p>
								<div style={{ marginTop: "6px" }}>
									<VideoUpload
										maxFiles={1}
										onVideoLengthExceded={(e) => {
											setResponseMessage({
												type: "danger",
												message: "Video length more than 1 min",
											});
										}}
										setParentFiles={(e) => setVideo(e)}
									/>
								</div>
							</div>
							<Alert
								message={responseMessage}
								reset={function () {
									setResponseMessage({
										type: false,
										message: false,
									});
								}}
							/>
							<div>
								<button
									type="submit"
									name="continue"
									className="uk-button uk-button-primary">
									{t('continue')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Media;
