import React, { createContext, useEffect, useState } from "react";
import { routes, urls } from "../Global";
import axios from "axios";

const ProductContext = createContext();

function ProductContextProvider(props) {
    const [resources, setResources] = useState(undefined);

    async function fetchResources() {
        try {
            const res = await axios.get(urls.serverUrl + routes.resources)
            setResources(res.data.items_details)
        } catch (err) {
            console.log(err)
            setResources(undefined)
        }
    }

    useEffect(function () {
        fetchResources()
        // eslint-disable-next-line
    }, [])

    return (
        <ProductContext.Provider
            value={{ resources }}>
            {props.children}
        </ProductContext.Provider>
    );
}

export default ProductContext;
export { ProductContextProvider };