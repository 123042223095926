import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { urls, routes } from "../../Global";
import Cookies from "universal-cookie";
import AuthContext from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import Alert from "../Helpers/Alert";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import { Link } from "react-router-dom";
// import info from "../../assets/svg_icons/info-light.svg";
// import facebook from "../../assets/svg_icons/facebook-fill.svg";
// import apple from "../../assets/svg_icons/apple.svg";
// import GoogleLoginButton from "./GoogleLogin";
import { useTranslation } from 'react-i18next';

function Login() {
	const [number, setNumber] = useState("");
	const [responseMessage, setResponseMessage] = useState({
		type: "",
		message: "",
	});
	const [isTermsAccepted, setisTermsAccepted] = useState(false);
	const cookies = new Cookies();
	const { getLoggedIn, myCookies, loggedIn } = useContext(AuthContext);
	const navigate = useNavigate();

	async function login(e) {
		try {
			e.preventDefault();
			if (!isTermsAccepted) {
				setResponseMessage({
					type: "danger",
					message: "Please accept terms and conditions",
				});

				return;
			}
			if (number) {
				let response = await axios.post(
					urls.serverUrl + routes.login,
					JSON.stringify({ number })
				);

				if (response.data.status === 200) {
					cookies.set(myCookies.number.name, number, {
						path: "/",
						expires: new Date(Date.now() + 2592000 * 1000),
					});
					cookies.set(
						myCookies.verification_code.name,
						response.data.results.verification_code,
						{
							path: "/",
							expires: new Date(Date.now() + 2592000 * 1000),
						}
					);
					await getLoggedIn();
					navigate("/verify-otp");
				}
			} else {
				setResponseMessage({
					type: "danger",
					message: "Enter your phone number",
				});
			}
		} catch (err) {
			console.log(err);
		}
	}

	const handlePhoneNumberChange = (e) => {
		const inputValue = e.target.value;
		const numericValue = inputValue.replace(/\D/g, "");
		const limitedValue = numericValue.slice(0, 10);
		setNumber(limitedValue);
	};

	function goBack() {
		navigate(-1);
	}

	useEffect(function () {
		if (loggedIn) {
			navigate("/", { replace: true });
		}
	});

	const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

	return (
		<>
			<div
				className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
					<img
						src="/images/side_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
				</div>
				<div className="uk-flex uk-flex-center uk-flex-middle">
					<div className="uk-container uk-container-signup-form uk-section">
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div>
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left uk-icon-black"
									/>
								</button>
							</div>
							{/* <div>
                <ReactSVG src={info} className="uk-icon-info" />
              </div> */}
						</div>
						<div style={{ marginTop: "34px" }}>
							<h1 className="uk-text-4 uk-text-fw-600">{t('welcomeBack')} 👋</h1>
						</div>
						<form
							onSubmit={login}
							style={{ marginTop: "50px" }}
							className="uk-grid-small uk-child-width-1-1"
							data-uk-grid>
							<div>
								<label className="uk-form-label" htmlFor="phone">
									{t('phone')}
								</label>
								<div className="uk-form-controls">
									<input
										type="tel"
										name="number"
										id="phone"
										value={number}
										onChange={handlePhoneNumberChange}
										className="uk-input"
									/>
								</div>
							</div>
							<div>
								<input
									type="checkbox"
									id="tac"
									checked={isTermsAccepted}
									onChange={(e) => setisTermsAccepted(e.target.checked)}
								/>
								<label htmlFor="tac">
									{t('iAccept')} 
									<Link to="/terms-and-conditions"> {t('terms')} </Link> 
									{t('and')}{" "}
									<Link to={"/terms-and-conditions"}>{t('conditions')}</Link>
								</label>
							</div>
							<Alert
								message={responseMessage}
								reset={function () {
									setResponseMessage({
										type: false,
										message: false,
									});
								}}
							/>
							<div>
								<button
									type="submit"
									name="login"
									className="uk-button uk-button-primary">
									{t('login')}
								</button>
							</div>
							{/* <p className="uk-text-8-big uk-text-center uk-margin-top uk-margin-bottom">Or sign in with</p>
              <ul className="uk-list uk-list-social-login">
                <li>
                  <ReactSVG src={facebook} />
                </li>
                <li>
                  <GoogleLoginButton />
                </li>
                <li>
                  <ReactSVG src={apple} />
                </li>
              </ul> */}
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
