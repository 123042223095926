import React, { useEffect } from "react";
import Header from "../Components/Helpers/Header";
import { Footer } from "../Components/Footer/Footer";
import { useTranslation } from 'react-i18next';


function Policy() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        } else {
          i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
        <Header/>
            <div className="uk-section">
                <div className="uk-container">
                    <div className="uk-wysiwyg-default">
                        <h1 className="uk-text-center">{t('privacyPolicy')}</h1>
                        <p>
                            {t('anywhereClothes')}
                        </p>
                        <ol className="uk-list uk-list-decimal">
                            <li>
                                <strong>{t('informationWeCollect')}: </strong>
                                {t('collectTypesInfo')}:
                                <ul className="uk-list uk-list-disc">
                                    <li>{t('contactInfo')}</li>
                                    <li>{t('profileInfo')}</li>
                                    <li>{t('transactionInfo')}</li>
                                    <li>{t('communicationInfo')}</li>
                                    <li>{t('paymentInfo')}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('howWeUseYourInformation')}: </strong>
                                {t('useYourPersonalInfo')}
                                <ul className="uk-list uk-list-disc">
                                    <li>{t('fulfillRentalOrders')}</li>
                                    <li>{t('communicateWithYou')}</li>
                                    <li>{t('improveServicesAndExperience')}</li>
                                    <li>{t('ensureSecurityAndIntegrity')}</li>
                                    <li>{t('personalizeExperience')}</li>
                                    <li>{t('complyWithLegalObligations')}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('informationSharing')}: </strong>
                                {t('serviceProviders')}
                                <ul className="uk-list uk-list-disc">
                                    <li>{t('thirdPartyVendors')}</li>
                                    <li>{t('legalCompliance')}</li>
                                    <li>{t('businessTransfers')}</li>
                                    <li>{t('withYourConsent')}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('dataSecurity')}: </strong>
                                {t('takeReasonableMeasures')} {t('dataTransmissionWarning')}
                            </li>
                            <li>
                                <strong>{t('yourChoices')}: </strong>
                                {t('reviewUpdateDeleteInfo')} {t('optOutMarketing')}
                            </li>
                            <li>
                                <strong>{t('cookiesAndTracking')}: </strong>
                                {t('useCookiesToEnhance')} {t('modifyBrowserSettings')}
                            </li>
                            <li>
                                <strong>{t('thirdPartyLinks')}: </strong>
                                {t('platformMayContainLinks')} {t('notResponsibleForPrivacy')} {t('encourageReviewPrivacyPolicies')}
                            </li>
                            <li>
                                <strong>{t('childrensPrivacy')}: </strong>
                                {t('servicesNotIntendedFor')} {t('notKnowinglyCollectPersonalInfo')} {t('awareThatCollectedInfoFromChild')}
                            </li>
                            <li>
                                <strong>{t('changesToPrivacyPolicy')}: </strong>
                                {t('mayUpdatePrivacyPolicy')} {t('continuedUseAfterChanges')}
                            </li>
                            <li>
                                <strong>{t('contactUs')}: </strong>
                                {t('questionsConcernsRequests')}
                            </li>
                            <li>
                                <strong>{t('termsAndConditions')}: </strong>
                                {t('visitTermsAndConditionsSection')} <a href="www.anywherecloths.com">www.anywherecloths.com</a>.
                            </li>
                        </ol>
                        <p>{t('acknowledgeReadUnderstoodAgree')}</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Policy;
