// PaymentConfirmation.js

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { urls, routes } from "../../Global";

import axios from "axios";

const PaymentConfirmation = () => {
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [bookingid, SetbookingId] = useState(0);
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(10);
    const [waiting, setWaiting] = useState(true);

    const location = useLocation();
    useEffect(() => {
        // Access location.pathname or perform other actions with location
        const arr = location.pathname.split("/");
        SetbookingId(arr[arr.length - 1]);
    }, [location]);

    useEffect(() => {
        if (seconds > 0) {
            const timerId = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);

            return () => clearTimeout(timerId);
        } else {
            navigate("/");
        }
    }, [seconds]);

    useEffect(() => {
        // Simulate a successful payment after a delay
        const simulatePayment = async () => {
            try {
                if (bookingid) {
                    const booking = await axios.get(
                        urls.serverUrl + routes.verifyPayment + "/" + bookingid
                    );
                    setPaymentStatus(booking.data.message.toLowerCase());
                    setWaiting(false);
                }
            } catch (error) {
                console.log(error);
                setWaiting(false);
            }
        };

        simulatePayment();
    }, [bookingid]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}>
            <div>
                {paymentStatus === "payment confirmed" && !waiting && (
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="https://imagetolink.com/ib/bqYYrI4HpC.png"
                            alt="done"
                            style={{ padding: 10, height: "auto", width: "15vw" }}
                        />

                        <p style={{ fontSize: "3rem", marginTop: 50 }}>Booking Confirmed</p>
                        <p style={{ fontSize: "1.5rem", marginTop: 50 }}>
                            Redirected in {seconds} seconds
                        </p>
                    </div>
                )}
                {paymentStatus !== "payment confirmed" && !waiting && (
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="https://imagetolink.com/ib/1aa88e1jCS.png"
                            alt="close"
                            style={{ padding: 10, height: "auto", width: "15vw" }}
                        />

                        <p style={{ fontSize: "3rem", marginTop: 50 }}>Booking Failed</p>
                        <p style={{ fontSize: "1.5rem", marginTop: 50 }}>
                            Redirected in {seconds} seconds
                        </p>
                    </div>
                )}

                {waiting && (
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="https://imagetolink.com/ib/47NdNgjdvL.gif"
                            alt="close"
                            style={{ padding: 10, height: "auto", width: "15vw" }}
                        />

                        <p style={{ fontSize: "3rem", marginTop: 50 }}>Loading ...</p>
                        <p style={{ fontSize: "1.5rem", marginTop: 50 }}>
                            Redirected in {seconds} seconds
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentConfirmation;
