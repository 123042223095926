import React, { useContext, useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router";
import { NavLink, Link, useSearchParams, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import edit from "../../assets/svg_icons/edit.svg";
import axios from "axios";
import { routes, urls } from "../../Global";
import AuthContext from "../../context/authContext";
import Alert from "../Helpers/Alert";
import { useTranslation } from 'react-i18next';
import tickSingle from "../../assets/svg_icons/tick-single.svg";
import chevronRight from "../../assets/svg_icons/chevron-right-dark.svg";
import checkout from "../../assets/svg_icons/checkout.svg";
import personalInfo from "../../assets/svg_icons/personal-info.svg";
import shield from "../../assets/svg_icons/shield.svg";
import payment from "../../assets/svg_icons/payment.svg";
import arrowRight from "../../assets/svg_icons/arrow-right.svg";

 
function Profile({ onClose }) {
    const popupRef = useRef(null);

	const { userStatus, setUserStatus } = useContext(AuthContext);
    const [verifiedString, setVerifiedString] = useState("");
    const [isDetailsVerified, setisDetailsVerified] = useState(false);
	const [isKycVerified, setisKycVerified] = useState(false);
	const [whatsAppNumber, setwhatsAppNumber] = useState("");

	const [userDetails, setUserDetails] = useState({
		name: "",
		storename: "",
		photo: "",
		adds: "",
		city: "",
		state: "",
		aadhaar_number: "",
		email: "",
		number: "",
	});
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const cookies = new Cookies();
	const redirectPath = searchParams.get("redirect");

	const id = cookies.get("id");

	const [responseMessage, setResponseMessage] = useState({
		type: false,
		message: false,
	});

	function goBack() {
		navigate(-1);
	}

	useEffect(
		function () {
			async function fetchUser() {
				try {
					let details = await axios.get(
						urls.serverUrl + routes.getUserProfile + `/${id}`
					);
					let appconfigres = await axios.get(urls.serverUrl + routes.resourcesConfig);

					if (details.data.user_details[0].user_details_status === 1) {
                        setVerifiedString("Phone Number Verified")
                        setisDetailsVerified(true);
                    }
                    if (details.data.user_details[0].user_kyc_status === 1) {
                        setVerifiedString("KYC Verified")
                        setisKycVerified(true);
                    }
					setUserDetails(details.data.user_details[0]);
					setisKycVerified(
						Boolean(details.data.user_details[0].user_kyc_status)
					);

					const wahtsAppConfigs = appconfigres.data.app_config_details.find(
						(v) => v.config_name === "WHATSAPP_CONTACT"
					);

					if (wahtsAppConfigs) {
						setwhatsAppNumber(wahtsAppConfigs.config_value);
					}

				} catch (err) {
					// console.log(err);
				}
			}

			fetchUser();
		},

		[id]
	);

	async function updateFields(event) {
		event.preventDefault(); // Prevent the default form submission behavior

		const formData = new FormData(event.target); // Create a FormData object from the form

		// Convert the FormData object to a plain object
		const updatedUserDetails = Object.fromEntries(formData.entries());

		// Create an array to store promises for file encoding
		const filePromises = [];

		// Check if any file inputs exist and convert them to base64
		for (const [fieldName, fieldValue] of formData.entries()) {
			const fieldInput = event.target.elements[fieldName];

			if (
				fieldInput.type === "file" &&
				fieldInput.files &&
				fieldInput.files.length > 0
			) {
				const selectedFile = fieldInput.files[0];
				const reader = new FileReader();

				const filePromise = new Promise((resolve) => {
					reader.onloadend = () => {
						const base64Data = reader.result.split(",")[1];
						updatedUserDetails[`${fieldName}_original`] = reader.result;
						updatedUserDetails[fieldName] = base64Data;
						resolve();
					};

					reader.readAsDataURL(selectedFile);
				});

				filePromises.push(filePromise);
			}
		}

		// Wait for all file encoding promises to resolve
		await Promise.all(filePromises);

		// Update the state with the new form field values (excluding file inputs)
		const combinedUserDetails = {
			...userDetails,
			...updatedUserDetails,
		};
		setUserDetails(combinedUserDetails);
		return combinedUserDetails;
	}

	async function updateProfile(e) {
		e.preventDefault();

		var details = await updateFields(e);

		//this indicates image field is empty
		if (typeof details.photo === "object") {
			details.photo = "";
			details.photo_original = "";
		}

		try {
			const updated = await axios.patch(
				urls.serverUrl + routes.updateUserProfile + `/${id}`,
				details
			);
			if (updated.data.status === 200) {
				setUserStatus(true);
				setResponseMessage({
					type: "success",
					message: "Profile updated successfully",
				});

				if (redirectPath) {
					navigate(redirectPath);
				}
			}
		} catch (err) {
			// console.log(err)
			setResponseMessage({
				type: "danger",
				message: "Could not update profile",
			});
		}
	}

	useEffect(() => {
	    const storedLanguage = localStorage.getItem('language');
	    
	    if (storedLanguage) {
	      switchLanguage(storedLanguage);
	    } else {
	      switchLanguage('en');
	    }
  	}, []);
	const { t, i18n } = useTranslation();
	const switchLanguage = (lang) => {
    console.log('Switching language to:', lang);
    i18n.changeLanguage(lang);
    // Store the language preference in localStorage
    localStorage.setItem('language', lang);
  };

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'en' ? 'hi' : 'en';
    switchLanguage(newLanguage);
  };

  	useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

	return (
		<>
			<div
				className="uk-card uk-card-profile uk-position-absolute uk-background-default uk-margin-right"
				ref={popupRef}>
				<div className="uk-card-body uk-padding-remove-vertical">
					<div className="uk-container-signup-form uk-section">
						{/*<button onClick={goBack} className="uk-button-noeffect">
							<ReactSVG
								src={arrowLeft}
								className="uk-icon-left-small uk-icon-black"
							/>
						</button>*/}
						<div className="uk-profile-card uk-flex uk-flex-middle">
							<div>
								<img src={userDetails.photo} alt="" />
							</div>
							<div
								style={{ maxWidth: "204px", width: "100%", marginLeft: "12px" }}
								className="uk-text-8-big uk-flex uk-flex-column uk-flex-center">
								<p className="uk-text-bold uk-text-primary">{userDetails.name}</p>
								<div className="uk-color-green uk-flex uk-flex-middle uk-margin-xxsmall-top">
									{verifiedString ? (
										<>
											<ReactSVG
												src={tickSingle}
												className="uk-margin-xxsmall-right"
											/>
											<span>{verifiedString}</span>
										</>
									) : null}
								</div>
								<div>
									{isDetailsVerified ? (
										isKycVerified ? null : (
											<Link
												to="/complete-kyc"
												className="uk-flex uk-link-text uk-text-meta uk-color-green">
												<span>Complete KYC</span>
												<ReactSVG
													src={chevronRight}
													className="uk-margin-left"
												/>
											</Link>
										)
									) : (
										<Link
											to="/profile"
											className="uk-flex uk-link-text uk-text-meta uk-color-green">
											<span>{t('completeProfile')}</span>
											<ReactSVG src={chevronRight} className="uk-margin-left" />
										</Link>
									)}
								</div>
							</div>
							<ReactSVG
								src={chevronRight}
								className="uk-width-expand uk-flex uk-flex-middle uk-flex-right"
							/>
						</div>

						<NavLink
							to={`/seller`}
							className="uk-button uk-button-black-bg uk-margin-top uk-width-1-1 uk-flex uk-flex-center uk-flex-middle">
							<ReactSVG src={checkout} className="uk-margin-small-right" />
							{t("rentOutYourOutfitsNow")}
						</NavLink>

						<p className="uk-text-bold uk-text-primary uk-margin-large-top">{t('accountSettings')}</p>

						<ul className="uk-list uk-list-border">
							<li>
								<NavLink
									to="/"
									className="uk-width-1-1 uk-flex uk-flex-middle">
									<ReactSVG
										src={personalInfo}
										className="uk-margin-xsmall-right"
									/>
									<span className="uk-text-8 uk-color-primary">
										{t('personalInformation')}
									</span>
									<ReactSVG
										src={chevronRight}
										className="uk-width-expand uk-text-right"
									/>
								</NavLink>
							</li>
							<li className="uk-flex uk-flex-middle uk-margin-remove-top">
								<NavLink
									to="/profile/security"
									className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
									<ReactSVG src={shield} className="uk-margin-xsmall-right" />
									<span className="uk-text-8">{t('loginAndSecurity')}</span>
									<ReactSVG
										src={chevronRight}
										className="uk-width-expand uk-text-right"
									/>
								</NavLink>
							</li>
							<li className="uk-flex uk-flex-middle uk-margin-remove-top">
								<NavLink
									to="/profile/payouts"
									className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
									<ReactSVG src={payment} className="uk-margin-xsmall-right" />
									<span className="uk-text-8">{t('payouts')}</span>
									<ReactSVG
										src={chevronRight}
										className="uk-width-expand uk-text-right"
									/>
								</NavLink>
							</li>
							{/* <li className="uk-flex uk-flex-middle uk-margin-remove-top">
		                        <NavLink to="/seller" className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
		                            <ReactSVG src={itemListing} className="uk-margin-xsmall-right" />
		                            <span className="uk-text-8">Item Listing</span>
		                            <ReactSVG
		                                src={chevronRight}
		                                className="uk-width-expand uk-text-right"
		                            />
		                        </NavLink>
		                    </li> */}
						</ul>

						<NavLink
							to="/seller"
							className="uk-button uk-button-lightgray uk-flex uk-flex-center uk-width-1-1 uk-text-8-big uk-margin-medium-top">
							{t('switchToSellerAccount')} 
							<ReactSVG src={arrowRight} className="uk-margin-left" />
						</NavLink>

						<p className="uk-text-bold uk-text-primary uk-margin-large-top">{t('support')}</p>

						<ul className="uk-list uk-list-border uk-margin-remove-top">
							{/* <li className="uk-flex uk-flex-middle">
		                        <span className="uk-text-8">How rented wardrobe works</span>
		                        <ReactSVG
		                            src={chevronRight}
		                            className="uk-width-expand uk-text-right"
		                        />
		                    </li> */}
							<li className="uk-margin-remove-top">
								<a
									target="_blank"
									rel="noreferrer"
									href={`https://wa.me/${whatsAppNumber}`}
									className="uk-text-primary uk-flex uk-flex-middle">
									<span className="uk-text-8">{t('customerSupport')}</span>
									<ReactSVG
										src={chevronRight}
										className="uk-width-expand uk-text-right"
									/>
								</a>
							</li>
							<li className="uk-flex uk-flex-middle uk-margin-remove-top">
								<NavLink
									to="/feedback"
									className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
									<span className="uk-text-8">{t('giveUsFeedback')}</span>
									<ReactSVG
										src={chevronRight}
										className="uk-width-expand uk-text-right"
									/>
								</NavLink>
							</li>
						</ul>

						<p className="uk-text-bold uk-margin-top uk-text-primary">{t('legal')}</p>
						<ul className="uk-list uk-list-border uk-margin-remove-top">
							<li>
								<NavLink
									to="/terms-and-conditions"
									className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
									<span className="uk-text-8">{t('termsOfServices')}</span>
									<ReactSVG
										src={chevronRight}
										className="uk-width-expand uk-text-right"
									/>
								</NavLink>
							</li>
							<li className="uk-color-primary uk-flex uk-flex-middle uk-margin-remove-top">
								<span className="uk-text-8">{t("privacyPolicies")}</span>
								<ReactSVG
									src={chevronRight}
									className="uk-width-expand uk-text-right"
								/>
							</li>
						</ul>

						{/*<div className="uk-flex uk-flex-center uk-margin-medium-top uk-margin-bottom">
							<div className="uk-avatar uk-position-relative">
								{userDetails.photo.photo_original ? (
									<img src={userDetails.photo_original} alt="Avatar" />
								) : userDetails.photo ? (
									<img src={userDetails.photo} alt="Avatar" />
								) : (
									<img src="/images/avatar-1.png" alt="Avatar" />
								)}
								<ReactSVG className="uk-icon-edit" src={edit} />
							</div>
						</div>*/}

						{/*<form
							className="uk-grid-small uk-child-width-1-1"
							onSubmit={updateProfile}
							data-uk-grid>
							<div>
								<label className="uk-form-label" htmlFor="name" style={{ color: 'black' }}>
									{t("name")}
								</label>
								<div className="uk-form-controls">
									<input
										type="text"
										name="name"
										defaultValue={userDetails.name}
										id="name"
										className="uk-input"
										required="true"
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="email" style={{ color: 'black' }}>
									{t("email")}
								</label>
								<div className="uk-form-controls">
									<input
										type="email"
										name="email"
										defaultValue={userDetails.email}
										id="email"
										className="uk-input"
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" style={{ color: 'black' }}>{t("phone")}</label>
								<div className="uk-form-controls">
									<input
										type="tel"
										defaultValue={userDetails.number}
										className="uk-input"
										disabled="true"
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="adds" style={{ color: 'black' }}>
									{t("address")}
								</label>
								<div className="uk-form-controls">
									<textarea
										name="adds"
										id="adds"
										defaultValue={userDetails.adds}
										className="uk-textarea"
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="city" style={{ color: 'black' }}>
									{t("city")}
								</label>
								<div className="uk-form-controls">
									<input
										type="text"
										name="city"
										defaultValue={userDetails.city}
										id="city"
										className="uk-input"
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="state" style={{ color: 'black' }}>
									{t("state")}
								</label>
								<div className="uk-form-controls">
									<input
										type="text"
										name="state"
										defaultValue={userDetails.state}
										id="state"
										className="uk-input"
									/>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="aadhaar_number" style={{ color: 'black' }}>
									{t("governmentId")}
								</label>
								<div className="uk-form-controls">
									<input
										type="number"
										name="aadhaar_number"
										defaultValue={userDetails.aadhaar_number}
										id="aadhaar_number"
										className="uk-input"
										minLength={12}
										maxLength={12}
										disabled={isKycVerified}
									/>
									{isKycVerified ? null : (
										<Link
											to="/complete-kyc"
											className="uk-align-right uk-link-text uk-text-9 uk-text-success">
											Complete KYC
										</Link>
									)}
								</div>
							</div>
							<div className="uk-margin" data-uk-margin>
								<label className="uk-form-label" htmlFor="form-stacked-text" style={{ color: 'black' }}>
									{t("photo")}
								</label>
								<div uk-form-custom="target: true" className="uk-display-block">
									<input
										type="file"
										name="photo"
										id="photo"
										accept="image/*"
										aria-label="Custom controls"
									/>
									<input
										className="uk-input"
										type="text"
										placeholder="Select Photo"
										aria-label="Custom controls"
										disabled
									/>
								</div>
							</div>
							<Alert
								message={responseMessage}
								reset={function () {
									setResponseMessage({
										type: false,
										message: false,
									});
								}}
							/>
							<div>
								<button
									type="submit"
									name="update_profile"
									className="uk-button uk-button-primary uk-width-expand">
									{t("photo")}
								</button>
							</div>
						</form>*/}
					<button
				        className="uk-button uk-button-default uk-text-success uk-margin-remove uk-padding-remove uk-padding-small uk-flex-center uk-width-1-1 uk-margin-medium-top"
				        onClick={toggleLanguage}> 
				        {i18n.language === 'en' ? 'हिंदी में बदलें' : 'Switch to English'} —>
			      	</button>
					</div>
				</div> 
			</div>
		</>
	);
}

export default Profile;
