import React from "react";
import Router from "./Router";
import { AuthContextProvider } from "./context/authContext";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { LanguageProvider } from './LanguageContext';

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <LanguageProvider>
          <I18nextProvider i18n={i18n}>
            <Router />
          </I18nextProvider>
        </LanguageProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
