import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { urls, routes } from "../../Global";
import Header from "../Helpers/Header";
import { Link, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import chevronRight from "../../assets/svg_icons/chevron-right.svg";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import ShareIcon from "@mui/icons-material/Share";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Skeleton from "@mui/material/Skeleton";

import { Grid, useMediaQuery, Box, createTheme, TextField, IconButton } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import ModalPortal from "../Helpers/ModalPortal";
import Alert from "../Helpers/Alert";
import openCage from "opencage-api-client";

import "yet-another-react-lightbox/styles.css";
import { CircularProgress, Stack } from "@mui/material";
import { ProductShare } from "./ProductShare";
import { Footer } from "../Footer/Footer";
import { checkTargetForNewValues } from "framer-motion";
import BasicDatePicker from "./Calendar";
import { DatePicker } from "@mui/x-date-pickers";
import DemoShare from "./DemoShare";
import { Calendar } from "./Calendar";
import GoogleMapReact from 'google-map-react';
import report from "../../assets/svg_icons/report.svg";
import error from "../../assets/svg_icons/error.svg";
import { useTranslation } from 'react-i18next';


const AnyReactComponent = ({ text }) => (
  <div style={{ position: 'relative' }}>
    <div
      style={{
        backgroundColor: 'green',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 5px rgba(0, 128, 0, 0.8)', // Optional: Add a shadow for better visibility
      }}
    ></div>
    <div
      style={{
        border: '1px solid green',
        borderRadius: '50%',
        width: '100px',
        height: '100px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 128, 0, 0.3)',
      }}
    ></div>
  </div>
);


function Product() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        } else {
          i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);
    const theme = createTheme();
    const is1200 = useMediaQuery(theme.breakpoints.down("1200"));
    const is480 = useMediaQuery(theme.breakpoints.down("480"));

    const [selectedImage, setSelectedImage] = useState(false);
    const [singleProduct, setProduct] = useState(false);
    const [productDetails, setproductDetails] = useState(false);
    const [storeDetails, setstoreDetails] = useState(false);
    const [productReviews, setProductReviews] = useState(false);
    const [disableBooking, setDisableBooking] = useState(true);
    const [bookingDetails, setBookingDetails] = useState(false);
    const [isVerified, setisVerified] = useState(false);
    const [dateRange, setDateRange] = useState(null);

    const [productLocation, setProductLocation] = useState("");

    const [isShareOpen, setIsShareOpen] = useState({
        state: false,
        url: "",
    });

    const [responseMessage, setResponseMessage] = useState({
        type: "",
        message: "",
    });
    const [openLightBox, setopenLightBox] = useState(false);
    const bookingTag = useRef();
    const navigate = useNavigate();

    const cookies = new Cookies();

    const params = useParams();
    const id = cookies.get("id");

    const { product_id } = params;

    // const selectionRange = {
    //     startDate: new Date(),
    //     endDate: new Date(),
    //     key: "selection",
    // };
    let selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    };

    const handleDateRangeChange = (newDateRange) => {
        const startDate = newDateRange.startDate;
        const endDate = newDateRange.endDate;

        selectionRange.startDate = startDate;

        if (!endDate) {
            selectionRange.endDate = startDate;
        } else {
            selectionRange.endDate = endDate;
        }
    };

    const bookingModalParams = {
        disableBooking: disableBooking,
        bookingMessage: false,
    };

    async function addToWishlist(e) {
        e.persist();
        try {
            e.currentTarget.classList.toggle("active");
            await axios.post(urls.serverUrl + routes.addToWishlist + `/${id}`, {
                product_id: productDetails.product_id,
            });
        } catch (err) {
            // console.log(err)
        }
    }

    async function updateBooking() {
        setDisableBooking(bookingModalParams.disableBooking);
        if (!bookingModalParams.disableBooking) {
            setBookingDetails({
                startDate: selectionRange.startDate,
                endDate: selectionRange.endDate,
            });
        }
    }
    async function checkBooking() {
        const diff = calculateDayCountBetweenDates(
            selectionRange.startDate,
            selectionRange.endDate
        );
        const formattedStartDate = new Date(selectionRange.startDate).toISOString().split('T')[0];
        const formattedEndDate = new Date(selectionRange.endDate).toISOString().split('T')[0];
        console.log("formatted date: ", formattedStartDate, formattedEndDate)

        if (diff >= 3) {
            try {

                const res = await axios.get(
                    urls.serverUrl +
                        routes.checkProductAvailability +
                        `/${product_id}?start_time=${formattedStartDate}&end_time=${formattedEndDate}`
                );
                console.log("check date: ", selectionRange.startDate, selectionRange.endDate, product_id)
                if (res.data.status === 200) {
                    bookingModalParams.disableBooking = false;
                    bookingModalParams.bookingMessage = "Booking Available";
                    console.log("Booking Available")
                    bookingTag.current.innerHTML = bookingModalParams.bookingMessage;
                }
            } catch (err) {
                if (err.response.data.status === 404) {
                    bookingModalParams.disableBooking = true;
                    bookingModalParams.bookingMessage = "Booking Unavailable";
                    console.log("Booking Unavailable")
                    bookingTag.current.innerHTML = bookingModalParams.bookingMessage;
                }
            }
        } else {
            bookingModalParams.bookingMessage = "Minimum 3 days Booking Cycle";
            bookingTag.current.innerHTML = bookingModalParams.bookingMessage;
        }
    }
    async function goToBooking() {
        if (id === undefined) {
            setResponseMessage({
                type: "danger",
                message: "Please login redirecting to login ",
            });
            setTimeout(() => {
                navigate("/login");
            }, 2000);
            return;
        }

        if (!isVerified) {
            setResponseMessage({
                type: "danger",
                message: "Please Verify Kyc redirecting to kyc",
            });
            setTimeout(() => {
                navigate("/complete-kyc");
            }, 2000);
            return;
        }

        if (!disableBooking) {
            navigate("/booking", {
                state: { product: productDetails, bookingDetails, storeDetails },
            });
        }
    }

    useEffect(
        function () {
            async function fetchProduct() {
                try {
                    let product = await axios.get(
                        urls.serverUrl +
                            routes.getProduct +
                            `/${id}?product_id=${params.product_id}`
                    );
                    setProduct(product.data);
                    setproductDetails(product.data.productdetails[0].products[0]);
                    setstoreDetails(product.data.productdetails[0].storedetails[0]);
                } catch (err) {
                    console.log(err);
                }
            }

            async function fetchProductReviews() {
                try {
                    let reviews = await axios.get(
                        urls.serverUrl + routes.getProductReviews + `/${params.product_id}`
                    );
                    setProductReviews(reviews.data.product_review_details);
                } catch (err) {
                    // console.log(err.response);
                    setProductReviews(false);
                }
            }

            async function setUserKyc() {
                try {
                    let details = await axios.get(
                        urls.serverUrl + routes.getUserProfile + `/${id}`
                    );

                    if (details.data.user_details[0].user_kyc_status === 1) {
                        setisVerified(true);
                    } else {
                        setisVerified(false);
                    }
                } catch (err) {
                    console.log(err);
                }
            }

            fetchProduct();
            setUserKyc();
            fetchProductReviews();
        },

        [params.product_id, id]
    );

    const getLocation = async () => {
        try {
            const res = await axios.get(
                `https://api.opencagedata.com/geocode/v1/json?q=${productDetails.latitude}+${productDetails.longitude}&key=2f39b8a76c3d4c9a88c5e86ab720ac87`
            );
            if (res.status == 200) {
                setProductLocation(res.data.results[0].components.city);
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (productDetails) {
            setSelectedImage(productDetails?.product_images[0]?.image_link);
        }
    }, [productDetails]);

    useEffect(() => {
        getLocation();
    }, [productDetails]);

    const handleSelectImage = (newImage) => {
        setSelectedImage(newImage.image_link);
    };
    const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const defaultProps = {
    center: {
      lat: parseFloat(productDetails.latitude) || 0, 
      lng: parseFloat(productDetails.longitude) || 0
      // lat: 23.2599333,
      // lng: 77.412615
    },
    zoom: 14
  };

  console.log(defaultProps.center.lat, defaultProps.center.lng)

  const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const VideoModal = ({ videoUrl, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        height: '400px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <video
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            marginTop: '10px',
            marginBottom: '10px', 
          }}
          controls
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Modal>
  );
};


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    return (
        <>
            <Header />
                

            {singleProduct ? (
                <div
                    className="uk-section-product"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "10rem",
                    }}>

                    <div style={{ width: "80vw" }}>
                        <div style={{ display: "flex", flexDirection: is1200 ? "column" : "" }}>
                            {/* PRODUCT DISPLAY GRID */}
                            {productDetails.product_images && (
                                <Grid
                                    flex={"70%"}
                                    container
                                    spacing={2}
                                    justifyItems={"center"}
                                    alignItems={"baseline"}>
                                    <Grid item xs={3}>
                                        {/* Small product images */}
                                        <Grid container spacing={1} >
                                            {/* todo loop */}
                                            {productDetails.product_videos.length > 0 && (
                                                <Grid item xs={12}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '100px',
                                                        height: '60px'}} >
                                                    <div
                                                      style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '60px',
                                                        height: '60px',
                                                        borderRadius: '50%',
                                                        overflow: 'hidden',
                                                        boxShadow: '0 0 1rem 1px #828282',
                                                        transition: 'transform 0.4s',
                                                        transform: 'scale(1.05)',
                                                        cursor: 'pointer',
                                                        marginBottom: '10px'
                                                      }}
                                                      onClick={handleOpen}
                                                    >
                                                      <OndemandVideoIcon sx={{ color: 'text.primary', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                                    </div>
                                                    </div>
                                                    
                                                    <VideoModal
                                                        videoUrl={productDetails.product_videos[0].video_link}
                                                        open={open}
                                                        handleClose={handleClose}
                                                    />
                                                </Grid>
                                            )}

                                            <Grid item xs={12}>
                                                <img
                                                    style={{
                                                        width: "100px",
                                                        cursor: "pointer",
                                                        transition: ".4s",
                                                        boxShadow:
                                                            selectedImage ===
                                                            productDetails.product_images[1]
                                                                ?.image_link
                                                                ? "0 0 1rem 1px #828282"
                                                                : "",
                                                        scale:
                                                            selectedImage ===
                                                            productDetails.product_images[1]
                                                                ?.image_link
                                                                ? "1.05"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        handleSelectImage(
                                                            productDetails.product_images[1]
                                                        )
                                                    }
                                                    src={
                                                        productDetails.product_images[1]?.image_link
                                                    }
                                                    alt="Product 1"
                                                    width="100%"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <img
                                                    style={{
                                                        transition: ".4s",
                                                        width: "100px",
                                                        cursor: "pointer",
                                                        boxShadow:
                                                            selectedImage ===
                                                            productDetails.product_images[2]
                                                                ?.image_link
                                                                ? "0 0 1rem 1px #828282"
                                                                : "",
                                                        scale:
                                                            selectedImage ===
                                                            productDetails.product_images[2]
                                                                ?.image_link
                                                                ? "1.05"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        handleSelectImage(
                                                            productDetails.product_images[2]
                                                        )
                                                    }
                                                    src={
                                                        productDetails.product_images[2]?.image_link
                                                    }
                                                    alt="Product 2"
                                                    width="100%"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <img
                                                    style={{
                                                        transition: ".4s",
                                                        width: "100px",
                                                        cursor: "pointer",
                                                        boxShadow:
                                                            selectedImage ===
                                                            productDetails.product_images[3]
                                                                ?.image_link
                                                                ? "0 0 1rem 1px #828282"
                                                                : "",
                                                        scale:
                                                            selectedImage ===
                                                            productDetails.product_images[3]
                                                                ?.image_link
                                                                ? "1.05"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        handleSelectImage(
                                                            productDetails.product_images[3]
                                                        )
                                                    }
                                                    src={
                                                        productDetails.product_images[3]?.image_link
                                                    }
                                                    width="100%"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <img
                                                    style={{
                                                        transition: ".4s",
                                                        width: "100px",
                                                        cursor: "pointer",
                                                        boxShadow:
                                                            selectedImage ===
                                                            productDetails.product_images[4]
                                                                ?.image_link
                                                                ? "0 0 1rem 1px #828282"
                                                                : "",
                                                        scale:
                                                            selectedImage ===
                                                            productDetails.product_images[4]
                                                                ?.image_link
                                                                ? "1.05"
                                                                : "",
                                                    }}
                                                    onClick={() =>
                                                        handleSelectImage(
                                                            productDetails.product_images[4]
                                                        )
                                                    }
                                                    src={
                                                        productDetails.product_images[4]?.image_link
                                                    }
                                                    width="100%"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid item xs={9}>
                                        <img
                                            style={{
                                                width: '420px',
                                                height: '720px',
                                                objectFit: 'contain',
                                            }}
                                            src={selectedImage}
                                            alt="product-image"
                                        />
                                    </Grid>

                                    <div style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      height: '60vh',
                                      width: '80%',
                                      alignItems: 'flex-start',
                                      justifyContent: 'center',
                                      margin: '30px auto',
                                    }}>
                                      <div className="uk-margin-medium-top uk-margin-small-bottom uk-text-primary uk-text-bold uk-text-left">
                                        <p>{t('location')}: </p>
                                      </div>

                                      {productDetails.latitude && productDetails.longitude ? (
                                        <GoogleMapReact
                                          bootstrapURLKeys={{ key: "YOUR_GOOGLE_MAPS_API_KEY" }}
                                          defaultCenter={defaultProps.center}
                                          defaultZoom={defaultProps.zoom}
                                        >
                                          <AnyReactComponent
                                            lat={defaultProps.center.lat}
                                            lng={defaultProps.center.lng}
                                            text="My Marker"
                                          />
                                        </GoogleMapReact>
                                      ) : (
                                        <p>Loading map...</p>
                                      )}

                                        <div className="uk-flex uk-margin-small uk-width-1-1" style={{ justifyContent: 'space-around' }}>
                                            <a
                                              href="/rental_policy.pdf"
                                              target="_blank"
                                              rel="noreferrer"
                                              className="uk-button uk-button-success uk-width-1-2 uk-flex-center">
                                              <ReactSVG src={report} />
                                              <span className="uk-margin-small-left">{t('rentalTerms')}</span>
                                            </a>
                                          
                                            <a
                                              href="/rental_policy.pdf"
                                              target="_blank"
                                              rel="noreferrer"
                                              className="uk-button uk-button-success uk-width-1-2 uk-flex-center">
                                              <ReactSVG src={error} />
                                              <span className="uk-margin-small-left uk-text-danger">{t('report')}</span>
                                            </a>
                                        </div>
                                    </div>
                                </Grid>

                            )}

                            {/* DETAILS SECTION */}
                            <Box flex={"50%"} style={{ marginTop: "3rem" }}>
                                {/* product title */}
                                <div className="uk-flex uk-flex-between uk-position-relative">
                                    <h1 className="uk-text-4">{productDetails.product_title} </h1>
                                    <IconButton
                                        onClick={() =>
                                            setIsShareOpen({
                                                state: true,
                                                url: `https://anywherecloths.com/product/${product_id}`,
                                            })
                                        }>
                                        <ShareIcon sx={{ color: "text.secondary" }} />
                                    </IconButton>
                                </div>

                                {/* details */}
                                <p
                                    style={{ fontWeight: 900, fontSize: "1.1rem" }}
                                    className="uk-color-green uk-text-4-big uk-text-fw-500 uk-margin-medium-top">
                                    {t('details')}:
                                </p>
                                <p
                                    className="uk-text-4-small uk-margin-xxsmall-top"
                                    style={{ lineHeight: "1.7rem", letterSpacing: ".4px" }}>
                                    {productDetails.product_desc}
                                </p>

                                <p
                                    style={{ fontWeight: 900, fontSize: "1.1rem" }}
                                    className="uk-color-green uk-text-4-big uk-text-fw-500 uk-margin-top">
                                    {t('location')}:
                                </p>
                                <p className="uk-text-4-small uk-margin-xxsmall-top">
                                    {productLocation === "" ? (
                                        <Skeleton width={"6rem"} />
                                    ) : (
                                        productLocation
                                    )}
                                </p>


                                {/* Brand */}
                                <p className="uk-flex uk-flex-middle uk-text-4-big uk-text-fw-500 uk-margin-top">
                                    <p
                                        style={{ fontWeight: 900, fontSize: "1.1rem" }}
                                        className="uk-color-green">
                                        {t('brand')}:{" "}
                                    </p>
                                    <p className="uk-text-4-small uk-margin-left">
                                        {productDetails.product_brand?.brand_name}
                                    </p>
                                </p>

                                {/* RECOMMENDED FOR */}
                                <p className="uk-flex uk-flex-middle uk-text-4-big uk-text-fw-500 uk-margin-top">
                                    <p
                                        style={{ fontWeight: 900, fontSize: "1.1rem" }}
                                        className="uk-color-green">
                                        {t('recommendedFor')}:{" "}
                                    </p>
                                    <p className="uk-margin-left uk-text-4-small">
                                        {
                                            productDetails?.product_recommendations_filter_option
                                                ?.recomm_name
                                        }
                                    </p>
                                </p>

                                {/* color */}
                                <p className="uk-flex uk-flex-middle uk-text-4-big uk-text-fw-500 uk-margin-top">
                                    <p
                                        style={{ fontWeight: 900, fontSize: "1.1rem" }}
                                        className="uk-color-green">
                                        {t('color')}:{" "}
                                    </p>
                                    <p className="uk-margin-left uk-text-4-small">
                                        {productDetails?.product_color?.color_name}
                                    </p>
                                </p>

                                {/* fabric name */}
                                <p className="uk-flex uk-flex-middle uk-text-4-big uk-text-fw-500 uk-margin-top">
                                    <p
                                        style={{ fontWeight: 900, fontSize: "1.1rem" }}
                                        className="uk-color-green">
                                        {t('fabric')}:{" "}
                                    </p>
                                    <p className="uk-margin-left uk-text-4-small">
                                        {productDetails?.productFabric?.fabric_name}
                                    </p>
                                </p>

                                {/* size */}
                                <p className="uk-flex uk-flex-middle uk-text-4-big uk-text-fw-500 uk-margin-top">
                                    <p
                                        style={{ fontWeight: 900, fontSize: "1.1rem" }}
                                        className="uk-color-green">
                                        {t('size')}:{" "}
                                    </p>

                                    <Stack
                                        flexDirection={"row"}
                                        columnGap={2}
                                        justifyContent={"flex-start"}
                                        className="uk-flex uk-flex-wrap-around uk-flex-middle uk-flex-around uk-margin-left uk-flex-wrap">
                                        <p className="uk-text-4-small uk-flex">
                                            <Stack
                                                flexDirection={"row"}
                                                columnGap={1}
                                                style={{ marginRight: ".5rem" }}>
                                                {t('shoulder')}:
                                                <span className="uk-text-4-small">
                                                    {productDetails.shoulder_size_low}-
                                                    {productDetails.shoulder_size_high}
                                                </span>
                                                <span>inches</span>
                                            </Stack>
                                        </p>
                                        <p className="uk-text-4-small uk-flex">
                                            <Stack
                                                flexDirection={"row"}
                                                columnGap={1}
                                                style={{ marginRight: ".5rem" }}>
                                                {t('thighs')}:
                                                <span className="uk-text-4-small">
                                                    {productDetails.thighs_size_low}-
                                                    {productDetails.thighs_size_high}
                                                </span>
                                                <span>inches</span>
                                            </Stack>
                                        </p>
                                        <p className="uk-flex uk-text-4-small">
                                            <Stack
                                                flexDirection={"row"}
                                                columnGap={1}
                                                style={{ marginRight: ".5rem" }}>
                                                {t('chest')}:
                                                <span className="uk-text-4-small">
                                                    {productDetails.chest_size_low}-
                                                    {productDetails.chest_size_high}
                                                </span>
                                                <span>inches</span>
                                            </Stack>
                                        </p>
                                        <p className="uk-text-4-small uk-flex">
                                            <Stack
                                                columnGap={1}
                                                flexDirection={"row"}
                                                style={{ marginRight: ".5rem" }}>
                                                {t('waist')}:
                                                <span className="uk-text-4-small">
                                                    {productDetails.waist_size_low}-
                                                    {productDetails.waist_size_high}
                                                </span>
                                                <span>inches</span>
                                            </Stack>
                                        </p>
                                    </Stack>
                                </p>
                                <Alert
                                    message={responseMessage}
                                    reset={function () {
                                        setResponseMessage({ type: false, message: false });
                                    }}
                                />

                                {/* buttons booking/share/avaialibity */}
                                <Stack
                                    justifyContent={"flex-start"}
                                    alignItems={"flex-start"}
                                    className="uk-flex uk-flex-middle uk-flex-between uk-price-wrapper  uk-flex-wrap"
                                    style={{ marginTop: "2rem" }}>
                                    {/* booking buton */}
                                    <Box style={{ maxWidth: "250px" }}>
                                        <p className="uk-text-5 uk-text-fw-600">
                                            &#8377; {productDetails.product_price}/ {t('booking')}
                                        </p>
                                        <p className="uk-text-4-small">
                                            {productDetails.product_price_duration}
                                        </p>
                                    </Box>

                                    <Box
                                        mt={2}
                                        alignSelf={"flex-start"}
                                        className="uk-width-expand@m"
                                        data-uk-margin="margin: uk-margin-top">
                                        <button
                                            className="uk-button uk-button-white uk-padding-small uk-text-7"
                                            data-uk-toggle="target: #check-availability">
                                            {t('checkAvailability')}
                                        </button>
                                        <ModalPortal
                                            className="uk-flex-top"
                                            id="check-availability">
                                            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                                                <button
                                                    className="uk-modal-close-default"
                                                    type="button"
                                                    onClick={updateBooking}
                                                    data-uk-close></button>
                                                <div
                                                    style={{
                                                        maxWidth: "min-content",
                                                        margin: "auto",
                                                    }}>
                                                    {/* todo* - add calendar here--> */}
                                                    <Calendar
                                                        handleDateRangeChange={
                                                            handleDateRangeChange
                                                        }
                                                    />

                                                    <button
                                                        className="uk-button uk-button-primary uk-button-small uk-margin-medium-top"
                                                        onClick={checkBooking}>
                                                        {t('checkAvailability')}
                                                    </button>
                                                    <button
                                                        className="uk-button uk-button-white uk-button-small uk-margin-medium-top uk-modal-close uk-margin-left"
                                                        onClick={updateBooking}>
                                                        {t('close')}
                                                    </button>
                                                    <p
                                                        className="uk-color-green uk-margin-top"
                                                        ref={bookingTag}></p>
                                                </div>
                                            </div>
                                        </ModalPortal>
                                        {/* <a href="https://api.anywherecloths.com/api/v1/get-app"> */}
                                        {!disableBooking && (
                                            <button
                                                className="uk-button uk-button-primary uk-margin-left@m uk-text-7"
                                                onClick={goToBooking}>
                                                {t('book')}
                                            </button>
                                        )}
                                        {/* </a> */}
                                        {/* <button
											className="uk-button uk-button-primary uk-margin-left@m uk-text-7"
											
											>
											Share
										</button> */}
                                        {/* <DemoShare/> */}
                                    </Box>
                                </Stack>
                                {storeDetails.store_name ? (
                                    <div className="uk-card uk-card-store-details">
                                        <div className="uk-card-body">
                                            <div data-uk-grid className="uk-grid uk-flex-middle">
                                                <div>
                                                    <img
                                                        src={storeDetails.store_photo}
                                                        alt={storeDetails.store_name}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="uk-text-5-small">
                                                        {storeDetails.store_name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {productReviews ? (
                                    <>
                                        <div
                                            className="uk-flex uk-text-5 uk-flex-between"
                                            style={{ margin: "50px 0" }}>
                                            <p>{t('reviews')}</p>
                                            <Link to={`#`} className="uk-flex">
                                                <span className="uk-margin-small-right">
                                                    {t('all')} {productReviews.length} {t('reviews')}{" "}
                                                </span>
                                                <ReactSVG src={chevronRight} />
                                            </Link>
                                        </div>
                                        <ul className="uk-list uk-list-reviews">
                                            {productReviews &&
                                                productReviews.map((review, index) => (
                                                    <li key={index} className="uk-padding-remove-bottom uk-margin-remove-bottom">
                                                        <p className="uk-text-5-xsmall uk-text-fw-500">
                                                            {review.review_details.review_user_desc}
                                                        </p>
                                                        <p className="uk-text-6-xbig uk-text-fw-500 uk-text-muted">
                                                            <span>
                                                                {new Date(
                                                                    review.review_details.review_creation_dt
                                                                )
                                                                    .toLocaleDateString("en-US", {
                                                                        day: "numeric",
                                                                        month: "long",
                                                                        year: "numeric",
                                                                    })
                                                                    .replace(",", "")}
                                                            </span>
                                                            {review.user_details.username ? (
                                                                <span>
                                                                    {review.user_details.username}
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </p>
                                                    </li>
                                                ))}
                                        </ul>
                                    </>
                                ) : (
                                    <div className="uk-text-7 uk-margin-top">
                                        <p>{t('noReviewsFound')}</p>
                                    </div>
                                )}
                            </Box>
                        </div>
                    </div>
                    <Lightbox
                        open={openLightBox}
                        close={() => setopenLightBox(false)}
                        slides={productDetails.product_images.map((v) => ({
                            src: v.image_link,
                        }))}
                    />
                    <ProductShare
                        url={isShareOpen.url}
                        isOpen={isShareOpen.state}
                        handleClose={() => setIsShareOpen({ state: false, url: "" })}
                    />
                </div>
            ) : (
                ""
            )}
            <Footer />
        </>
    );
}

function calculateDayCountBetweenDates(startDate, endDate) {
    // Calculate the difference in milliseconds between the two dates.
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

    // Convert the difference in milliseconds to days.
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Return the difference in days.
    return differenceInDays + 1;
}

export default Product;
