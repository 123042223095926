import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { routes, urls } from "../../Global";
import { ReactSVG } from "react-svg";
import search from "../../assets/svg_icons/search.svg";
import InputRange from "react-input-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useTranslation } from 'react-i18next';


function ExtraFilters({ onClose, submit, filterData, clearFilters }) {
	const popupRef = useRef(null);

	const [gender, setGender] = useState(filterData.product_gender || 0);
	const [resources, setResources] = useState(undefined);
	const [rating, setRating] = useState(filterData.selectedRating || "");
	const [bodytype, setbodytype] = useState(filterData.bodytype || "");
	const [fabric, setfabric] = useState(filterData.product_fabric || "");
	const [color, setcolor] = useState(filterData.product_colors || "");
	const [brand_name, setbrand_name] = useState(filterData.brandName || "");

	const [priceRange, setpriceRange] = useState({
		min: filterData.low_price || 0,
		max: filterData.high_price || 30000,
	});

	const [distance, setdistance] = useState({
		min: 0,
		max: filterData.distance_high || 500,
	});

	const [shoulderSize, setShoulderSize] = useState({
		min: 20,
		max: filterData.shoulder_size || 30,
	});

	const [chestSize, setChestSize] = useState({
		min: 34,
		max: filterData.chest_size || 56,
	});

	const [waistSize, setWaistSize] = useState({
		min: 32,
		max: filterData.waist_size || 50,
	});

	const [thighSize, setThighSize] = useState({
		min: 20,
		max: filterData.thighs_size || 30,
	});

	function applySearch() {
		const data = {
			selectedRating: formatNumVal(rating),
			bodytype: formatNumVal(bodytype),
			brandName: formatNumVal(brand_name),
			product_colors: formatNumVal(color),
			product_fabric: formatNumVal(fabric),
			low_price: formatNumVal(priceRange.min),
			high_price: formatNumVal(priceRange.max),
			distance_high: formatNumVal(distance.max),
			waist_size: formatNumVal(waistSize.max),
			thighs_size: formatNumVal(thighSize.max),
			shoulder_size: formatNumVal(shoulderSize.max),
			chest_size: formatNumVal(chestSize.max),
		};

		submit({
			...(data.selectedRating ? { selectedRating: data.selectedRating } : {}),
			...(data.bodytype ? { bodytype: data.bodytype } : {}),
			...(data.brandName ? { brandName: data.brandName } : {}),
			...(data.product_colors ? { product_colors: data.product_colors } : {}),
			...(data.product_fabric ? { product_fabric: data.product_fabric } : {}),
			...(data.low_price ? { low_price: data.low_price } : {}),
			...(data.high_price ? { high_price: data.high_price } : {}),
			...(data.distance_high ? { distance_high: data.distance_high } : {}),
			...(data.waist_size !== 50
				? { waist_size: data.waist_size }
				: { waist_size : ""}),
			...(data.thighs_size !==30 ? { thighs_size: data.thighs_size } : {thighs_size: ""}),
			...(data.shoulder_size !== 30 ? { shoulder_size: data.shoulder_size } : {shoulder_size:""}),
			...(data.chest_size !== 56 ? { chest_size: data.chest_size } : {chest_size:""}),
		});
		onClose();
	}

	function handleClear(params) {
		setGender(0);
		clearFilters();
		onClose();
	}

	const centeredStyle = {
		top: '20%',  // Move the top edge to the vertical center
		left: '50%', // Move the left edge to the horizontal center
		transform: 'translate(-50%, -50%)', // Translate it back by half its width and height
	  };
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				onClose();
			}
		};

		async function fetchResources() {
			try {
				const res = await axios.get(urls.serverUrl + routes.resources);
				setResources(res.data.items_details);
			} catch (err) {
				console.log(err);
				setResources(undefined);
			}
		}

		fetchResources();

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);


	const storedLanguage = localStorage.getItem('language');
	// console.log(storedLanguage);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (storedLanguage) {
		    i18n.changeLanguage(storedLanguage);
		} else {
		    i18n.changeLanguage('en');
		}
	}, [storedLanguage, i18n]);

	return (
		<>
			<div
				style={centeredStyle}
				className="uk-card uk-card-search uk-position-absolute uk-background-default uk-width-3-4@m uk-width-2-3@s"
				ref={popupRef}> 
				<div className="uk-card-body">
					<div className="uk-flex uk-flex-middle">
						<p className="uk-text-6-big uk-text-fw-600">{t('filterItemsBy')}</p>
						<ul className="uk-margin-left uk-list uk-list-sort">
							<li>
								<select
									className="uk-select"
									name="gender"
									value={gender}
									onChange={(e) => setGender(e.target.value)}>
									<option value="0">{t('male')}</option>
									<option value="1">{t('female')}</option>
								</select>
							</li>
						</ul>
					</div>
					<div className="uk-margin-top">
						<label className="uk-form-label" htmlFor="shoulder-size">
							{t('priceRange')} (INR)
						</label>
						<div className="uk-form-controls uk-margin-top">
							<InputRange
								minValue={100}
								maxValue={30000}
								step={10}
								value={priceRange}
								onChange={(value) => {
									setpriceRange(value);
								}}
							/>
						</div>
					</div>
					<div className="uk-margin-medium-top">
						<label className="uk-form-label" htmlFor="chest-size">
							{t('distance')} (KM)
						</label>
						<div className="uk-form-controls uk-margin-top">
							<InputRange
								minValue={0}
								maxValue={500}
								step={5}
								value={distance}
								onChange={(value) => {
									setdistance(value);
								}}
							/>
						</div>
					</div>

					<div>
						<div className="uk-grid uk-child-width-1-2@l" data-uk-grid>
							<div className="uk-margin-top">
								<div>
									<div className="uk-grid uk-child-width-1-2" data-uk-grid>
										<div>
											<label className="uk-form-label" htmlFor="brand_name">
												{t('brand')}
											</label>
											<div className="uk-form-controls">
												<ul className="uk-list uk-list-sort">
													<li>
														<select
															name="brand_name"
															id="brand_name"
															className="uk-select"
															value={brand_name}
															onChange={(e) => setbrand_name(e.target.value)}>
															<option value={""}>{t('select')}</option>
															{resources?.brands_details.map((brand, index) => (
																<option key={index} value={brand.brand_id}>
																	{brand.brand_name}
																</option>
															))}
														</select>
													</li>
												</ul>
											</div>
										</div>
										<div>
											<label className="uk-form-label" htmlFor="brand_name">
												{t('rating')}
											</label>
											<div className="uk-form-controls">
												<ul className="uk-list uk-list-sort">
													<li>
														<select
															className="uk-select"
															value={rating}
															onChange={(e) => {
																setRating(e.target.value);
															}}>
															<option value="">{t('select')}</option>
															<option value="1">1 {t('star')}</option>
															<option value="2">2 {t('star')}</option>
															<option value="3">3 {t('star')}</option>
															<option value="4">4 {t('star')}</option>
															<option value="5">5 {t('star')}</option>
														</select>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div>
									<div className="uk-grid uk-child-width-1-2" data-uk-grid>
										<div>
											<label className="uk-form-label" htmlFor="product_colors">
												{t('color')}
											</label>
											<div className="uk-form-controls">
												<ul className="uk-list uk-list-sort">
													<li>
														<select
															name="product_colors"
															id="product_colors"
															className="uk-select"
															value={color}
															onChange={(e) => setcolor(e.target.value)}>
															<option value={""}>{t('select')}</option>
															{resources?.colors_details.map((color, index) => (
																<option key={index} value={color.color_id}>
																	{color.color_name}
																</option>
															))}
														</select>
													</li>
												</ul>
											</div>
										</div>
										<div>
											<label className="uk-form-label" htmlFor="product_fabric">
												{t('fabric')}
											</label>
											<div className="uk-form-controls">
												<ul className="uk-list uk-list-sort">
													<li>
														<select
															name="product_fabric"
															id="product_fabric"
															className="uk-select"
															value={fabric}
															onChange={(e) => setfabric(e.target.value)}>
															<option value={""}>{t('select')}</option>
															{resources?.fabric_details.map(
																(fabric, index) => (
																	<option key={index} value={fabric.fabric_id}>
																		{fabric.fabric_name}
																	</option>
																)
															)}
														</select>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="bodytype">
										{t('bodyType')}
									</label>
									<div
										className="uk-form-controls uk-slider-size-picker"
										data-uk-slider>
										<div className="uk-slider-container">
											<ul className="uk-slider-items uk-child-width-1-4">
												{gender == 0 ? (
													<>
														{resources?.bodytype_for_man.map((size, index) => (
															<li key={index}>
																<input
																	id={size.bodytype_name}
																	type="radio"
																	name="bodytype"
																	value={size.bodytype_id}
																	onChange={(e) => setbodytype(e.target.value)}
																/>
																<label htmlFor={size.bodytype_name}>
																	<img src={`${size.body_type_image}`} />
																</label>
															</li>
														))}
													</>
												) : (
													<>
														{resources?.bodytype_for_woman.map(
															(size, index) => (
																<li key={index}>
																	<input
																		id={size.bodytype_name}
																		type="radio"
																		name="bodytype"
																		value={size.bodytype_id}
																		onChange={(e) =>
																			setbodytype(e.target.value)
																		}
																	/>
																	<label htmlFor={size.bodytype_name}>
																		<img src={`${size.body_type_image}`} />
																	</label>
																</li>
															)
														)}
													</>
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div>
								<p className="uk-text-muted uk-margin-top">{t('size')}</p>
								<div>
									<label className="uk-form-label" htmlFor="shoulder-size">
										{t('shoulderSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={20}
											maxValue={30}
											value={shoulderSize}
											onChange={(value) => {
												setShoulderSize(value);
											}}
										/>
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="chest-size">
										{t('chestSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={34}
											maxValue={56}
											value={chestSize}
											onChange={(value) => {
												setChestSize(value);
											}}
										/>
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="phone">
										{t('waistSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={32}
											maxValue={50}
											value={waistSize}
											onChange={(value) => {
												setWaistSize(value);
											}}
										/>
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="phone">
										{t('thighsSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={20}
											maxValue={30}
											value={thighSize}
											onChange={(value) => {
												setThighSize(value);
											}}
										/>
									</div>
								</div>

								<div
									style={{ width: "99.5%", marginLeft: "5px" }}
									className="uk-grid uk-child-width-1-2@l uk-child-width-1-1@m">
									<button
										style={{ marginTop: "22px", height: "70px" }}
										className="uk-button uk-button-primary uk-flex-center uk-flex"
										onClick={applySearch}>
										<ReactSVG src={search} className="uk-icon-white" />
										<span className="uk-margin-left">{t('applyFilter')}</span>
									</button>
									<button
										style={{ marginTop: "22px", height: "70px" }}
										className="uk-button uk-button-info uk-flex-center uk-flex"
										onClick={handleClear}>
										<span className="uk-margin-left">{t('clear')}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ExtraFilters;

function formatNumVal(v) {
	return isNaN(parseInt(v)) ? undefined : parseInt(v);
}