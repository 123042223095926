import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { routes, urls } from "../Global";
import { ReactSVG } from "react-svg";
import confirmation from "../assets/svg_icons/confirmation.svg";
import search from "../assets/svg_icons/search.svg";
import arrowLeft from "../assets/svg_icons/arrow-left.svg";
import BookingItemSeller from "./BookingItem";
import { useTranslation } from 'react-i18next';


function SellerBookings() {
    const params = useParams();
    const navigate = useNavigate();

    const [status, setStatus] = useState(params.status || "ongoing");
    const [hasMore, setHasMore] = useState(true);
    const [bookings, setBookings] = useState([]);
    const [bookingData, setBookingData] = useState({
        searchTerm: false,
        limit: 100,
        offset: 0,
    });
    const [details, setDetails] = useState(false);
    const [clickedItem, setClickedItem] = useState(null);

    const cookies = new Cookies();
    const reseller_id = cookies.get("reseller_id");

    async function changeSearch(e) {
        if (e.target.value !== "") {
            setBookingData({
                ...bookingData,
                offset: 0,
                search_term: e.target.value,
            });
        } else {
            setBookingData({
                ...bookingData,
                offset: 0,
                search_term: false,
            });
        }
    }

    async function getOngoingBookings() {
        try {
            setStatus("ongoing");
            navigate("/seller/bookings/ongoing");
            const get = await axios.get(
                urls.serverUrl +
                    routes.getBooking +
                    `?reseller_id=${reseller_id}&booking_status=1,2,4`
            );
            // const demo_id = 'ca3ca1f7-c40c-48eb-88c8-69fb0e9ee55e';
            // const get = await axios.get(
            //     urls.serverUrl +
            //         routes.getBooking +
            //         `?reseller_id=${demo_id}&booking_status=1,2,4`
            // );
            setBookingData((prevState) => ({
                ...prevState,
                offset: prevState.limit,
            }));
            setBookings(get.data.booking);
            setHasMore(true);
        } catch (err) {
            console.log(err.response);
            setBookings([]);
            setHasMore(false);
        }
    }

    async function getPaidBookings() {
        try {
            setStatus("paid");
            navigate("/seller/bookings/paid");
            const get = await axios.get(
                urls.serverUrl +
                    routes.getBooking +
                    `?reseller_id=${reseller_id}&booking_status=3,5`
            );
            // const demo_id = 'ca3ca1f7-c40c-48eb-88c8-69fb0e9ee55e';
            // const get = await axios.get(
            //     urls.serverUrl +
            //         routes.getBooking +
            //         `?reseller_id=${demo_id}&booking_status=3,5`
            // );
            setBookingData((prevState) => ({
                ...prevState,
                offset: prevState.limit,
            }));
            setBookings(get.data.booking);
            setHasMore(true);
        } catch (err) {
            console.log(err.response);
            setBookings([]);
            setHasMore(false);
        }
    }

    const viewDetails = async (e) => {
        try {
            setDetails(true);
            console.log("Item seller:", e);
            setClickedItem(e);
        } catch (err) {
            console.log(err.response);
            setBookings([]);
        }
    }

    async function cancelBooking(e, user_id) {
        try {
            if (!window.confirm("Are you sure you want to cancel booking?")) {
                return false;
            }
            const deleted = await axios.patch(
                urls.serverUrl + routes.bookingStatusChange + `/${reseller_id}`,
                {
                    bookingid: e,
                    bookingstatus: 5,
                    user_id: user_id,
                }
            );

            if (deleted.status === 200) {
                console.log(deleted);
                alert("Your booking has been canceled");
                if (status === "ongoing") {
                    getOngoingBookings();
                } else {
                    getPaidBookings();
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function fetchBookings() {
        try {
            var get = null;
            if (status === "ongoing") {
                get = await axios.get(
                    urls.serverUrl +
                        routes.getBooking +
                        `?reseller_id=${reseller_id}&booking_status=1,2,4&limit=${bookingData.limit}&offset=${bookingData.offset}&search_term=${bookingData.searchTerm}`
                );
            } else {
                get = await axios.get(
                    urls.serverUrl +
                        routes.getBooking +
                        `?reseller_id=${reseller_id}&booking_status=3,5&limit=${bookingData.limit}&offset=${bookingData.offset}&search_term=${bookingData.searchTerm}`
                );
            }
            setBookings((prevBooking) => [...prevBooking, ...get.data.booking]);
            setBookingData((prevState) => ({
                ...prevState,
                offset: prevState.offset + prevState.limit,
            }));
            setHasMore(true);
        } catch (err) {
            setHasMore(false);
        }
    }

    useEffect(function () {
        if (status === "ongoing") {
            getOngoingBookings();
        } else if (status === "paid") {
            getPaidBookings();
        }
    }, []);

    function formatDate(inputDate) {
        const dateObject = new Date(inputDate);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear().toString().slice(2);
        return `${day}/${month}/${year}`;
    }

    function calculateDayDifference(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000; 
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffDays = Math.round(Math.abs((start - end) / oneDay)) + 1;
        return diffDays;
    }

    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
    if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
    } else {
        i18n.changeLanguage('en');
    }
    }, [storedLanguage, i18n]);

    return (
        <>
            <div className="uk-section uk-position-relative">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <p className="uk-text-4 uk-text-fw-600">Bookings</p>
                        </div>
                        <div>
                            <div className="uk-input-search">
                                <ReactSVG src={search} />
                                <input
                                    className="uk-input"
                                    placeholder={t('search')}
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="uk-margin-top" data-uk-slider style={{ overflow: "visible" }}>
                        <div className="uk-flex uk-slider-items" data-uk-grid>
                            <div className="uk-width-fit">
                                <button
                                    className={`uk-button uk-button-switcher ${
                                        status === "ongoing"
                                            ? "uk-button-primary"
                                            : "uk-button-secondary"
                                    }`}
                                    onClick={getOngoingBookings}>
                                    {t('ongoingBookings')}
                                </button>
                            </div>
                            <div className="uk-width-fit">
                                <button
                                    className={`uk-button uk-button-switcher ${
                                        status === "paid"
                                            ? "uk-button-primary"
                                            : "uk-button-secondary"
                                    }`}
                                    onClick={getPaidBookings}>
                                    {t('pastBookings')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="uk-child-width-1-3@m uk-grid-products" data-uk-grid>
                        {bookings.map((item, index) => (
                            <div key={index}>
                                <BookingItemSeller
                                    item={item}
                                    viewDetails={viewDetails}
                                    cancelBooking={cancelBooking}
                                    status={status}
                                />
                            </div>
                        ))}
                    </div>
                    {hasMore ? (
                        <div className="uk-text-center uk-margin-large-top">
                            <button
                                className="uk-button uk-button-primary uk-button-rounded"
                                onClick={fetchBookings}>
                                {t('fetch')}
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                {details ? (
                    <>
                        <div className="uk-section-booking-details">
                            <div
                                className="uk-flex uk-flex-between uk-flex-wrap"
                                data-uk-margin="margin: uk-margin-top">
                                <ReactSVG
                                    src={arrowLeft}
                                    className="uk-icon-lightgray uk-cursor-pointer"
                                    onClick={() => setDetails(false)}
                                    style={{ height: "fit-content" }}
                                />
                                <div className="uk-booking-details-content">
                                    <div className="uk-flex" style={{ gap: "16px" }}>
                                        <div>
                                            <img
                                                src={clickedItem.product.product_images[0].image_link}
                                                alt={clickedItem.product.product_title}
                                                className="uk-product-image"
                                            />
                                        </div>
                                        <div>
                                            <p className="uk-text-8-big">
                                                <strong>{clickedItem.product.product_title}</strong>
                                            </p>

                                            {clickedItem.booking_statu ? (
                                              <>
                                                {[1, 3, 4].includes(clickedItem.booking_statu.booking_status_id) ? (
                                                  <div className="uk-flex uk-margin-booking-item uk-color-green">
                                                    <ReactSVG src={confirmation} style={{paddingRight: '5px'}}/>
                                                    <span className="uk-color-green">{clickedItem.booking_statu.booking_status_name}</span>
                                                  </div>
                                                ) : [2, 5].includes(clickedItem.booking_statu.booking_status_id) ? (
                                                  <div className="uk-flex uk-margin-booking-item uk-color-red">
                                                    <ReactSVG src={confirmation} style={{paddingRight: '5px'}}/>
                                                    <span className="uk-color-red">{clickedItem.booking_statu.booking_status_name}</span>
                                                  </div>
                                                ) : ("")}
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            <div
                                                className="uk-text-10 uk-margin-booking-item"
                                                data-uk-margin="margin: uk-margin-booking-item">
                                                <p>
                                                    <strong className="uk-margin-small-right">
                                                        {t('size')}:
                                                    </strong>{" "}
                                                    <span className="uk-button-pill">
                                                        Thighs: {clickedItem.product.thighs_size_low}, 
                                                        Chest: {clickedItem.product.chest_size_low}, 
                                                        Waist: {clickedItem.product.waist_size_low},
                                                        Shoulder: {clickedItem.product.shoulder_size_low}
                                                    </span>
                                                </p>
                                                <p>
                                                    <strong className="uk-margin-small-right">
                                                        {t('price')}:
                                                    </strong>{" "}
                                                    <strong className="uk-margin-small-right">
                                                        ₹{clickedItem.product.product_price}
                                                    </strong>{" "}
                                                    <span className="uk-text-muted">
                                                        (For {calculateDayDifference(clickedItem.booked_current_date, clickedItem.booked_end_date)} {t('days')})
                                                    </span>
                                                </p>
                                                <p>
                                                    <strong className="uk-margin-small-right">
                                                        {t('date')}:
                                                    </strong>{" "}
                                                    <span>{formatDate(clickedItem.booked_current_date)} TO {formatDate(clickedItem.booked_end_date)}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-margin-top">
                                        <p className="uk-text-light">
                                            <strong>{t('location')}: </strong>{clickedItem.booking_location}
                                        </p>
                                        {/*<img
                                            src="images/location.png"
                                            className="uk-width-1-1 uk-margin-small-top"
                                        />*/}
                                        <div className="uk-card uk-card-store-details uk-margin-small-top">
                                          <div className="uk-card-body">
                                            <div data-uk-grid className="uk-grid uk-flex-middle">
                                              <div className="uk-first-column">
                                                <img 
                                                  src={clickedItem.user.user_photo}
                                                  alt="Reseller photo"
                                                  style={{ objectFit: 'contain', width: '100px', height: '100px' }} />
                                              </div>
                                              <div style={{ maxWidth: '160px' }}>
                                                <p className="uk-text-medium" style={{ wordWrap: 'break-word' }}>
                                                  {clickedItem.user.storename ? clickedItem.user.storename : clickedItem.user.user_name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-card uk-card-store-details uk-margin-small-top">
                                            <div className="uk-card-body">
                                                <p className="uk-flex uk-flex-between uk-flex-middle">
                                                    <span className="uk-text-muted uk-text-10">
                                                        <strong className="uk-text-8-big uk-color-primary uk-text-fw-600 uk-margin-small-right">
                                                            {t('price')}
                                                        </strong>
                                                        {t('asPerSelectedDates')}
                                                    </span>
                                                    <span className="uk-color-green uk-text-6">
                                                        <strong className="uk-text-fw-600">
                                                            ₹{clickedItem.product.product_price}
                                                        </strong>
                                                    </span>
                                                </p>
                                                <p className="uk-flex uk-flex-between uk-flex-middle">
                                                    <span className="uk-text-muted uk-text-10">
                                                        <strong className="uk-text-8-big uk-color-primary uk-text-fw-600 uk-margin-small-right">
                                                            {t('securityAmount')}
                                                        </strong>
                                                        {t('standardRates')}
                                                    </span>
                                                    <span className="uk-color-green uk-text-6">
                                                        <strong className="uk-text-fw-600">
                                                            ₹{clickedItem.product.product_price}
                                                        </strong>
                                                    </span>
                                                </p>
                                                <p className="uk-flex uk-flex-between uk-flex-middle">
                                                    <span className="uk-text-muted uk-text-10">
                                                        <strong className="uk-text-8-big uk-color-primary uk-text-fw-600 uk-margin-small-right">
                                                            {t('walletCoinsUsed')}:
                                                        </strong>
                                                    </span>
                                                    <span className="uk-color-green uk-text-6">
                                                        <strong className="uk-text-fw-600">
                                                            ₹0
                                                        </strong>
                                                    </span>
                                                </p>
                                                <hr />
                                                <p className="uk-flex uk-flex-between uk-flex-middle">
                                                    <span className="uk-text-muted uk-text-10">
                                                        <strong className="uk-text-8-big uk-color-primary uk-text-fw-600 uk-margin-small-right">
                                                            {t('totalPaidAmount')}:
                                                        </strong>
                                                    </span>
                                                    <span className="uk-color-green uk-text-6">
                                                        <strong className="uk-text-fw-600">
                                                            ₹{clickedItem.total_price}
                                                        </strong>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
        </>
    );
}

export default SellerBookings;
