import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

function Sort({ onClose, submit, filterData }) {
	const popupRef = useRef(null);

	const [sortHighToLow, setSortHighToLow] = useState(filterData.sortHighToLow);
	const [rating, setRating] = useState(filterData.selectedRating);
	const [popularity, setPopularity] = useState(filterData.selectedPoularity);

	function applySort() {
		submit({
			sortHighToLow,
			rating,
			popularity,
		});
		onClose();
	}
	function clear() {
		submit({
			sortHighToLow: undefined,
			rating: "",
			popularity,
		});
		onClose();
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				onClose();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);

	const storedLanguage = localStorage.getItem('language');
	// console.log(storedLanguage);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (storedLanguage) {
		    i18n.changeLanguage(storedLanguage);
		} else {
		    i18n.changeLanguage('en');
		}
	}, [storedLanguage, i18n]);

	return (
		<>
			<div
				className="uk-card uk-card-sort uk-position-absolute uk-background-default"
				ref={popupRef}>
				<div className="uk-card-header">
					<div className="uk-grid-small uk-flex-middle" data-uk-grid>
						<div className="uk-width-expand">
							<h3 className="uk-card-title uk-text-6 uk-margin-remove-bottom">
								Sorting
							</h3>
						</div>
					</div>
				</div>
				<div className="uk-card-body">
					<ul className="uk-list uk-list-sort">
						<li>
							<input
								type="radio"
								id="high"
								name="price_filter"
								value="true"
								checked={sortHighToLow === true}
								onChange={() => {
									setSortHighToLow(true);
								}}
							/> 
							<label htmlFor="high">{t('highToLow')}</label>
						</li>
						<li>
							<input
								type="radio"
								id="low"
								name="price_filter"
								value="false"
								checked={sortHighToLow === false}
								onChange={() => {
									setSortHighToLow(false);
								}}
							/>
							<label htmlFor="low">{t('lowToHigh')}</label>
						</li>
						<li>
							<select
								className="uk-select"
								value={rating}
								onChange={(e) => {
									setRating(e.target.value);
								}}>
								<option value="">{t('selectRating')}</option>
								<option value="1">1 {t('star')}</option>
								<option value="2">2 {t('star')}</option>
								<option value="3">3 {t('star')}</option>
								<option value="4">4 {t('star')}</option>
								<option value="5">5 {t('star')}</option>
							</select>
						</li>
						{/* <li>
              <select className="uk-select" value={popularity} onChange={(e) => { setPopularity(e.target.value) }}>
                <option value="">Select Popularity</option>
                <option value="normal">Normal</option>
                <option value="good">Good</option>
                <option value="average">Average</option>
                <option value="excellent">Excellent</option>
              </select>
            </li> */}
					</ul>
				</div>
				<div class="uk-card-footer">
					<div>
						<button
							className="uk-button uk-button-primary uk-width-1-1"
							onClick={applySort}>
							{t('apply')}
						</button>
						<button style={{marginTop:"10px"}} className="uk-button uk-width-1-1" onClick={clear}>
							{t('clear')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Sort;
