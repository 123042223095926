import { Box,Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { React, useEffect } from 'react'
import logo512 from '../../assets/logo/logo512.png'
import googlePlay from '../../assets/footer/googlePlay.png'
import appStore from '../../assets/footer/appStore.png'
import combined from '../../assets/footer/combined.png'
import { BasicAccordion } from '../Accordian/BasicAccordian'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';



export const Footer = () => {

    const theme=useTheme()

    const is480=useMediaQuery(theme.breakpoints.down("480"))
    const is900=useMediaQuery(theme.breakpoints.down("900"))

    
    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
      if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
      } else {
          i18n.changeLanguage('en');
      }
    }, [storedLanguage, i18n]);

  return (
    <>
    <Stack width={"100%"} sx={{boxSizing:"border-box"}} component={Paper} elevation={10} justifyContent={"flex-start"} alignItems={'flex-start'} flexDirection={'column'} ml={"auto"} mr={"auto"} p={2} spacing={4}>

        {/* upper */}
        <Stack flexWrap={'wrap'} flexDirection={'row'} width={"100%"} justifyContent={is900?'flex-start':'space-around'} alignItems={'center'}>

            {/* images */}
            <Stack p={2} mt={2} alignItems={'center'}>
                <Box>
                    <img style={{width:"120px",borderRadius:"50%"}} src={logo512} alt="logo" />
                </Box>
                
                    <a target='_blank' href="https://api.anywherecloths.com/api/v1/get-app">
                <Box mt={3} sx={{cursor:"pointer"}}>

                    <img src={combined} style={{width:"200px"}} alt="combined" />
                </Box>
                    </a>
            </Stack>

            {/* quicklinks */}
            <Stack  p={2}  mt={2} alignSelf={"flex-start"} justifySelf={'flex-start'}>
                <Typography variant='h5' fontWeight={'600'}>{t('quickLinks')}</Typography>
                <hr color='text.secondary'/>
                <Stack spacing={1}>
                <Typography component={Link} to={"/privacy-policy"} sx={{cursor:"pointer"}}>{t('privacyPolicy')}</Typography>
                <Typography component={Link} to={"/terms-and-conditions"} sx={{cursor:"pointer"}}>{t('termsAndConditions')}</Typography>
                <Typography component={Link} to={"/rental-terms"} sx={{cursor:"pointer"}}>{t('rentalTerms')}</Typography>
                <Typography component={Link} to={"/feedback"} sx={{cursor:"pointer"}}>{t('feedback')}</Typography>
                </Stack>
            </Stack>

            {/* connect wit uys */}
            <Stack p={2}  mt={2} alignSelf={"flex-start"} justifySelf={'flex-start'}>

                <Stack>
                        
                        <Stack>
                            <Typography variant='h5' fontWeight={'600'}>{t('connectWithUs')}</Typography>
                            <hr color='text.secondary'/>
                        </Stack>

                        <Stack alignItems={'center'} flexDirection={'row'} columnGap={1}>
                            <a href='https://api.whatsapp.com/send?phone=918109062889'>
                            <WhatsAppIcon  sx={{color:"green",mr:".4rem"}} fontSize='large'/>
                            <Typography component='a' href='https://api.whatsapp.com/send?phone=918109062889'>+91 8109062889</Typography>
                            </a>
                        </Stack>

                        <Stack mt={1.5} justifyContent={'center'} alignItems={"center"} flexDirection={'row'} columnGap={1}>
                            <MailIcon sx={{color:"green"}} fontSize='large'/>
                            <Typography component={'a'} sx={{textDecoration:"none",wordBreak:"break-word"}} href={`mailto:help.anywherecloths@gmail.com`}>help.anywherecloths@gmail.com</Typography>
                        </Stack>
                        
                        <Stack mt={1.5}>
                            <Stack flexDirection={'row'} columnGap={1}>
                                <InstagramIcon sx={{color:"green",cursor:"pointer"}} fontSize='large'/>
                                <FacebookIcon sx={{color:"green",cursor:"pointer"}} fontSize='large'/>
                                <a href='https://api.whatsapp.com/send?phone=918109062889'>
                            <WhatsAppIcon  sx={{color:"green"}} fontSize='large'/>
                            </a>
                                <YouTubeIcon sx={{color:"green",cursor:"pointer"}} fontSize='large'/>
                            </Stack>
                        </Stack>
        
                </Stack>

            </Stack>


        </Stack>
        
        {/* Lower -> copyright section */}
        <Stack alignSelf={"center"} width={'100%'} justifyContent={'center'} alignItems={'center'} justifySelf={"center"}>
            <Typography>2023 © All right reserved by Bhelsa Rental Pvt. Ltd.</Typography>
        </Stack>
    </Stack>
    </>
  )
}
