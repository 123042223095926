import React, { useEffect } from 'react';

const AlertComponent = ({ type, title, message, duration = 4000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const closeAlert = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={`uk-alert-${type} uk-margin-small uk-padding-small`} uk-alert>
      <a href="#" className="uk-alert-close" onClick={closeAlert} uk-close></a>
      <h3 className="uk-text-default">{title}</h3>
      <p className="uk-text-small">{message}</p>
    </div>
  );
};

export default AlertComponent;
