import React, { createContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { defaultId, defaultToken } from "../Global";

const AuthContext = createContext();

function AuthContextProvider(props) {
	const [loggedIn, setLoggedIn] = useState(undefined);
	const [userStatus, setUserStatus] = useState(undefined);
	const [openRoutes, setOpenRoutes] = useState(undefined);
	const cookies = new Cookies();

	const myCookies = {
		token: {
			name: "token",
			value: cookies.get("token") || defaultToken,
			required: false,
		},
		id: {
			name: "id",
			value: cookies.get("id") || defaultId,
			required: false,
		},
		number: {
			name: "number",
			value: cookies.get("number"),
			required: true,
		},
		reseller_id: {
			name: "reseller_id",
			value: cookies.get("reseller_id"),
			required: false,
		},
		otp_verified: {
			name: "otp_verified",
			value: cookies.get("otp_verified"),
			required: false,
		},
		verification_code: {
			name: "verification_code",
			value: cookies.get("verification_code"),
			required: true,
		},
	};

	async function getLoggedIn() {
		// const token = await cookies.get("token");
		// const id = await cookies.get("id");
		// if (id && token) {
		//   // try {
		//   //   const loggedIn = await axios.get(
		//   //     urls.serverUrl + routes.userVerified + `/${id}`
		//   //   );
		//   //   if (loggedIn.data.user_details[0].user_active_status) {
		//   //     setLoggedIn(true);
		//   //   } else {
		//   //     setLoggedIn(false);
		//   //   }
		//   // } catch (err) {
		//   //   console.log(err);
		//   // }
		//   setLoggedIn(true);
		// } else {
		//   setLoggedIn(false);
		// }
		await refreshCookies();
		await verifyCookies();
	}

	async function getUserStatus() {
		const token = await cookies.get("token");
		const id = await cookies.get("id");
		if (id && token) {
			try {
				// const loggedIn = await axios.get(
				//   urls.serverUrl + routes.userVerified + `/${id}`
				// );
				// if (loggedIn.data.user_details[0].user_details_status) {
				//   setUserStatus(true);
				// } else {
				//   setUserStatus(false);
				// }
				setUserStatus(true);
			} catch (err) {
				console.log(err);
			}
		} else {
			setUserStatus(false);
		}
	}

	const verifyCookies = async () => {
		return new Promise((resolve, reject) => {
			const missingCookies = Object.values(myCookies).filter(
				(cookie) => cookie.value === undefined && cookie.required === true
			);
			if (missingCookies.length > 0) {
				setLoggedIn(false);
			} else {
				setLoggedIn(true);
			}

			resolve();
		});
	};

	const refreshCookies = async () => {
		return new Promise((resolve, reject) => {
			const cookieNames = Object.keys(myCookies);
			cookieNames.forEach((name) => {
				myCookies[name].value = cookies.get(name);
			});

			resolve();
		});
	};

	const removeCookies = async () => {
		try {
			const cookieNames = Object.keys(myCookies);
			return new Promise((resolve, reject) => {
				cookieNames.forEach((name) => {
					cookies.remove(name);
				});

				refreshCookies();
				setLoggedIn(false);
				resolve();
			});
		} catch (error) {
			console.error("Error removing cookies:", error);
		}
	};

	const verifyOpenRoutes = () => {
		setOpenRoutes(loggedIn && myCookies.otp_verified.value === "true");
	};

	useEffect(() => {
		getLoggedIn();
		getUserStatus();
		verifyCookies();
		verifyOpenRoutes();
	});

	return (
		<AuthContext.Provider
			value={{
				loggedIn,
				getLoggedIn,
				userStatus,
				setUserStatus,
				myCookies,
				removeCookies,
				openRoutes,
				verifyOpenRoutes,
			}}>
			{props.children}
		</AuthContext.Provider>
	);
}

export default AuthContext;
export { AuthContextProvider };
