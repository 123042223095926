import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import confirmation from "../../assets/svg_icons/confirmation.svg";
// import infoLight from "../../assets/svg_icons/info-light.svg";
// import star from "../../assets/svg_icons/star.svg";
import FileUpload from "../Helpers/FileUpload";
import Cookies from "universal-cookie";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { routes, urls } from "../../Global";
import { NavLink } from "react-router-dom";
import { useRef } from "react";
import Alert from "../Helpers/Alert";
import { useTranslation } from 'react-i18next';


function RaiseComplaint(props) {
  const storedLanguage = localStorage.getItem('language');
  console.log(storedLanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      } else {
        i18n.changeLanguage('en');
      }
  }, [storedLanguage, i18n]);

  const cookies = new Cookies();
  const params = useParams();
  const id = cookies.get("id");
  const reseller_id = cookies.get("reseller_id");
  const complainDesc = useRef();

  const [productDetails, setproductDetails] = useState(false);

  const [complainType, setComplainType] = useState(false);

  const [complainData, setComplainData] = useState(false);
  const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [responseMessage, setResponseMessage] = useState({
    type: false,
    message: false,
  });

  useEffect(function () {
    if (location.state == null) {
      console.log("yes")
      navigate('/')
    }
  }, [location.state])

  console.log(location)
  const booking_id = location.state ? location.state.booking_id : '';

  function goBack() {
    navigate(-1);
  }

  // const handleItemClick = (rating) => {
  //   setSelectedRating(rating);
  // };

  useEffect(
    function () {
      async function fetchProduct() {
        try {
          let product = await axios.get(
            urls.serverUrl + routes.getProduct + `/${id}?product_id=${params.product_id}`
          );
          setproductDetails(product.data.productdetails[0].products[0]);
        } catch (err) {
          // console.log(err);
        }
      }

      fetchProduct();
    },

    [params.product_id, id]
  );

  const handleOptionChange = (event) => {
    setComplainType(event.target.value);
  };

  function raiseComplain(e) {
    e.preventDefault();
    setComplainData({
      reseller_id: reseller_id,
      booking_id: booking_id,
      product_id: params.product_id,
      product_complain_type: complainType,
      complain_decs: complainDesc.current.value,
      complain_product_photo: files.length > 0 ? files[0].base64 : "",
      product_image1:
        files.length > 1
          ? files[1].base64
          : files.length > 0
            ? files[0].base64
            : "",
    });
  }

  useEffect(
    function () {
      async function raiseComplain() {
        if (complainData) {
          try {
            const complain = await axios.post(
              urls.serverUrl + routes.userBookingComplain + `/${id}`,
              complainData
            );
            setResponseMessage({
              type: "success",
              message: "Complain Raised Successfully"
            })
          } catch (err) {
            // console.log(err);
            setResponseMessage({
              type: "danger",
              message: "Some error occured. Please try again"
            })
          }
        }
      }

      complainData && raiseComplain();
    },
    [complainData]
  );

  if (!productDetails) return

  return (
    <>
      <div
        className="uk-section-complaint uk-grid uk-grid-complaint uk-child-width-1-2@m uk-height-1-1"
        data-uk-grid>
        <div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
          <img
            src="/images/side_banner.png"
            alt="Side Banner"
            className="uk-width-side-banner"
          />
        </div>
        <div className="uk-flex uk-flex-middle uk-section">
          <div className="uk-container uk-container-complaint">
            <div className="uk-flex uk-flex-middle uk-flex-between">
              <div className="uk-flex uk-flex-middle">
                <button onClick={goBack} className="uk-button-noeffect">
                  <ReactSVG
                    src={arrowLeft}
                    className="uk-icon-left uk-icon-black"
                  />
                </button>
                <p className="uk-text-6-xbig uk-text-fw-600 uk-margin-xsmall-left">
                  {t('raiseComplain')}
                </p>
              </div>
              {/* <div>
                <ReactSVG
                  src={infoLight}
                  className="uk-icon-info-light uk-icon-black"
                />
              </div> */}
            </div>
            <div className="uk-card uk-card-complaint uk-width-expand">
              <div className="uk-card-body uk-flex">
                <div className="uk-width-auto">
                  <img
                    src={productDetails.product_images.length > 0 ? productDetails.product_images[0].image_link : ''}
                    alt={productDetails.product_title}
                  />
                </div>
                <div className="uk-flex uk-flex-column uk-flex-between">
                  <p className="uk-text-8-big uk-text-fw-600">
                    {productDetails.product_title}
                  </p>
                  <div className="uk-flex uk-flex-middle">
                    <ReactSVG src={confirmation} />
                    <p className="uk-color-red uk-text-9 uk-text-fw-500 uk-margin-small-left">
                      {t('raisingComplain')}
                    </p>
                  </div>
                  <NavLink
                    to={`/product/${productDetails.product_id}`}
                    className="uk-button uk-button-primary uk-margin-remove uk-padding-remove">
                    {t('viewDetails')}
                  </NavLink>
                </div>
              </div>
            </div>
            <p
              className="uk-text-6 uk-text-fw-600"
              style={{ marginTop: "25px" }}>
              {t('tellUsYourProblem')}:{" "}
            </p>
            <form onSubmit={raiseComplain}>
              <ul className="uk-list">
                <li>
                  <input
                    type="radio"
                    name="problem"
                    id="got-damaged-item"
                    checked={complainType === "Got the damaged item"}
                    onChange={handleOptionChange}
                    value="Got the damaged item"
                  />
                  <label htmlFor="got-damaged-item">{t('gotDamagedItem')}</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="problem"
                    id="incorrect-product"
                    checked={complainType === "Product was incorrect"}
                    onChange={handleOptionChange}
                    value="Product was incorrect"
                  />
                  <label htmlFor="incorrect-product">
                    {t('productWasIncorrect')}
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="problem"
                    id="delay"
                    checked={complainType === "Delay in delivery"}
                    onChange={handleOptionChange}
                    value="Delay in delivery"
                  />
                  <label htmlFor="delay">{t('delayInDelivery')}</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="problem"
                    id="stain"
                    checked={complainType === "Stain"}
                    onChange={handleOptionChange}
                    value="Stain"
                  />
                  <label htmlFor="stain">{t('stain')}</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="problem"
                    id="Other"
                    checked={complainType === "Other"}
                    onChange={handleOptionChange}
                    value="Other"
                  />
                  <label htmlFor="Other">{t('other')}</label>
                </li>
              </ul>
              {/* <div
                className="uk-flex uk-flex-middle"
                style={{ marginTop: "25px" }}>
                <div>
                  <p className="uk-text-6 uk-text-fw-600">Rate: </p>
                </div>
                <div className="uk-flex uk-list-stars">
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <ReactSVG src={star} key={rating} onClick={() => handleItemClick(rating)} className={`${selectedRating && rating <= selectedRating ? "selected" : ""} uk-icon-star uk-icon`} />
                  ))}
                </div>
              </div> */}
              <textarea
                ref={complainDesc}
                className="uk-textarea"
                rows="3"
                placeholder={t('writeYourMessageHere')}></textarea>
              <p className="uk-text-muted uk-text-8-big">
                {t('uploadItemImages')}
              </p>
              <div style={{ marginTop: "6px" }}>
                <FileUpload setParentFiles={(e) => setFiles(e)} />
              </div>

              <button
                type="submit"
                className="uk-button uk-button-primary uk-width-1-1 uk-margin-top">
                {t('raiseComplain')}
              </button>

              <Alert
                className="uk-margin-top"
                message={responseMessage}
                reset={function () {
                  setResponseMessage({
                    type: false,
                    message: false,
                  });
                }}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default RaiseComplaint;
