import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import { routes, urls } from "../Global";
import { ReactSVG } from "react-svg";
import tickSingle from "../assets/svg_icons/tick-single.svg";
import chevronRight from "../assets/svg_icons/chevron-right-dark.svg";
import checkout from "../assets/svg_icons/checkout.svg";
import payment from "../assets/svg_icons/payment.svg";
import shield from "../assets/svg_icons/shield.svg";
import itemListing from "../assets/svg_icons/item-listing.svg";
import arrowRight from "../assets/svg_icons/arrow-right.svg";
import personalInfo from "../assets/svg_icons/personal-info.svg";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function SellerProfilePopup({ onClose }) {
    const popupRef = useRef(null);

    const [userDetails, setUserDetails] = useState({
        name: "",
        storename: "",
        photo: "",
        adds: "",
        gov_id: "",
        email: "",
        number: "",
        password: "",
        city: "",
        state: "",
    });
    const [verifiedString, setVerifiedString] = useState("");
    const [isDetailsVerified, setisDetailsVerified] = useState(false);
	const [isKycVerified, setisKycVerified] = useState(false);
	const [whatsAppNumber, setwhatsAppNumber] = useState("");

    const cookies = new Cookies();

    const id = cookies.get("id");

    useEffect(
        function () {
            async function fetchUser() {
                try {
                    let details = await axios.get(
                        urls.serverUrl + routes.getUserProfile + `/${id}`
                    );
					let appconfigres = await axios.get(urls.serverUrl + routes.resourcesConfig);

                    if (details.data.user_details[0].user_details_status === 1) {
                        setVerifiedString("Phone Number Verified")
                        setisDetailsVerified(true);
                    }
                    if (details.data.user_details[0].user_kyc_status === 1) {
                        setVerifiedString("KYC Verified")
                        setisKycVerified(true);
                    }
                    setUserDetails(details.data.user_details[0]);

					const wahtsAppConfigs = appconfigres.data.app_config_details.find(
						(v) => v.config_name === "WHATSAPP_CONTACT"
					);

					if (wahtsAppConfigs) {
						setwhatsAppNumber(wahtsAppConfigs.config_value);
					}
                } catch (err) {
                    console.log(err);
                }
            }

            fetchUser();
        },

        [id]
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

	const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
	const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return ( 
		<>
			<div
				className="uk-card uk-card-profile uk-position-absolute uk-background-default"
				ref={popupRef}>
				<div className="uk-card-body">
					<div className="uk-profile-card uk-flex">
						<div>
							<img src={userDetails.photo} alt="" />
						</div>
						<div
							style={{ maxWidth: "204px", width: "100%", marginLeft: "12px" }}
							className="uk-text-8-big uk-flex uk-flex-column uk-flex-center">
							<p className="uk-text-bold">{userDetails.name}</p>
							<div className="uk-color-green uk-flex uk-flex-middle uk-margin-xxsmall-top">
								{verifiedString ? (
									<>
										<ReactSVG
											src={tickSingle}
											className="uk-margin-xxsmall-right"
										/>
										<span>{verifiedString}</span>
									</>
								) : null}
							</div>
							<div>
								{isDetailsVerified ? (
									isKycVerified ? null : (
										<Link
											to="/complete-kyc"
											className="uk-flex uk-link-text uk-text-meta uk-color-green">
											<span>Complete KYC</span>
											<ReactSVG
												src={chevronRight}
												className="uk-margin-left"
											/>
										</Link>
									)
								) : (
									<Link
										to="/profile"
										className="uk-flex uk-link-text uk-text-meta uk-color-green">
										<span>{t('completeProfile')}</span>
										<ReactSVG src={chevronRight} className="uk-margin-left" />
									</Link>
								)}
							</div>
						</div>
						<ReactSVG
							src={chevronRight}
							className="uk-width-expand uk-flex uk-flex-middle uk-flex-right"
						/>
					</div>

					<NavLink
						to={`/`}
						className="uk-button uk-button-black-bg uk-margin-top uk-width-1-1 uk-flex uk-flex-center uk-flex-middle">
						<ReactSVG src={checkout} className="uk-margin-small-right" />
						{t("rentOutYourOutfitsNow")}
					</NavLink>

					<p className="uk-text-bold uk-text-primary uk-margin-large-top">{t('accountSettings')}</p>
					<ul className="uk-list uk-list-border">
						<li>
							<NavLink
								to="/seller"
								className="uk-width-1-1 uk-flex uk-flex-middle">
								<ReactSVG
									src={personalInfo}
									className="uk-margin-xsmall-right"
								/>
								<span className="uk-text-8 uk-color-primary">
									{t('personalInformation')}
								</span>
								<ReactSVG
									src={chevronRight}
									className="uk-width-expand uk-text-right"
								/>
							</NavLink>
						</li>
						<li className="uk-flex uk-flex-middle uk-margin-remove-top">
							<NavLink
								to="/profile/security"
								className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
								<ReactSVG src={shield} className="uk-margin-xsmall-right" />
								<span className="uk-text-8">{t('loginAndSecurity')}</span>
								<ReactSVG
									src={chevronRight}
									className="uk-width-expand uk-text-right"
								/>
							</NavLink>
						</li>
						<li className="uk-flex uk-flex-middle uk-margin-remove-top">
							<NavLink
								to="/profile/payouts"
								className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
								<ReactSVG src={payment} className="uk-margin-xsmall-right" />
								<span className="uk-text-8">{t('payouts')}</span>
								<ReactSVG
									src={chevronRight}
									className="uk-width-expand uk-text-right"
								/>
							</NavLink>
						</li>
						{/* <li className="uk-flex uk-flex-middle uk-margin-remove-top">
	                        <NavLink to="/seller" className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
	                            <ReactSVG src={itemListing} className="uk-margin-xsmall-right" />
	                            <span className="uk-text-8">Item Listing</span>
	                            <ReactSVG
	                                src={chevronRight}
	                                className="uk-width-expand uk-text-right"
	                            />
	                        </NavLink>
	                    </li> */}
					</ul>

					<NavLink
						to="/"
						className="uk-button uk-button-lightgray uk-flex uk-flex-center uk-width-1-1 uk-text-8-big uk-margin-medium-top">
						{t('switchToUserAccount')}
						<ReactSVG src={arrowRight} className="uk-margin-left" />
					</NavLink>

					<p className="uk-text-bold uk-text-primary uk-margin-large-top">{t('support')}</p>

					<ul className="uk-list uk-list-border uk-margin-remove-top">
						{/* <li className="uk-flex uk-flex-middle">
	                        <span className="uk-text-8">How rented wardrobe works</span>
	                        <ReactSVG
	                            src={chevronRight}
	                            className="uk-width-expand uk-text-right"
	                        />
	                    </li> */}
						<li className="uk-margin-remove-top">
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://wa.me/${whatsAppNumber}`}
								className="uk-text-primary uk-flex uk-flex-middle">
								<span className="uk-text-8">{t('customerSupport')}</span>
								<ReactSVG
									src={chevronRight}
									className="uk-width-expand uk-text-right"
								/>
							</a>
						</li>
						<li className="uk-flex uk-flex-middle uk-margin-remove-top">
							<NavLink
								to="/feedback"
								className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
								<span className="uk-text-8">{t('giveUsFeedback')}</span>
								<ReactSVG
									src={chevronRight}
									className="uk-width-expand uk-text-right"
								/>
							</NavLink>
						</li>
					</ul>

					<p className="uk-text-bold uk-margin-top uk-text-primary">{t('legal')}</p>
					<ul className="uk-list uk-list-border uk-margin-remove-top">
						<li>
							<NavLink
								to="/terms-and-conditions"
								className="uk-color-primary uk-width-1-1 uk-flex uk-flex-middle">
								<span className="uk-text-8">{t('termsOfServices')}</span>
								<ReactSVG
									src={chevronRight}
									className="uk-width-expand uk-text-right"
								/>
							</NavLink>
						</li>
						<li className="uk-color-primary uk-flex uk-flex-middle uk-margin-remove-top">
							<span className="uk-text-8">{t("privacyPolicies")}</span>
							<ReactSVG
								src={chevronRight}
								className="uk-width-expand uk-text-right"
							/>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}

export default SellerProfilePopup;
