import React, { useEffect } from "react";
import { ReactSVG } from "react-svg";
import arrowRight from "../../assets/svg_icons/arrow-right.svg";
import { useTranslation } from 'react-i18next';

function Featured() {
	const storedLanguage = localStorage.getItem('language');
	// console.log(storedLanguage);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (storedLanguage) {
			i18n.changeLanguage(storedLanguage);
		} else {
			i18n.changeLanguage('en');
		}
	}, [storedLanguage, i18n]);

	return (
		<>
			<div
				style={{ padding: "1.5em 0" }}
				className="uk-section uk-section-featured uk-background-lightblue">
				<div className="uk-container">
					<div className="uk-grid" data-uk-grid>
						<div className="uk-width-2-3@m">
							<h4 className="uk-text-uppercase">
								{t('notEverythingNeedsToBeOwned')}
							</h4>
							<div className="uk-flex uk-margin-medium-top">
								{t('exploreNow')}
								<ReactSVG className="uk-margin-left" src={arrowRight} />
							</div>
						</div>
						<div className="uk-width-1-3@m"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Featured;
