import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Cookies from "universal-cookie";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import shield from "../../assets/svg_icons/shield.svg";
import chevronRight from "../../assets/svg_icons/chevron-right.svg";
import upi from "../../assets/svg_icons/upi.svg";
import instagram from "../../assets/svg_icons/instagram.svg";
import facebook from "../../assets/svg_icons/facebook.svg";
import checkboxActive from "../../assets/svg_icons/checkbox-active.svg";
import checkboxInactive from "../../assets/svg_icons/checkbox-inactive.svg";
import closeAccount from "../../assets/svg_icons/close-account.svg";
import pen from "../../assets/svg_icons/pen.svg";
import trash from "../../assets/svg_icons/trash.svg";
import tick from "../../assets/svg_icons/tick.svg";
import netbanking from "../../assets/svg_icons/net-banking.svg";
import axios from "axios";
import { routes, urls } from "../../Global";
import AlertComponent from './AlertComponent';
import { useTranslation } from 'react-i18next';


function Payouts() {
	const storedLanguage = localStorage.getItem('language');
	// console.log(storedLanguage);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (storedLanguage) {
			i18n.changeLanguage(storedLanguage);
		} else {
			i18n.changeLanguage('en');
		}
	}, [storedLanguage, i18n]);

  const navigate = useNavigate();
  const [deactivate, setDeactivate] = useState(false);

  const cookies = new Cookies();
  const id = cookies.get("id")
  const reseller_id = cookies.get("reseller_id")  

  function goBack() {
    navigate(-1);
  }

  	const [alert, setAlert] = useState(null);

	const [upiValues, setUpiValues] = useState([]);
	// const [bankValues, setBankValues] = useState([]);

  	useEffect(() => {
		const sellerBankDetails = async () => {
	      try {
	        let response = await axios.get(urls.serverUrl + routes.getSellerBankDetails + `/${reseller_id}`);
	        console.log('Seller bank details fetched successfully');
	        const upiDetails = response.data.message.upi_details;
	        const resellerUpiNames = upiDetails;
        	setUpiValues(resellerUpiNames);
	        console.log("upiDetails", upiDetails);

	        const bankDetails = response.data.message.bank_details;
	        setBankValues(bankDetails);
	        console.log("bankDetails", bankDetails);

	        // .filter(name => name.trim() !== '');
	      } catch (error) {
	        console.error('Error fetching seller bank details:', error);
	      }
	    };
	    sellerBankDetails();
  	}, []);

	const handleUpiDeleteClick = async (upi_id) => {
	  try {
	    await deleteUpiDetails(upi_id);
	    const newUpiValues = upiValues.filter(value => value.upi_id !== upi_id);
	    setUpiValues(newUpiValues);
	    setAlert({
	      type: 'success',
	      message: 'Successfully deleted UPI details.',
	    });
	  } catch (err) {
	    console.error('Error during UPI details deletion:', err);
	    setAlert({
	      type: 'danger',
	      message: 'Error during UPI details deletion.',
	    });
	  }
	};

	const deleteUpiDetails = async (upi_id) => {
	  try {
	    await axios.delete(urls.serverUrl + routes.upiDelete + `/${reseller_id}`, { data: { upi_id: upi_id } });
	    console.log(`UPI Details with upi_id ${upi_id} deleted successfully.`);
	  } catch (err) {
	    console.error(`Error during UPI details with upi_id ${upi_id} deletion:`, err);
	  }
	};

	const [isTyping, setIsTyping] = useState(false);
	const [editingIndex, setEditingIndex] = useState(null);
	const [newUpi, setNewUpi] = useState(false);


	const handleUpiInputChange = (index, value) => {
	    const newUpiValues = [...upiValues];
	    newUpiValues[index] = value;
	    setUpiValues(newUpiValues);
	    setIsTyping(true);
	    setEditingIndex(index);
	};

	useEffect(() => {
	    console.log("this", editingIndex);
	  }, [editingIndex]);

	const handleUpiAddClick = async () => {
	  try {
	  	setNewUpi(true);
	    const newUpiValues = [...upiValues, ''];
	    setUpiValues(newUpiValues);
	  } catch (err) {
	    console.error('Error during UPI details addition:', err);
	  }
	};

	const handleUpiSaveClick = async (value) => {
	  try {
	    const response = await axios.post(
	      urls.serverUrl + routes.upiAdd + `/${reseller_id}`,
	      { upidetails: [value] }
	    );

	    if (response.status === 200) {
	      console.log('Successfully saved UPI details:', response.data);
	      setIsTyping(false); 
	      setEditingIndex(null);
	      setNewUpi(false);
	      setAlert({
	        type: 'success',
	        message: 'Successfully saved UPI details.',
	      });
	    } else {
	      console.error('Failed to save UPI details. Server response:', response);
	      setAlert({
	        type: 'danger',
	        message: 'Failed to save UPI details. Please try again.',
	      });
	    }
	  } catch (err) {
	    console.error('Error while saving UPI details:', err);
	    setAlert({
	      type: 'danger',
	      message: 'Error while saving UPI details. Please try again.',
	    });
	  }
	};

	const [isEditing, setIsEditing] = useState(false);
	const [editingValue, setEditingValue] = useState('');

	const handleUpiEditClick = (targetValue, index) => {
	  setIsEditing(true);
	  setEditingIndex(index);
	  setEditingValue(targetValue)
	  console.log("editingValue", editingValue)
	  console.log("editingIndex", editingIndex)
	};

	const handleUpiSaveEditClick = async (upiId) => {
	  try {
	    const response = await axios.patch(
	      urls.serverUrl + routes.upiEdit + `/${reseller_id}`,
	      {
		    "upi_id": upiId,
		    "reseller_upi_name": editingValue
		  }
	    );

	    if (response.status === 200) {
	      setAlert({
	          type: 'success',
	          message: 'Successfully saved UPI details.',
	      });
	      console.log('Successfully edited UPI details:', response.data);
	      const updatedUpiValues = upiValues.map((value, index) =>
	        index === editingIndex ? { ...value, reseller_upi_name: editingValue } : value
	      );
	      setUpiValues(updatedUpiValues);
	      setIsTyping(false); 
	      setEditingIndex(null);
	      setIsEditing(false);
	    } else {
	      console.error('Failed to edit UPI details. Server response:', response);
	      setAlert({
	        type: 'danger',
	        message: 'Failed to edit UPI details. Please try again.',
	      });
	    }
	  } catch (err) {
	    console.error('Error while editing UPI details:', err);
	    setAlert({
	      type: 'danger',
	      message: 'Error while editing UPI details. Please try again.',
	    });
	  }
	};

	const handleUpiEdit = (index, value) => {
		console.log("handleUpiEdit");
	    const newUpiValues = [...upiValues];
	    newUpiValues[index] = value;
	    setUpiValues(newUpiValues);
	    
	    setEditingIndex(index);
	};


const [editBankValues, setEditBankValues] = useState(false);
const [bankValues, setBankValues] = useState([]);
const [isInputModified, setIsInputModified] = useState(false);
const [isAccNoModified, setIsAccNoModified] = useState(false);
const [isIfscModified, setIsIfscModified] = useState(false);
const [isHolderModified, setIsHolderModified] = useState(false);



const [addingNew, setAddingNew] = useState(false);


const handleAccNoInputChange = (index, value) => {
  const newBankValues = [...bankValues];
  newBankValues[index] = { ...newBankValues[index], reseller_acc_no: value };
  setBankValues(newBankValues);
  setIsAccNoModified(true);
  setIsInputModified(true);
  console.log("yes")
  // console.log(bankValues)
};

const handleIFSCInputChange = (index, value) => {
  const newBankValues = [...bankValues];
  newBankValues[index] = { ...newBankValues[index], reseller_ifsc_code: value };
  setBankValues(newBankValues);
  setIsIfscModified(true);
  setIsInputModified(true);
};

const handleHolderNameInputChange = (index, value) => {
  const newBankValues = [...bankValues];
  newBankValues[index] = { ...newBankValues[index], reseller_account_name: value };
  setBankValues(newBankValues);
  setIsHolderModified(true);
  setIsInputModified(true);
};

	// const handleBankAddClick = () => {
	//   setEditBankDetails(true);
	// };
const handleBankSaveClick = async (index) => {
  setIsInputModified(false);
  setIsAccNoModified(false);
  setIsIfscModified(false);
  setIsHolderModified(false);

  try {
    const editedDetails = bankValues[index];

    // Check if reseller_bank_id is present to determine editing or adding new
    if (editedDetails.reseller_bank_id) {
      const response = await axios.patch(`${urls.serverUrl}${routes.bankDetailsEdit}/${reseller_id}`, {
        "reseller_bank_id": parseInt(editedDetails.reseller_bank_id, 10),
        "reseller_acc_no": parseInt(editedDetails.reseller_acc_no, 10),
        "reseller_ifsc_code": parseInt(editedDetails.reseller_ifsc_code, 10),
        "reseller_account_name": `${editedDetails.reseller_account_name}`,
      });

      if (response.status === 200) {
        console.log('Bank details edited successfully:', response.data);
        setAlert({
          type: 'success',
          message: 'Successfully edited Bank details.',
        });
      } else {
        console.error('Failed to edit bank details. Server response:', response);
        setAlert({
          type: 'danger',
          message: 'Failed to edit bank details.',
        });
      }
    } else {
      // If reseller_bank_id is not present, it means adding new
      const response = await axios.post(`${urls.serverUrl}${routes.bankDetailsAdd}/${reseller_id}`, {
        "reseller_acc_no": parseInt(editedDetails.reseller_acc_no, 10),
        "reseller_ifsc_code": parseInt(editedDetails.reseller_ifsc_code, 10),
        "reseller_account_name": `${editedDetails.reseller_account_name}`,
      });

      if (response.status === 200) {
        console.log('Bank details added successfully:', response.data);
        setAlert({
          type: 'success',
          message: 'Successfully added new Bank details.',
        });

        // If adding new is successful, you may want to update the bankValues state
        setBankValues([...bankValues, response.data]);
      } else {
        console.error('Failed to add new bank details. Server response:', response);
        setAlert({
          type: 'danger',
          message: 'Failed to add new bank details.',
        });
      }
    }
  } catch (err) {
    console.error('Error during bank details processing:', err);
    setAlert({
      type: 'danger',
      message: 'Error during bank details processing.',
    });
  }
};


const addNewBank = async () => {
 const newBank = {
    reseller_acc_no: null,
    reseller_ifsc_code: null,
    reseller_account_name: null,
  };
  setBankValues([...bankValues, newBank]);
}



	const [paymentHistory, setPaymentHistory] = useState([]);
	const [showPaymentHistoryModal, setShowPaymentHistoryModal] = useState(false);

	const handlePaymentHistoryClick = async () => {
		try {
		  const response = await axios.get(urls.serverUrl + routes.paymentHistory + `/${reseller_id}`);
		  const historyData = response.data.results;
		  setPaymentHistory(historyData);
		  setShowPaymentHistoryModal(true);
		} catch (error) {
		  console.error('Error fetching payment history:', error);
		}
	};
	const closePaymentHistoryModal = () => {
		setShowPaymentHistoryModal(false);
	};

	function formatDate(inputDate) {
	  const date = new Date(inputDate);
	  const day = String(date.getDate()).padStart(2, '0');
	  const month = String(date.getMonth() + 1).padStart(2, '0');
	  const year = String(date.getFullYear()).slice(-2);
	  const formattedDate = `${day}/${month}/${year}`;

	  return formattedDate;
	}

	const closeAlert = () => {
    	setAlert(null);
    };


  return (
	  	
		<>
			<div
				className="uk-section-signin uk-section-profile uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center uk-flex-column">
					<img
						src="/images/profile_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
					<p className="uk-margin-large-top uk-text-4 uk-width-large uk-text-center uk-text-fw-600">
						Lorem ipsum dolor sit amet consectetur.
					</p>
				</div>
				<div className="uk-flex uk-flex-center uk-flex-middle">
					<div className="uk-container-signup-form uk-section">
						{alert && (
					        <AlertComponent
					          type={alert.type}
					          title={alert.title}
					          message={alert.message}
					          onClose={closeAlert}
					        />
					    )}
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div className="uk-flex uk-flex-middle">
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left-small uk-icon-black"
									/>
								</button>
								<p className="uk-text-5-small uk-text-fw-600 uk-margin-left">
									{t('payouts')}
								</p>
							</div>
						</div>

						<div className="uk-list">
							<div className="uk-flex uk-flex-column uk-flex-between uk-flex-middle uk-child-width-1-1 uk-width-expand">
						        <div className="uk-flex uk-flex-middle uk-margin-medium-top uk-flex-between">
						          <ReactSVG className="uk-width-1-6 uk-margin-small-right" src={upi} />
						          <p className="uk-color-green uk-text-6 uk-text-fw-500">
						            {t('savedUPIID')}
						          </p>
						        </div>
						        {upiValues.map((value, index) => (
						          <div key={value.upi_id} className="uk-flex uk-background-muted uk-flex-middle uk-margin-small-top">
						            <input
						              type="text"
						              className="uk-input uk-width-2-3"
						              value={isEditing && editingIndex === index ? editingValue : value.reseller_upi_name}
						              placeholder="Add New"
						              onChange={(e) => {
								        if (isEditing) {
								          setEditingValue(e.target.value);
								          handleUpiEditClick(e.target.value, index);
								        } else {
								          handleUpiInputChange(index, e.target.value);
								        }
								      }}
						              disabled={isEditing || newUpi ? false : true}
						            />
						            <div className="uk-width-1-3 uk-flex uk-flex-middle uk-flex-around">
							            
									    {isEditing && editingIndex === index ? (
									        <ReactSVG
									          src={tick}
									          className="uk-icon-delete uk-margin-remove uk-button-medium uk-padding-remove"
									          onClick={() => handleUpiSaveEditClick(value.upi_id)}
									        />
									      ) : (
									        <ReactSVG
										        src={pen}
										        className="uk-icon-delete uk-margin-remove uk-button-medium"
										        onClick={() => handleUpiEditClick(value.reseller_upi_name, index)}
										    />
								      	)}
								        {!isEditing && isTyping && editingIndex === index ? (
									        <ReactSVG
									          src={tick}
									          className="uk-icon-delete uk-margin-remove uk-button-medium uk-padding-remove"
									          onClick={() => handleUpiSaveClick(value)}
									        />
									      ) : (
									        <ReactSVG
									          src={trash}
									          className="uk-icon-delete uk-margin-remove uk-button-medium"
									          onClick={() => handleUpiDeleteClick(value.upi_id)}
									        />
								      	)}
						            </div>
						          </div>
						        ))}
						        <div className="uk-flex-inline uk-flex-right uk-align-right uk-margin-small-right">
						        	<button className="uk-button uk-button-default uk-button-small uk-padding-small uk-padding-remove-horizontal uk-margin-small-top uk-align-right" style={{ width: "100px", border: "2px solid black", borderRadius: "25px", color: "black" }} onClick={handleUpiAddClick}>+ {t('addNew')}</button>
					        	</div>
						    </div>

							<div className="uk-flex uk-flex-column uk-flex-between uk-flex-middle uk-child-width-1-1 uk-width-expand">
							    <div className="uk-flex uk-flex-middle uk-flex-between">
						          <ReactSVG className="uk-width-1-6 uk-margin-remove" src={netbanking} />
						          <p className="uk-color-green uk-text-6 uk-text-fw-500">
						            {t('savedAccountDetails')}
						          </p>
						        </div>
						        <div className="uk-flex-inline uk-flex-right uk-align-right uk-margin-small-right">
						        	{(!bankValues || bankValues.length === 0) && (
								      <button
								        className="uk-button uk-button-default uk-button-small uk-padding-small uk-padding-remove-horizontal uk-margin-small-top uk-align-right"
								        style={{ width: "100px", border: "2px solid black", borderRadius: "25px", color: "black" }}
								        onClick={addNewBank}
								      >
								        {t('addBank')}
								      </button>
								    )}
					        	</div>

						        {bankValues.map((value, index) => (
						          <div key={index} className="uk-flex uk-flex-between uk-background-muted uk-flex-middle uk-margin-small-top">
						          	<p className="uk-width-2-5 uk-margin-remove" style={{ paddingLeft: '6px' }}>Acc no: </p>
						            <input
						              type="number"
						              className="uk-input uk-width-5-6"
						              value={value.reseller_acc_no || ""}
						              placeholder="Enter"
						              onChange={(e) => handleAccNoInputChange(index, e.target.value)}
						            />
						            {isAccNoModified ? (
								      <ReactSVG
								        src={tick}
								        className="uk-flex-center uk-flex uk-icon-tick uk-padding-remove uk-width-1-6 uk-button-large"
								        onClick={(e) => handleBankSaveClick(index)}
								      />
								    ) : (
								      <ReactSVG
								        src={pen}
								        className="uk-flex-center uk-flex uk-icon-delete uk-padding-remove uk-width-1-6 uk-button-large"
								        onClick={(e) => {}}
								      />
								    )}
						          </div>
						        ))}

						        {bankValues.map((value, index) => (
						          <div key={index} className="uk-flex uk-flex-between uk-background-muted uk-flex-middle uk-margin-small-top">
						          	<p className="uk-width-2-5 uk-margin-remove" style={{ paddingLeft: '6px' }}>IFSC: </p>
						            <input
						              type="number"
						              className="uk-input uk-width-5-6"
						              value={value.reseller_ifsc_code || ""}
						              placeholder="Enter"
						              onChange={(e) => handleIFSCInputChange(index, e.target.value)}
						            />
						            {isIfscModified ? (
								      <ReactSVG
								        src={tick}
								        className="uk-flex-center uk-flex uk-icon-tick uk-padding-remove uk-width-1-6 uk-button-large"
								        onClick={(e) => handleBankSaveClick(index)}
								      />
								    ) : (
								      <ReactSVG
								        src={pen}
								        className="uk-flex-center uk-flex uk-icon-delete uk-padding-remove uk-width-1-6 uk-button-large"
								        onClick={(e) => {}}
								      />
								    )}
						          </div>
						        ))}

						        {bankValues.map((value, index) => (
						          <div key={index} className="uk-flex uk-flex-between uk-background-muted uk-flex-middle uk-margin-small-top">
						          	<p className="uk-width-2-5 uk-margin-remove" style={{ paddingLeft: '6px' }}>Holder Name: </p>
						            <input
						              type="text"
						              className="uk-flex-center uk-flex uk-input uk-width-5-6"
						              value={value.reseller_account_name || ""}
						              placeholder="Enter"
						              onChange={(e) => handleHolderNameInputChange(index, e.target.value)}
						            />
						            {isHolderModified ? (
								      <ReactSVG
								        src={tick}
								        className="uk-flex-center uk-flex uk-icon-tick uk-padding-remove uk-width-1-6 uk-button-large"
								        onClick={(e) => handleBankSaveClick(index)}
								      />
								    ) : (
								      <ReactSVG
								        src={pen}
								        className="uk-flex-center uk-flex uk-icon-delete uk-padding-remove uk-width-1-6 uk-button-large"
								        onClick={(e) => {}}
								      />
								    )}
						          </div>
						        ))}
							</div>

							<div className="uk-flex uk-flex-column uk-flex-between uk-flex-middle">
								<button className="uk-button uk-button-default uk-button-small uk-padding-small uk-padding-remove-horizontal uk-margin-small-top" style={{ color: "white", backgroundColor: "black"}} onClick={handlePaymentHistoryClick}>
									{t('checkPaymentHistory')}
								</button>
							</div>

							{showPaymentHistoryModal && (
						        <div className="uk-modal uk-open" style={{ display: 'block', overflow: 'scroll' }}>
						          <div className="uk-modal-dialog uk-modal-body">
						            <button
						              className="uk-modal-close-default"
						              type="button"
						              onClick={closePaymentHistoryModal}
						              uk-close
						            />
						            <h2 className="uk-modal-title uk-padding-remove">{t('paymentHistory')}</h2>
						            <ul className="uk-list">
						              {paymentHistory.map((payment, index) => (
						                <li key={index} className="uk-margin-small">
						                	<div className="uk-background-muted uk-padding-small">
						                		<div className="uk-flex uk-flex-between">
						                			<p>{t('customerName')}:</p>
						                			<p>{t('received')}</p>
						                		</div>
						                		<div className="uk-flex uk-flex-between uk-text-bolder">
						                			<p className="uk-text-large">{payment.user.user_name}</p>
						                			<p className="uk-text-success">&#8377;{payment.paid_amount}</p>
						                		</div>
						                		<hr className="uk-margin-small"/>
						                		<div className="uk-flex uk-flex-between uk-text-small">
						                			<p>{t('date')}: {formatDate(payment.payment_creation_dt)}</p>
						                			<p className="uk-text-success">{t('viewBookingDetails')}</p>
						                		</div>
						                	</div>
						                </li>
						              ))}
						            </ul>
						          </div>
						        </div>
						    )}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Payouts;
