import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { ReactSVG } from "react-svg";
import search from "../../assets/svg_icons/search.svg";
import PlacesSearch from "../PlaceSearch/PlacesSearch";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useTranslation } from 'react-i18next';


function Search({ onClose, submit, filterData, categories, clear }) {

	const storedLanguage = localStorage.getItem('language');
	console.log(storedLanguage);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (storedLanguage) {
			i18n.changeLanguage(storedLanguage);
		} else {
			i18n.changeLanguage('en');
		}
	}, [storedLanguage, i18n]);

	const popupRef = useRef(null);
	const [gender, setGender] = useState('male');
	const [location, setLocation] = useState("");
	const [maleCat,setMaleCat]=useState(categories.category_male_details)
	const [femaleCat,setFemaleCat]=useState(categories.category_woman_details)

	const [selectedCategory,setSelectedCategory]=useState('')
	
	const selectionRange = {
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	};

	async function handleDateChange(e) {
		selectionRange.startDate = e.selection.startDate;
		selectionRange.endDate = e.selection.endDate;
	}

	function applySearch() {
		const startDate =
			selectionRange.startDate.toISOString().split("T")[0] + " 00:00:00";
		const endDate =
			selectionRange.endDate.toISOString().split("T")[0] + " 00:00:00";

		submit({
			gender,
			latitude: location?.lat,
			longitude: location?.lng,
			selectedCategory,
			start_time: startDate === endDate ? "" : startDate,
			end_time: startDate === endDate ? "" : endDate,
		});
		onClose();
	}

	function handleClear(params) {
		setGender(0);
		clear();
		onClose();
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				onClose();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);

	return (
		<>
			<div
			style={{top:'30vh', marginTop: '15%'}}
				className="uk-card uk-card-search uk-position-absolute uk-background-default"
				ref={popupRef}>
				<div className="uk-card-body">
					<p className="uk-text-6-big uk-text-fw-600">{t('searchItems')}</p>
					<ul className="uk-list uk-list-sort uk-margin-medium-top">
						{/* gender selection */}
						<li>
							<select
								className="uk-select"
								name="gender"
								value={gender}
								onChange={(e) => setGender(e.target.value)}>
								<option value='male'>{t('male')}</option>
								<option value="female">{t('female')}</option>
							</select>
						</li>
					</ul>
					<p className="uk-text-muted uk-margin-top">{t('where')}</p>
					<PlacesSearch onSelect={setLocation} />
					<p className="uk-text-muted uk-margin-top">{t('what')}</p>
					<ul className="uk-list uk-list-sort uk-margin-small-top">
						<li>
							<select 
								className="uk-select"
								name="category"
								value={selectedCategory}
								onChange={(e) => setSelectedCategory(e.target.value)}
								>
								<option>{t('selectCategory')}</option>
								{
									gender==='male'?(
										maleCat?.map((item, index) => (
											<>
											<option key={index} value={item.category_name}>
												{item.category_name}
											</option>
											</>
										))
									):(
										femaleCat?.map((item, index) => (
											<option key={index} value={item.category_name}>
												{item.category_name}
											</option>
										))
									)
								}
							</select>
						</li>
					</ul>

					<p className="uk-text-muted uk-margin-top">{t('when')}</p>
					<div className="sort-react-date-range-picker-container">
						<DateRangePicker
							ranges={[selectionRange]}
							onChange={handleDateChange}
							className="uk-flex uk-flex-column"
						/>
					</div>
					<button
						className="uk-button uk-button-primary uk-flex-center uk-flex uk-width-1-1"
						onClick={applySearch}>
						<ReactSVG src={search} className="uk-icon-white" />
						<span className="uk-margin-left">{t('searchProducts')}</span>
					</button>
					<button
						style={{ marginTop: "10px" }}
						className="uk-button uk-button-info uk-flex-center uk-flex uk-width-1-1"
						onClick={handleClear}>
						<span className="uk-margin-left">{t('clear')}</span>
					</button>
				</div>
			</div>
		</>
	);
}

export default Search;