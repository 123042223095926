import { React } from "react";
import { Outlet } from "react-router-dom";
import { EditProductContextProvider } from "../context/editProductContext";

function EditProduct() {
    return (
        <>
            <EditProductContextProvider>
                <Outlet /> 
            </EditProductContextProvider>
        </>
    )
}

export default EditProduct;
