import { React, useState, useEffect } from "react";
import marker from "../../assets/svg_icons/map-marker.svg";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import heart from "../../assets/svg_icons/heart.svg";
import thumbsup from "../../assets/svg_icons/thumbsup.svg";
import axios from "axios";
import { routes, urls } from "../../Global";
import { addDefaultSrc } from "../../lib/helper";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next';

function ProductItem(props) {
    const item = props.item.product_details;
    const [productLike, setProductLike] = useState(item.product_like);

    const cookies = new Cookies();

    const id = cookies.get("id");
    async function addToWishlist(e) {
        e.persist();
        try {
            console.log(item.product_id);
            e.currentTarget.classList.toggle("active");
            const add = await axios.post(urls.serverUrl + routes.addToWishlist + `/${id}`, {
                product_id: item.product_id,
            });
            console.log(add);
        } catch (err) {
            console.log(err);
        }
    }

    console.log(item.product_like);

    async function handleLike(e) {
        e.persist();
        const button = e.currentTarget;
        button.classList.toggle("active");

        const isActive = button.classList.contains("active");
        console.log(isActive);
        if (isActive) {
            setProductLike((prevLikes) => prevLikes + 1);
            console.log(productLike);

            const addtolike = await axios.patch(urls.serverUrl + routes.likeProduct + `/${id}`, {
                product_id: item.product_id,
            });
            console.log(addtolike);
        } else {
            setProductLike((prevLikes) => prevLikes - 1);
            const dislike = await axios.patch(urls.serverUrl + routes.dislikeProduct + `/${id}`, {
                product_id: item.product_id,
                like_count: productLike,
                like_time: new Date(),
            });
            console.log(dislike);
        }
    }

    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
      if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
      } else {
          i18n.changeLanguage('en');
      }
    }, [storedLanguage, i18n]);

    return (
        <div className="uk-card uk-card-product-item">
            <div className="uk-position-relative">
                {/* <ReactSVG
				src={heart}
				className={`uk-icon-heart uk-margin-left ${
					item.wishlists.length > 0 ? "active" : ""
				}`}
				onClick={addToWishlist}
			/> */}

                <ReactSVG
                    src={heart}
                    className={`uk-icon-heart ${item.wishlists.length > 0 ? "active" : "inactive"}`}
                    onClick={addToWishlist}
                />

                <Link to={`/product/${item.product_id}`}>
                    <img
                        src={item.product_images[0].image_link}
                        alt={item.product_title}
                        onError={addDefaultSrc}
                    />
                </Link>

                <button
                    className={`uk-icon-like-btn ${
                        item.product_like.length === 0 ? "active" : "inactive"
                    }`}
                    onClick={handleLike}>
                    <ReactSVG src={thumbsup} />
                    <span>{productLike}</span>
                </button>
            </div>
            <div className="uk-card-body">
                <p className="uk-card-title">{item.product_title}</p>
                <p className="uk-flex uk-flex-between uk-margin-xxsmall-top uk-text-muted">
                    <span>{t('mrp')} : ₹{item.mrp}</span>
                    <span className="uk-margin-right">{t('rent')} : ₹{item.product_price}</span>
                    {/* <span className="uk-margin-left">
							<img
								src={marker}
								alt="map marker"
								style={{ width: "9px", marginRight: "5px" }}
							/>
							{item.product_location}
						</span> */}
                </p>
                <p className="uk-margin-xsmall-top uk-text-muted">{item.product_desc}</p>
            </div>
            {/* </Link> */}
        </div>
    );
}

export default ProductItem;
