import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
// import infoLight from "../../assets/svg_icons/info-light.svg";
import Cookies from "universal-cookie";
import { ReactSVG } from "react-svg";
import axios from "axios";
import { routes, urls } from "../../Global";
import Alert from "../Helpers/Alert";
import { useTranslation } from 'react-i18next';

function OTPVerification() {
    const { loggedIn, getLoggedIn, removeCookies, verifyOpenRoutes, myCookies } =
        useContext(AuthContext);
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [otp, setOtp] = useState("");
    const number = cookies.get("number");
    const [responseMessage, setResponseMessage] = useState({
        type: false,
        message: false,
    });

    var refreshTime = 10;
    const refreshTag = useRef();
    const [showResend, setShowResend] = useState(false);

    useEffect(function () {
        if (loggedIn === false || !number) {
            navigate("/login", { replace: true });
        }
    });

    useEffect(
        function () {
            let refreshInterval;
            if (!showResend) {
                setTimeout(function () {
                    setShowResend(true);
                }, refreshTime * 1000);
                refreshInterval = setInterval(function () {
                    if (refreshTag.current) {
                        refreshTime--;
                        refreshTag.current.innerHTML = `Resend OTP in ${refreshTime}`;
                    } else {
                        clearInterval(refreshInterval);
                    }
                    if (refreshTime <= 0) {
                        setShowResend(true);
                        clearInterval(refreshInterval);
                    }
                }, 1000);
            } else {
                clearInterval(refreshInterval);
            }
        },
        [showResend]
    );

    async function resend() {
        setShowResend(false);
        if (number) {
            let response = await axios.post(
                urls.serverUrl + routes.login,
                JSON.stringify({ number })
            );

            if (response.data.status === 200) {
                cookies.set(myCookies.number.name, number, {
                    path: "/",
                    expires: new Date(Date.now() + 2592000 * 1000),
                });
                cookies.set(
                    myCookies.verification_code.name,
                    response.data.results.verification_code,
                    {
                        path: "/",
                        expires: new Date(Date.now() + 2592000 * 1000),
                    }
                );
                await getLoggedIn();
                navigate("/verify-otp");
            }
        } else {
            setResponseMessage({
                type: "danger",
                message: "Enter your phone number",
            });
        }
    }

    const inputRefs = useRef([]);
    let value;

    const handleChange = (index, e) => {
        value = e.target.value;
        const newOtp = otp.slice(0, index) + value + otp.slice(index + 1);
        setOtp(newOtp);
        if (value.length > 0 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
            console.log(otp[index]);
            console.log(newOtp); 
        }
    };

    const handleKeyDown = (index, e) => {
        const value = e.target.value;
        if (e.key === "Backspace" && value.length === 0 && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    async function goBack() {
        try {
            await removeCookies();
            navigate(-1);
        } catch (err) {
            console.log(err);
        }
    }

    async function verifyOtp(e) {
        e.preventDefault();
        try {
            const res = await axios.post(urls.serverUrl + routes.verifyOtp, {
                verification_code: myCookies.verification_code.value,
                otp: otp,
            });
            myCookies.otp_verified.value = true;
            if (res.data.status === 200) {
                const user_id = res.data.user_details[0].user_id;

                cookies.set(myCookies.token.name, res.data.user_details[0].token, {
                    path: "/",
                    expires: new Date(Date.now() + 2592000 * 1000),
                });

                cookies.set(myCookies.id.name, res.data.user_details[0].user_id, {
                    path: "/",
                    expires: new Date(Date.now() + 2592000 * 1000),
                });

                cookies.set(myCookies.reseller_id.name, res.data.user_details[0].reseller_id, {
                    path: "/",
                    expires: new Date(Date.now() + 2592000 * 1000),
                });

                cookies.set(myCookies.otp_verified.name, true, {
                    path: "/",
                    expires: new Date(Date.now() + 2592000 * 1000),
                });

                await getLoggedIn();

                let user_details_res = await axios.get(
                    urls.serverUrl + routes.getUserProfile + `/${user_id}`,
                    {
                        headers: {
                            Authorization: "Bearer " + res.data.user_details[0].token,
                        },
                    }
                );

                let do_redirect_to_profile = false;

                if (user_details_res.data.user_details[0].user_details_status === 0) {
                    do_redirect_to_profile = true;
                }

                verifyOpenRoutes();
                navigate(do_redirect_to_profile ? "/profile?redirect=/" : "/", {
                    replace: true,
                });
            }
        } catch (err) {
            setResponseMessage({
                type: "danger",
                message: "OTP is invalid",
            });
        }
    }

    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return (
        <>
            <div
                className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
                data-uk-grid>
                <div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
                    <img
                        src="/images/side_banner.png"
                        alt="Side Banner"
                        className="uk-width-side-banner"
                    />
                </div>
                <div className="uk-flex uk-flex-center uk-flex-middle">
                    <div className="uk-container uk-container-signup-form uk-section">
                        <div className="uk-flex uk-flex-middle uk-flex-between">
                            <button onClick={goBack} className="uk-button-noeffect">
                                <ReactSVG
                                    src={arrowLeft}
                                    className="uk-icon-left-small uk-icon-black"
                                />
                            </button>
                            {/* <div>
                <ReactSVG
                  src={infoLight}
                  className="uk-icon-info-light uk-icon-black"
                />
              </div> */}
                        </div>
                        <p className="uk-text-4 uk-text-fw-600" style={{ marginTop: "35px" }}>
                            {t('codeConfirmation')}
                        </p>
                        <form className="uk-margin-large-top" onSubmit={verifyOtp}>
                            <p className="uk-text-center uk-text-muted">
                                {t('enter4DigitCode')} <br />
                                <strong className="uk-color-primary">
                                    {myCookies.number.value}
                                </strong>
                            </p>
                            <div
                                className="uk-grid uk-child-width-1-4 uk-grid-otp"
                                data-uk-grid
                                style={{ marginTop: "28px", display: "flex", justifyContent: "center" }}>
                                {[0, 1, 2, 3].map((index) => (
                                    <div key={index} style={{ position: "relative" }}>
                                      <input
                                        key={index}
                                        className="uk-width-1-1"
                                        type="text"
                                        maxLength={1}
                                        value={otp[index] || ""}
                                        onChange={(e) => handleChange(index, e)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        ref={(ref) => (inputRefs.current[index] = ref)}
                                        style={{ color: "black" }}
                                      />
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          color: "black",
                                          fontSize: "22px"
                                        }}
                                      >
                                        {otp[index]}
                                      </span>
                                    </div>
                                ))}
                            </div>
                            <p
                                className="uk-text-muted uk-text-center"
                                style={{ marginTop: "40px" }}>
                                {t('didntGetCode')}
                            </p>
                            <div>
                                {!showResend ? (
                                    <p className="uk-text-muted uk-text-center" ref={refreshTag}>
                                        {t('resendOTPIn')} {refreshTime}
                                    </p>
                                ) : (
                                    <button
                                        style={{ padding: "16px", marginTop: "16px" }}
                                        type="button"
                                        className="uk-button uk-button-white uk-text-7"
                                        onClick={resend}>
                                        {t('sendAgain')}
                                    </button>
                                )}
                                <button
                                    style={{ padding: "16px", marginTop: "16px" }}
                                    type="submit"
                                    onClick={verifyOtp}
                                    className="uk-button uk-button-primary uk-text-7">
                                    {t('verifyOTP')}
                                </button>
                            </div>
                            <Alert
                                className="uk-margin-top"
                                message={responseMessage}
                                reset={function () {
                                    setResponseMessage({
                                        type: false,
                                        message: false,
                                    });
                                }}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OTPVerification;