import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../Helpers/Header";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import upi from "../../assets/svg_icons/upi.svg";
import visa from "../../assets/svg_icons/visa.svg";
import net from "../../assets/svg_icons/net-banking.svg";
import paytm from "../../assets/svg_icons/paytm.svg";
import material from "../../assets/svg_icons/material.svg";
import chevronRight from "../../assets/svg_icons/chevron-right.svg";
import bookedIcon from "../../assets/svg_icons/booked.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { routes, urls } from "../../Global";
import UIkit from "uikit";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function Payment() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        } else {
          i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);
    
    const navigate = useNavigate();
    const location = useLocation();
    function goBack() {
        navigate(-1);
    }
    var refreshTime = 3;
    const refreshTag = useRef();

    const [bookingDetails, setBookingDetails] = useState(location.state);
    const [disableBooking, setDisableBooking] = useState(false);
    const [booked, setBooked] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    // async function bookProduct() {
    //     try {
    //         setDisableBooking(true);
    //         const booking = await axios.post(
    //             urls.serverUrl + routes.bookingAdd + `/${bookingDetails.user_id}`,
    //             bookingDetails
    //         );
    //         const payment = await axios.post(
    //             urls.serverUrl + routes.paymentAdd + `/${bookingDetails.user_id}`,
    //             {
    //                 booking_id: booking.data.booking_id,
    //                 product_id: bookingDetails.product_id,
    //                 user_id: bookingDetails.user_id,
    //                 paid_amount: bookingDetails.total_price,
    //                 paid_status: bookingDetails.booking_payments_status,
    //                 upi_pay_status: "upi",
    //                 payment_method: "phonepe",
    //                 reseller_id: bookingDetails.reseller_id,
    //                 coin: bookingDetails.coin,
    //             }
    //         );
    //         setBooked(true);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    const book = useCallback(async () => {
        try {
            setDisableButton(true);
            const booking = await axios.post(
                urls.serverUrl + routes.bookingAdd + `/${bookingDetails.user_id}`,
                bookingDetails
            );

            const payment = await axios.post(
                urls.serverUrl + routes.paymentAdd + `/${bookingDetails.user_id}`,
                {
                    booking_id: booking.data.booking_id,
                    product_id: bookingDetails.product_id,
                    user_id: bookingDetails.user_id,
                    paid_amount: bookingDetails.total_price,
                    paid_status: bookingDetails.booking_payments_status,
                    upi_pay_status: "upi",
                    payment_method: "phonepe",
                    reseller_id: bookingDetails.reseller_id,
                    coin: bookingDetails.coin,
                }
            );

            const ccavlink = await axios.post(urls.serverUrl + routes.ccavLink, {
                billing_name: bookingDetails.user_id,
                order_id: booking.data.booking_id,
                amount: bookingDetails.total_price,
            });

            if (ccavlink.data) {
                console.log(ccavlink.data.payLink);
                window.open(ccavlink.data.payLink, "_blank");
                navigate("/");
            }
        } catch (err) {
            console.log(err);
        }
    }, []);
    // async function book() {
    //     try {
    //         const booking = await axios.post(
    //             urls.serverUrl + routes.bookingAdd + `/${bookingDetails.user_id}`,
    //             bookingDetails
    //         );
    //         const payment = await axios.post(
    //             urls.serverUrl + routes.paymentAdd + `/${bookingDetails.user_id}`,
    //             {
    //                 booking_id: booking.data.booking_id,
    //                 product_id: bookingDetails.product_id,
    //                 user_id: bookingDetails.user_id,
    //                 paid_amount: bookingDetails.total_price,
    //                 paid_status: bookingDetails.booking_payments_status,
    //                 upi_pay_status: "upi",
    //                 payment_method: "phonepe",
    //                 reseller_id: bookingDetails.reseller_id,
    //                 coin: bookingDetails.coin,
    //             }
    //         );

    //         const ccavlink = await axios.post(urls.serverUrl + routes.ccavLink, {
    //             billing_name: bookingDetails.user_id,
    //             order_id: booking.data.booking_id,
    //             amount: bookingDetails.total_price,
    //         });

    //         if (ccavlink.data) {
    //             console.log(ccavlink.data.payLink);
    //             window.open(ccavlink.data.payLink, "_blank");
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    useEffect(
        function () {
            let refreshInterval;
            if (booked) {
                setTimeout(function () {
                    const modal = UIkit.modal("#booking_on_hold");
                    if (modal) {
                        modal.show();
                        refreshInterval = setInterval(function () {
                            refreshTag.current.innerHTML = `Refreshing in ${refreshTime}`;
                            if (refreshTime <= 0) {
                                window.history.replaceState({}, document.title, "/");
                                window.location.replace("/");
                            }
                            refreshTime--;
                        }, 1000);
                    }
                }, 1000);
            }

            return clearInterval(refreshInterval);
        },
        [booked]
    );

    return (
        <>
            <Header />
            <div className="uk-section uk-section-payments uk-position-relative">
                <div className="uk-container">
                    <div className="uk-flex">
                        <button onClick={goBack} className="uk-button-noeffect">
                            <ReactSVG src={arrowLeft} className="uk-icon-left uk-icon-black" />
                        </button>
                        <p className="uk-text-4 uk-text-fw-600 uk-margin-left">{t('pay')}</p>
                    </div>
                    <div className="uk-width-payments uk-margin-top">
                        <div className="uk-card uk-card-payable">
                            <div className="uk-card-body">
                                <p className="uk-text-5-xsmall uk-text-fw-500">
                                    {t('totalPayableAmount')}
                                </p>
                                <p className="uk-text-4 uk-color-green uk-text-fw-600">
                                    ₹{bookingDetails.total_price}
                                </p>
                            </div>
                        </div>
                        <p className="uk-margin-top uk-text-5-xsmall">{t('paymentMethods')}:</p>
                        <ul className="uk-list uk-list-payment-options">
                            <li>
                                <a
                                    className="uk-color-primary uk-flex uk-flex-middle uk-flex-between"
                                    onClick={() => {
                                        if (!disableButton) {
                                            book();
                                        }
                                    }}>
                                    <div>
                                        <ReactSVG src={upi} />
                                        <p className="uk-text-5 uk-margin-small-top">
                                            {t('payUsingUPI')}
                                        </p>
                                        <div className="uk-flex uk-flex-middle uk-margin-small-top">
                                            <ReactSVG src={material} className="uk-color-green" />
                                            <p className="uk-margin-left uk-text-6-xbig uk-color-green">
                                                {t('recommendedMethod')}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactSVG src={chevronRight} className="uk-color-primary" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="uk-color-primary uk-flex uk-flex-middle uk-flex-between">
                                    <div>
                                        <ReactSVG src={visa} />
                                        <p className="uk-text-5 uk-margin-small-top">
                                            {t('payUsingDebitOrCreditCard')}
                                        </p>
                                        <div>
                                            <p className="uk-text-6-xbig uk-color-red uk-margin-small-top">
                                                {t('extraTransactionCharges')}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactSVG src={chevronRight} className="uk-color-primary" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="uk-color-primary uk-flex uk-flex-middle uk-flex-between">
                                    <div>
                                        <ReactSVG src={net} />
                                        <p className="uk-text-5 uk-margin-small-top">{t('netBanking')}</p>
                                        <div>
                                            <p className="uk-text-6-xbig uk-color-red uk-margin-small-top">
                                                {t('extraTransactionCharges')}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactSVG src={chevronRight} className="uk-color-primary" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="uk-color-primary uk-flex uk-flex-middle uk-flex-between">
                                    <div>
                                        <ReactSVG src={paytm} />
                                        <p className="uk-text-5 uk-margin-small-top">
                                            {t('paymentsWallets')}
                                        </p>
                                        <div>
                                            <p className="uk-text-6-xbig uk-color-red uk-margin-small-top">
                                                {t('extraTransactionCharges')}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactSVG src={chevronRight} className="uk-color-primary" />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="uk-section uk-section-booking fixed">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <p className="uk-text-6-big uk-text-fw-600">
                                Total Payable:
                            </p>
                            <p style={{ marginTop: "5px" }}>
                                <span className="uk-color-green uk-text-5-small">
                                    <strong className="uk-text-fw-600">
                                        ₹{bookingDetails.total_price}
                                    </strong>
                                </span>
                            </p>
                        </div>
                        <div>
                            <button className="uk-button uk-button-primary" disabled={disableBooking} onClick={bookProduct}>
                                Pay Now
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
                <div
                    id="booking_on_hold"
                    className="uk-modal-booking-hold"
                    data-uk-modal="bg-close: false;">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <ReactSVG src={bookedIcon} />
                        <p className="uk-text-6-big uk-text-fw-600">{t('bookingOnHold')}</p>
                        <p className="uk-text-6 uk-text-muted">
                            {t('waitingForSellerConfirmation')}
                        </p>
                        <p className="uk-text-9 uk-text-muted">{t('exploreMoreProducts')}</p>
                        <NavLink to="/" className="uk-button uk-button-primary">
                            {t('exploreMore')}
                        </NavLink>
                        <p ref={refreshTag}>Refreshing in {refreshTime}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Payment;
