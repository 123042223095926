import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import AuthContext from "../../context/authContext";

function LogoutBtn() {
  const { getLoggedIn, removeCookies } = useContext(AuthContext);
  const navigate = useNavigate();

  async function logout() {
    await removeCookies()
    await getLoggedIn()
    navigate("/")
  }

  useEffect(() => {
    logout();
  });

  return <></>;
}

export default LogoutBtn;