import { React, useState, useEffect, useRef } from "react";
import axios from "axios";
import { urls, routes } from "../../Global";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import tick from "../../assets/svg_icons/tick-large.svg";
import Alert from "../../Components/Helpers/Alert";
import Cookies from "universal-cookie"; 
import { useTranslation } from 'react-i18next'; 


function Add() {
    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    const [responseMessage, setResponseMessage] = useState({
        type: "",
        message: ""
    })

    const [updating, setUpdating] = useState(false)

    const cookies = new Cookies()
    const user_id = cookies.get("id")
    const reseller_id = cookies.get("reseller_id")

    const location = useLocation()
    const product = location.state ? location.state.product : {};
    product.user_id = user_id
    product.reseller_id = reseller_id

    var refreshTime = 3
    const refreshTag = useRef();

    async function createProduct() {
        try {
            const tempProd = {
                ...product
            };
            delete tempProd.display_image           
            setUpdating(true)
            const add = await axios.post(urls.serverUrl + routes.resellerCreateProduct + `/${reseller_id}`, tempProd, {
                'Content-Type': 'multipart/form-data'
            }) // api post request
            if (add.status === 200) {
                setUpdating(false)
            }


            {/*// Step 2: Add product to getProducts API
            let demo = "itemsearch=false&limit=100&offset=0";
            const addGetProductsProduct = await axios.post(
                urls.serverUrl + routes.getProducts + `/${user_id}?${demo}`,
                tempProd
            );

            // Check if the product was successfully added to getProducts API
            if (addGetProductsProduct.status !== 200) {
                throw new Error("Failed to add product to getProducts API");
            }

            // Step 3: Fetch updated list of products from getProducts API
            let products = await axios.get(urls.serverUrl + routes.getProducts + `/${user_id}?${demo}`);
            console.log(products);


            async function addProduct(newProductData) {
            try {
                // Make a POST request to add a new product
                const response = await axios.post(urls.serverUrl + routes.addProduct, newProductData);

                // Check if the product was successfully added
                if (response.status === 200) {
                    // If successful, fetch the updated list of products
                    // fetchUpdatedProducts();
                    console.log("done");
                } else {
                    console.error("Failed to add product. Server returned an error.");
                    // Handle error, show a message, etc.
                }
            } catch (error) {
                console.error("An error occurred while adding the product:", error);
                // Handle error, show a message, etc.
            }
        }*/}


            setInterval(function () {
                refreshTag.current.innerHTML = `Refreshing in ${refreshTime}`
                if (refreshTime <= 0) {
                    window.history.replaceState({}, document.title, '/seller');
                    window.location.replace('/seller');
                }
                refreshTime--
            }, 1000)
        } catch (err) {
            setResponseMessage({
                type: "danger",
                message: "Some error occured, please try again"
            })
            setUpdating(false)
        }
    }

    useEffect(function () {
        createProduct()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div
                className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
                data-uk-grid>
                <div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
                    <img
                        src="/images/side_banner.png"
                        alt="Side Banner"
                        className="uk-width-side-banner"
                    />
                </div>
                <div className="uk-flex uk-flex-center uk-flex-middle">
                    <div className="uk-container uk-container-add uk-section uk-width-1-1">
                        {
                            updating === true ? (
                                <>
                                    <div className="uk-text-center">
                                        <div data-uk-spinner></div>
                                        <p className="uk-margin-top">{t('addingProduct')}</p>
                                    </div>
                                </>
                            ) : updating === false ? (
                                <>
                                    <Alert
                                        message={responseMessage}
                                        reset={function () {
                                            setResponseMessage({
                                                type: false,
                                                message: false,
                                            });
                                        }}
                                    />
                                    <div className="uk-position-relative">
                                        <img src={`${product.display_image.base64UnCut}`} alt={product.product_title} />
                                        <ReactSVG src={tick} className="uk-icon-product-added" />
                                    </div>
                                    <p style={{ marginTop: "85px" }} className="uk-text-6 uk-text-bold uk-text-center">{t('productListedSuccessfully')}</p>
                                    <p className="uk-text-center uk-margin-top" ref={refreshTag}>Refreshing in {refreshTime}</p>
                                </>
                            ) : ("")
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Add;
