import axios from "axios";
import { routes, urls } from "../Global";
import Cookies from "universal-cookie";
import { useEffect, useRef, useState } from "react";
import SellerProductItem from "./ProductItem";
import UIkit from "uikit";
import ModalPortal from "../Components/Helpers/ModalPortal";
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-multi-date-picker';
import "react-multi-date-picker/styles/colors/green.css"

import { NavLink } from "react-router-dom";
import Alert from "../Components/Helpers/Alert";
import { useTranslation } from 'react-i18next';


function Calender() {
    const [products, setProducts] = useState([]);
    const [bookingDates, setBookingDates] = useState(false)
    const [bookingDisabled, setBookingDisabled] = useState(false)
    const [responseMessage, setResponseMessage] = useState({
        type: "",
        message: "",
    });

    const cookies = new Cookies()
    const reseller_id = cookies.get('reseller_id')
    const deleteRef = useRef(null);

    async function fetch() {
        try {
            const get = await axios.post(
							urls.serverUrl +
								routes.getResellerBookings +
								`/${reseller_id}?limit=1000&offset=0`
						);
            // const specificProduct = get.data.data.find(
            //     (product) => product.product_id === 158
            // );

            // if (specificProduct) 
            //     setProducts([specificProduct]);
            // else
                setProducts(get.data.data);

            // console.log(urls.serverUrl + routes.getResellerBookings + `/${reseller_id}?limit=1000&offset=0`)

        } catch (err) {
            // console.log(err)
            setProducts([])
        }
    }

    useEffect(function () {
        fetch()
        // eslint-disable-next-line
    }, [])

    const [values, setValues] = useState([]);

    async function viewBookings(product_id) {
        try {
            const { data } = await axios.get(urls.serverUrl + routes.viewBookingDates + `/${reseller_id}?product_id=${product_id}&booking_status=1,2,4,5&limit=1000&offset=0`)
            const arr = data.message
            console.log("arr", arr)
            let dates = []

            arr.forEach(element => {
                dates.push(element.booked_current_date)
                dates.push(element.booked_end_date)
            });

            setBookingDates(dates)
            // console.log("dates", dates)

            const convertedValues = arr.map(({ booked_current_date, booked_end_date }) => [
                new Date(booked_current_date),
                new Date(booked_end_date),
            ]);
            setValues(convertedValues);
            console.log("values", convertedValues)



        } catch (error) {
            // console.log(error)
            setBookingDates([])
        }
    }

    async function deleteBooking(e) {
        try {
            const product_id = e.target.dataset.product_id
            const deleted = await axios.delete(urls.serverUrl + routes.resellerDeleteProduct + `/${product_id}`)
            setResponseMessage({
                type: "success",
                message: "Product Deleted Successfully"
            })
            fetch()
            setBookingDisabled(false)
        } catch (err) {
            setBookingDisabled(false)
            setResponseMessage({
                type: "danger",
                message: "Could not delete product"
            })
        }
    }

    function showDeleteBookingPopup(product_id) {
        setTimeout(function () {
            const modal = UIkit.modal("#delete_booking");
            if (modal) {
                modal.show();
                deleteRef.current.dataset.product_id = product_id
            }
        }, 100)
    }

    function checkDate({ date }) {
        // console.log("chec", bookingDates)
        return bookingDates.includes(date.toISOString()) ? 'marked' : "asd"
    }

    function checkRangeDate({ date }) {
        const dateString = date.toISOString();
        const isInRange = bookingDates.some(
            (booking) => dateString >= booking.booked_current_date && dateString <= booking.booked_end_date
        );
        console.log("crd", dateString)
        return isInRange ? 'marked' : 'asd';
    }

    useEffect(
        function () {
            if (bookingDates) {
                setTimeout(function () {
                    const modal = UIkit.modal("#booking-dates");
                    if (modal) {
                        modal.show();
                    }
                }, 100);
            }
        },
        [bookingDates]
    );

    const handleClose = () => {
        setValues(null); 
    };

    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);


    return (
        <>
            <div className="uk-section uk-section-calender uk-position-relative">
                {bookingDisabled ? (
                    <div className="uk-background-wash uk-position-cover uk-position-z-index"></div>
                ) : ("")}
                <div className="uk-container">
                    <Alert
                        message={responseMessage}
                        reset={function () {
                            setResponseMessage({
                                type: false,
                                message: false,
                            });
                        }}
                    />
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <p className="uk-text-4 uk-text-fw-600">{t('calendar')}</p>
                        </div>
                        <div>
                            <NavLink to={`/seller/add-product`} className="uk-button uk-button-primary">
                                + {t('addItem')}
                            </NavLink>
                        </div>
                    </div>
                    <div data-uk-grid className="uk-grid uk-grid-calendar uk-child-width-1-3@m uk-child-width-1-2@s">
                        {products.map((product, index) => (
                            <SellerProductItem key={index} viewBookings={viewBookings} product={product} deleteBooking={showDeleteBookingPopup} />
                        ))}
                    </div>
                </div>

                <ModalPortal className="uk-flex-top"
                    id="booking-dates">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-flex-middle">
                        <button
                            className="uk-modal-close-default"
                            type="button"
                            data-uk-close onClick={handleClose}></button>
                        <p className="uk-text-center uk-margin-bottom">{t('itemBookings')}</p>
                        {bookingDates ? (
                            // <Calendar tileClassName={checkRangeDate} />
                            
                                <Calendar
                                    value={values}
                                    onChange={setValues}
                                    multiple
                                    range
                                    className="green"
                                    style={{ margin: 'auto' }}
                                />
                            
                        ) : (
                            <p className="uk-text-center uk-margin-bottom">{t('noBookingsFound')}</p>
                        )}
                    </div>
                </ModalPortal>

                <ModalPortal className="uk-flex-top"
                    id="delete_booking">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button
                            className="uk-modal-close-default"
                            type="button"
                            data-uk-close></button>
                        <p className="uk-text-center uk-margin-bottom">{t('confirmDeleteProduct')}</p>
                        <div className="uk-text-center">
                            <button className="uk-button uk-button-danger" ref={deleteRef} onClick={deleteBooking}>
                                {t('deleteProduct')}
                            </button>
                        </div>
                    </div>
                </ModalPortal>
            </div>
        </>
    );
}

export default Calender;
