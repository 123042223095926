import React, { useContext, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import search from "../assets/svg_icons/search.svg";
import { routes, urls } from "../Global";
import NotificationItem from "./NotificationItem";
import AuthContext from "../context/authContext";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next';


const SellerNotification = () => {
    const [notifications, setNotifications] = useState(false);

    const { myCookies } = useContext(AuthContext);
    const resellerId = myCookies.reseller_id.value;

    async function fetchNotifications() {
        try {
            const get = await axios.get(
                urls.serverUrl + routes.getBooking + `?reseller_id=${resellerId}&booking_status=2`
            );
            setNotifications(get.data.booking)
        } catch (err) {
            console.log(err)
            setNotifications(false)
        }
    }

    useEffect(function () {
        fetchNotifications()
        // eslint-disable-next-line
    }, [])

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return (
			<>
				<div className="uk-section-inbox chat-screen uk-width-1-1">
					<div className="uk-height-1-1 uk-width-1-1">
						<div className="uk-grid uk-grid-inbox uk-height-1-1 uk-width-1-1 uk-flex uk-flex-column" data-uk-grid>
							<div className="uk-width-2-5@m uk-overflow-hidden">
								<div className="uk-section-otp uk-flex uk-flex-between uk-flex-middle uk-hidden@m">
									<div> 
										<a
											href="#inbox-menu"
											data-uk-toggle=""
											className="uk-hamburger uk-style-tertiary uk-hidden@m uk-text-center uk-margin-left"
											aria-expanded="false">
											<span className="uk-navbar-toggle-icon">
												<svg
													className=""
													width="26"
													height="20"
													viewBox="0 0 26 20"
													xmlns="http://www.w3.org/2000/svg">
													<rect y="0" width="26" height="2"></rect>
													<rect y="9" width="26" height="2"></rect>
													<rect y="18" width="26" height="2"></rect>
												</svg>
											</span>
										</a>
									</div>
									<div></div>
								</div>
								<div className="uk-container uk-padding-horizontal-remove" style={{paddingLeft: '60px', paddingRight: '30px'}}>
									<div className="uk-visible@m uk-height-1-1">
										<div className="uk-flex uk-flex-between uk-flex-middle">
											<div>
												<p className="uk-text-4 uk-text-fw-600">{t('inbox')}</p>
											</div>
											<div>
												<div className="uk-input-search">
													<ReactSVG src={search} />
													<input
														className="uk-input"
														placeholder={t('search')}
														// onChange={changeSearch}
													/>
												</div>
											</div>
										</div>
										<div
											data-uk-slider
											className="uk-overflow-visible uk-margin-medium-top">
											<div className="uk-grid uk-slider-items uk-flex uk-flex-row uk-flex-center" data-uk-grid>
												<div>
													<NavLink
														to="/seller/inbox"
														className="uk-button uk-button-white uk-button-rounded uk-text-default" style={{ padding: '8px 16px' }}>
														{t('messages')}
													</NavLink>
												</div>
												<div>
													<NavLink
														to="/seller/inbox/notifications"
														className="uk-button uk-button-primary uk-button-rounded uk-text-default" style={{ padding: '8px 16px' }}>
														{t('notifications')}
													</NavLink>
												</div>
												<div>
													<NavLink
														to="/seller/inbox/ratings"
														className="uk-button uk-button-white uk-button-rounded uk-text-default" style={{ padding: '8px 16px' }}>
														{t('ratings')}
													</NavLink>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="uk-container uk-height-viewport">
								{notifications ? (
									<div
										data-uk-grid
										className="uk-grid uk-grid-notifications uk-child-width-1-3@m uk-child-width-1-2@m uk-align-center uk-flex-column uk-margin-xlarge-left">
										{notifications.map((notification, index) => (
											<NotificationItem
												reset={fetchNotifications}
												notification={notification}
												key={index}
											/>
										))}
									</div>
								) : (
									<p className="uk-margin-xlarge-left">{t('noNotificationSelected')}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
}

export default SellerNotification;
