import React, { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import search from "../assets/svg_icons/search.svg";
import verify from "../assets/svg_icons/verify.svg";
import copy from "../assets/svg_icons/copy.svg";
import call from "../assets/svg_icons/call.svg";
import mapmarker from "../assets/svg_icons/map-marker.svg";
import paperplane from "../assets/svg_icons/paperplane.svg";
import companyLogo from "../assets/logo/logo512.png";

import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { routes, urls } from "../Global";
import Cookies from "universal-cookie";
import UIkit from "uikit";
import ModalPortal from "../Components/Helpers/ModalPortal";
import { useTranslation } from 'react-i18next';

const SellerInbox = () => {
    const [searchTerm, setSearchTerm] = useState(false)
    const [inboxId, setInboxId] = useState(false)
    const [bookingId, setBookingId] = useState(false)
    const [userId, setUserId] = useState(false)
    const [userNumber, setUserNumber] = useState(false);
    const [userName, setUserName] = useState(null);
    const [latitude, setLatitude] = useState(false);
    const [longitude, setLongitude] = useState(false);

    const [userOtp, setUserOtp] = useState(false);
    const [sellerOtp, setSellerOtp] = useState(false);
    const [otpModal, setOtpModal] = useState(false);

    const timeoutRef = useRef(null);
    const [chats, setChats] = useState([]);
    const [chatHistory, setChatHistory] = useState(false)
    const messageInput = useRef(null);
    const cookies = new Cookies()
    const user_id = cookies.get("id");
    const reseller_id = cookies.get("reseller_id");

    var otp = "";
    const inputRefs = useRef([]);
    const bookingTag = useRef();
    const continueTag = useRef();
    const refreshTag = useRef();

    const [chatSettings, setChatSettings] = useState({
        reseller_id,
        limit: 100,
        offset: 0,
    })

    const navigate = useNavigate()

    const handleChange = (index, e) => {
        const value = e.target.value;
        const newOtp = otp.slice(0, index) + value + otp.slice(index + 1);
        otp = newOtp
        if (value.length > 0 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        const value = e.target.value;
        if (e.key === "Backspace" && value.length === 0 && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const [resellerImg, setResellerImg] = useState("/images/avatar.png");
    const [userImg, setUserImg] = useState("/images/avatar.png");

    const fetchResellerImg = async (userId) => {
        try {
            const resellerImgResponse = await axios.get(
                `${urls.serverUrl}${routes.getUserProfile}/${userId}`
            );

            if (resellerImgResponse.data && resellerImgResponse.data.user_details) {
                const updatedResellerImg = resellerImgResponse.data.user_details[0].photo;
                return updatedResellerImg;
            }
        } catch (error) {
            // Handle errors as needed
            console.error("Error fetching user image:", error.response);
            return null;
        }
    };

    const fetchData = async () => {
        try {
            const chatsResponse = await axios.get(urls.serverUrl + routes.inboxChat + `?reseller_id=${reseller_id}`);
            setChats(chatsResponse.data.data);
            console.log(chatsResponse.data.data);

            const specificInbox = chatsResponse.data.data.find((inbox) => inbox.inbox_id === inboxId);
            const updatedUserImg = specificInbox.uuser.user_photo;
            setUserImg(updatedUserImg);
            console.log("inboxChat seller");

            const updatedResellerImg = await fetchResellerImg(user_id);

            if (updatedResellerImg !== null) {
                setResellerImg(updatedResellerImg);
                console.log("resellerImg from new API:", updatedResellerImg);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const fetchDataAndSetResellerImg = async () => {
            await fetchData();
        };

        fetchDataAndSetResellerImg();
    }, [user_id, inboxId]);

    console.log("user_id ", user_id, " reseller_id ", reseller_id)

    useEffect(() => {   
        if (!otpModal) {
            fetchData();
            const fetchDataInterval = setInterval(() => {
                fetchData();
            }, otpModal ? 25000 : 5000);

            return () => clearInterval(fetchDataInterval);
        }
    }, [otpModal]);

    async function changeSearch(e) {
        setSearchTerm(e.target.value);

        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            fetchData(e.target.value);
        }, 5000);
    }

    useEffect(function () {
        fetchData()
    }, [])

    async function fetchChatHistory() {
        try {
            const history = await axios.get(urls.serverUrl + routes.chatHistory + `/${inboxId}?limit=10&offset=0`)
            setChatHistory(history.data.message)
            console.log("fetchChatHistory seller")
        } catch (err) {
            // console.log(err.data)
        }
    }

    useEffect(() => {
        if (!otpModal) {
            fetchChatHistory();
            const intervalId = setInterval(() => {
              fetchChatHistory();
            }, otpModal ? 25000 : 5000);

            return () => clearInterval(intervalId);
        }
    }, [otpModal, inboxId]);

    async function sendMessage(e) {
        e.preventDefault()
        const messageValue = messageInput.current.value

        if (messageValue.length > 0) {
            try {
                const send = await axios.post(urls.serverUrl + routes.messageChat, {
                    from: reseller_id,
                    to: userId,
                    message: messageValue,
                    inbox_id: inboxId
                })
                // setChatHistory([send.data.message, ...chatHistory])
                await fetchChatHistory()
                messageInput.current.value = ""
            } catch (error) {
                // console.log(error)
                messageInput.current.value = ""
            }
        }
    }
    let userNameKeshav;

    const openChat = (chat) => {
        console.log(chat)
        setInboxId(chat.inbox_id)
        setUserId(chat.uuser.user_id)
        setUserNumber(chat.uuser.userNumber);
        setUserName(chat.uuser.user_name)
        setLatitude(chat.uuser.latitude);
        setLongitude(chat.uuser.longitude);
        // console.log("this is", chat.uuser.userNumber);
        // console.log("this is the ", userNumber);
        userNameKeshav = chat.uuser.user_name;


        setBookingId(chat.booking_id)
        setSellerOtp(chat.booking.seller_otp)
        setUserOtp(chat.booking.user_otp)
        UIkit.offcanvas("#inbox-menu").hide();
    }

    const copyOtp = (event, otp) => {
        event.currentTarget.classList.add("uk-active")
        navigator.clipboard.writeText(otp);
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleTimeString([], { timeStyle: 'short' });
    }

    const formatLastSeenDateTime = (dateStr) => {
        const date = new Date(dateStr);
        const options = { dateStyle: "medium", timeStyle: "short" };
        return date.toLocaleString(undefined, { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });
    };

    const openInMaps = (latitude, longitude) => {
        const mapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(mapsUrl, '_blank');
    };

    console.log("otp", otp, otp!=="")
    console.log("sellerotp", sellerOtp)
    console.log("userOtp", userOtp)

    async function verifyOtp() {
        setOtpModal(false);
        if (otp.length == 4) {
            try {
                const verifycation = await axios.post(urls.serverUrl + routes.customerOtpVerification + `/${bookingId}`, {
                    user_otp: otp,
                })
                if (verifycation.data.status === 200) {
                    inputRefs.current.forEach((input) => {
                        input.value = ""
                    })
                    bookingTag.current.innerHTML = "OTP Verified Successfully"
                    continueTag.current.disabled = true
                    otp = ""
                    var refreshTime = 3
                    setInterval(function () {
                        refreshTag.current.innerHTML = `Refreshing in ${refreshTime}`
                        refreshTime--
                        if (refreshTime === 0) {
                            navigate(0)
                        }
                    }, 1000)
                }
            } catch (err) {
                // console.log(err)
            }
        }
    }

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const handleIconClick = () => {
        navigator.clipboard.writeText(userNumber);
        setTooltipVisible(true);

        setTimeout(() => {
          setTooltipVisible(false);
        }, 2000);
    };

    const openOtpModal = () => {
        console.log("modal opened")
        setOtpModal(true);
    };

    const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return (
        <>
            <div className="uk-section-inbox chat-screen uk-width-1-1">
                <div className="uk-height-1-1 uk-width-1-1">
                    <div className="uk-grid uk-grid-inbox uk-height-1-1 uk-width-1-1" data-uk-grid>
                        <div className="uk-width-2-5@m uk-overflow-hidden">
                            <div className="uk-section-otp uk-flex uk-flex-between uk-flex-middle uk-hidden@m">
                                <div>
                                    <a href="#inbox-menu" data-uk-toggle="" className="uk-hamburger uk-style-tertiary uk-hidden@m uk-text-center uk-margin-left" aria-expanded="false">
                                        <span className="uk-navbar-toggle-icon">
                                            <svg className="" width="26" height="20" viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="0" width="26" height="2"></rect>
                                                <rect y="9" width="26" height="2"></rect>
                                                <rect y="18" width="26" height="2"></rect>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                {!(userOtp && sellerOtp) ? (
                                    <div>
                                        <button data-uk-toggle="target: #otp-verify" style={{ boxShadow: "none" }} className="uk-button uk-button-black uk-button-rounded uk-flex-inline uk-flex-center uk-flex-middle" onClick={openOtpModal}>
                                            <ReactSVG src={verify} className="uk-margin-small-right" />
                                            {t('otpSection')}
                                        </button>
                                    </div>
                                ) : ("")}
                            </div>
                            <div className="uk-container uk-padding-horizontal-remove" style={{paddingLeft: '60px', paddingRight: '30px'}}>
                                <div className="uk-visible@m uk-height-1-1">
                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                        <div>
                                            <p className="uk-text-4 uk-text-fw-600">{t('inbox')}</p>
                                        </div>
                                        <div>
                                            <div className="uk-input-search">
                                                <ReactSVG src={search} />
                                                <input
                                                    className="uk-input"
                                                    placeholder={t('search')}
                                                    onChange={changeSearch}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div data-uk-slider className="uk-overflow-visible uk-margin-medium-top">
                                        <div className="uk-grid uk-slider-items uk-flex uk-flex-row uk-flex-center" data-uk-grid>
                                            <div>
                                                <NavLink to="/seller/inbox" className="uk-button uk-button-primary uk-button-rounded uk-text-default"
                                                     style={{ padding: '8px 16px' }} >
                                                    {t('messages')}
                                                 </NavLink>
                                            </div>
                                            <div>
                                                <NavLink to="/seller/inbox/notifications" className="uk-button uk-button-white uk-button-rounded uk-text-default"
                                                     style={{ padding: '8px 16px' }} >
                                                    {t('notifications')}
                                                 </NavLink>
                                            </div>
                                            <div>
                                                <NavLink to="/seller/inbox/ratings" className="uk-button uk-button-white uk-button-rounded uk-text-default"
                                                     style={{ padding: '8px 16px' }} >
                                                    {t('ratings')}
                                                 </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="uk-list uk-list-chats">
                                        {chats.map((chat, index) => (
                                            <li key={index} onClick={(e) => { openChat(chat) }}>
                                                <div className="uk-grid uk-grid-chat uk-flex uk-flex-middle" data-uk-grid>
                                                    <div className="uk-flex uk-margin-small-top">
                                                        <img src={chat.uuser.user_photo ?? `/images/avatar.png`} />
                                                    </div>
                                                    <div className="uk-width-expand">
                                                        <p>
                                                            <strong>{chat.uuser.user_name ?? "John Doe"}</strong>
                                                            {chat.uuser.user_online_status ==
                                                                0 ? (
                                                                    <span
                                                                        style={{
                                                                            color: "green",
                                                                            margin: "5px",
                                                                            fontSize: "34px",
                                                                        }}>
                                                                        &#x2022;
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            color: "grey",
                                                                            margin: "5px",
                                                                            fontSize: "34px",
                                                                        }}>
                                                                        &#x2022;
                                                                    </span>
                                                                )}
                                                            </p>
                                                        <p className="uk-text-muted">
                                                            {chat.chat_message_2[0].last_message.substring(0, 30)}..
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-3-5@m uk-margin-remove uk-padding-left-remove uk-position-relative uk-align-center">
                            {chatHistory.length > 0 ? (
                                <>
                                    <div className="uk-section-inbox uk-width-expand">
                                        <div className="uk-section-otp uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-visible@m">
                                            {/*<div>
                                                <a href="#inbox-menu" data-uk-toggle="" className="uk-hamburger uk-style-tertiary uk-hidden@m uk-text-center uk-margin-large-left" aria-expanded="false">
                                                    <span className="uk-navbar-toggle-icon">
                                                        <svg className="" width="26" height="20" viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="0" width="26" height="2"></rect>
                                                            <rect y="9" width="26" height="2"></rect>
                                                            <rect y="18" width="26" height="2"></rect>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>*/}
                                            <div className="uk-height-small uk-margin-medium-top uk-margin-medium-right uk-flex-inline uk-flex-center uk-flex-column" style={{ fontSize: "10px" }}>
                                                <p className="uk-text-bolder uk-text-default">
                                                  {userName}
                                                </p>
                                                <div className="uk-margin-medium-right uk-flex-inline uk-flex-center uk-flex-middle uk-flex" style={{ fontSize: "10px" }}>
                                                    <p>
                                                      {t('lastSeenOn')} {chatHistory.length > 0 ? formatLastSeenDateTime(chatHistory[0].last_activity_timestamp) : ''}
                                                    </p>
                                                    <div className="uk-margin-large-left uk-flex">
                                                      <div onClick={handleIconClick}>
                                                        <ReactSVG
                                                          src={call}
                                                          style={{width: "20px", marginRight: "8px", cursor: "pointer" }}
                                                        />
                                                      </div>
                                                      {tooltipVisible && <span className="tooltip">Number copied!</span>}
                                                        {latitude && longitude &&
                                                            <ReactSVG
                                                            src={mapmarker}
                                                            onClick={(e) => {
                                                                openInMaps(latitude, longitude);
                                                            }}
                                                            style={{width:"15px", cursor: "pointer"}}
                                                            className="uk-margin-medium-left uk-flex" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {!(userOtp && sellerOtp) ? (
                                                <div>
                                                    <button data-uk-toggle="target: #otp-verify" className="uk-button uk-button-black uk-button-rounded uk-flex-inline uk-flex-center uk-flex-middle uk-margin-large-right" onClick={openOtpModal}
                                                        style={{fontSize: "12px"}}>
                                                        <ReactSVG src={verify} className="uk-margin-small-right" />
                                                        {t('otpSection')}
                                                    </button>
                                                </div>
                                            ) : ("")}
                                        </div>
                                        <div className="uk-section-chats uk-margin-small-top@m uk-width-auto">
                                            <ul className="uk-list uk-list-messages">
                                                {sellerOtp !== null ? (
                                                    <>
                                                        <li className="generated otp">
                                                            <p>Hi, here is your OTP <strong>{sellerOtp}</strong> to proceed with your booking.</p>
                                                            <hr />
                                                            <div className="otp-copy uk-flex uk-flex-middle">
                                                                <strong className="uk-text-5-xsmall uk-">{sellerOtp}</strong>
                                                                <ReactSVG className="uk-margin-left uk-icon" onClick={(e) => { copyOtp(e, sellerOtp) }} src={copy} />
                                                                <p className={"uk-text-success uk-text-bold uk-margin-small-left"} style={{ cursor: 'pointer' }} onClick={(e) => { copyOtp(e, sellerOtp) }}>SHARE OTP</p>
                                                            </div>
                                                        </li>
                                                    </>
                                                ) : (
                                                    <li className="generated">
                                                        <p>
                                                            <strong>Buyer needs to enter OTP</strong>
                                                        </p>
                                                    </li>
                                                )}
                                                {chatHistory.toReversed().map((chat, index) => (
                                                    chat.visibility === 0 ? (
                                                        <li key={index} className={`${parseInt(chat.from) === userId ? "recipient" : "sender"} uk-flex`}>
                                                            <div>
                                                                {chat.from == `${userId}` && 
                                                                    <img
                                                                        src={userImg}
                                                                        className="uk-flex uk-margin-auto-vertical uk-margin-remove-left uk-margin-medium-right"
                                                                        alt="User Avatar" 
                                                                        style={{
                                                                            width: '40px',
                                                                            height: '40px',
                                                                            borderRadius: '50%',
                                                                        }}
                                                                    />
                                                                }    
                                                            </div>
                                                            <div >
                                                                <p>{chat.message}</p>
                                                                <small className="uk-display-block uk-margin-xsmall-top uk-text-right">
                                                                    {formatDate(chat.creation_dt)}
                                                                </small>
                                                            </div>
                                                            <div>
                                                                {chat.from === `${reseller_id}` && 
                                                                    <img
                                                                        src={resellerImg}
                                                                        className="uk-flex uk-margin-auto-vertical uk-margin-remove-right uk-margin-medium-left"
                                                                        alt="User Avatar" 
                                                                        style={{
                                                                            width: '40px',
                                                                            height: '40px',
                                                                            borderRadius: '50%',
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        </li>
                                                    ) : chat.visibility === 2 ? (
                                                        <li key={index} className="generated">
                                                            <div className="uk-flex uk-flex-row uk-align-center uk-margin-remove">
                                                                <img
                                                                    src={companyLogo}
                                                                    className="uk-flex uk-margin-auto-vertical uk-margin-remove-left uk-margin-medium-right"
                                                                    alt="User Avatar" 
                                                                    style={{
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        borderRadius: '50%',
                                                                    }}
                                                                />
                                                                <p className="uk-text-left">{chat.message}</p>
                                                            </div>
                                                            <small className="uk-display-block uk-margin-xsmall-top uk-text-right">
                                                                {formatDate(chat.creation_dt)}
                                                            </small>
                                                        </li>
                                                    ) : ("")
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="uk-padding-small uk-section-message uk-background-white">
                                        <form className="uk-flex uk-flex-between">
                                            <input ref={messageInput} className="uk-input" placeholder="Enter Your Message" />
                                            <button
                                                type="button"
                                                onClick={sendMessage}
                                                className="uk-button uk-button-default uk-text-success uk-padding-small ">
                                                <ReactSVG src={paperplane}/>
                                            </button>
                                        </form>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="uk-text-center">{t('noMessagesYet')}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div id="inbox-menu" data-uk-offcanvas="overlay: true">
                    <div className="uk-offcanvas-bar mobile-menu">
                        <button
                            className="uk-offcanvas-close uk-color-primary"
                            type="button"
                            data-uk-close></button>
                        <ul className="uk-list uk-margin-large-top">
                            <li>
                                <NavLink to="/inbox" className="uk-color-primary">{t('messages')}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/seller/inbox/notifications" className="uk-color-primary">{t('notifications')}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/inbox/ratings" className="uk-color-primary">{t('ratings')}</NavLink>
                            </li>
                        </ul>
                        <ul className="uk-list uk-list-chats uk-margin-large-top">
                            {chats.map((chat, index) => (
                                <li key={index} onClick={(e) => { openChat(chat) }}>
                                    <div className="uk-grid uk-grid-chat uk-flex-top" data-uk-grid>
                                        <div>
                                            <img src={chat.uuser.user_photo ?? `/images/avatar.png`} />
                                        </div>
                                        <div className="uk-width-expand">
                                            {chat.user_name ? (
                                                <p>
                                                    <strong>{chat.uuser.user_name ?? "John Doe"}</strong>
                                                </p>
                                            ) : ("")}
                                            <p className="uk-text-muted">
                                                {chat.chat_message_2[0].last_message.substring(0, 50)}...
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <ModalPortal className="uk-flex-top"
                    id="otp-verify">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button
                            className="uk-modal-close-default"
                            type="button"
                            data-uk-close onClick={() => setOtpModal(false)}></button>
                        <p className="uk-text-bold uk-text-center uk-text-uppercase">Verify OTP</p>
                        <p className="uk-text-center uk-text-9 uk-text-light uk-margin-top">Enter OTP delivered from the seller side.</p>

                        <div className="uk-width-1-2@m uk-align-center">
                            <div
                                className="uk-grid uk-child-width-1-4 uk-grid-otp uk-margin-top"
                                data-uk-grid>
                                {[0, 1, 2, 3].map((index) => (
                                    <div key={index}>
                                        <input
                                            key={index}
                                            className="uk-width-1-1"
                                            type="text"
                                            maxLength={1}
                                            onChange={(e) => handleChange(index, e)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            ref={(ref) => (inputRefs.current[index] = ref)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-text-center">
                            <button
                                className="uk-button uk-button-primary uk-button-small uk-margin-left"
                                onClick={verifyOtp} ref={continueTag}>
                                Continue
                            </button>
                            <p
                                className="uk-color-green uk-margin-top"
                                ref={bookingTag}></p>
                            <p ref={refreshTag}></p>
                        </div>
                    </div>
                </ModalPortal>
            </div>
        </>
    );
}

export default SellerInbox;
