import React, { useState } from "react";

function Filters(props) {
	const [categories, setCategories] = useState(props.categories);

	function handleClick(cat_id) {
		const newCatId = cat_id === props.active ? "" : cat_id;

		props.changeCategory(newCatId);
	}
	return (
		<>
			<div
				className="uk-section uk-section-filters"
				data-uk-slider="finite: true; autoplay: true;">
				<div className="uk-container uk-flex uk-flex-middle">
					{/* <ReactSVG src={explore} /> */}
					<div className="uk-flex uk-width-expand uk-slider-items" data-uk-grid>
						{categories.map((category, index) => (
							<div
								key={index}
								onClick={() => {
									handleClick(category.category_id);
								}}
								style={{ cursor: "pointer" }}>
								<button
									style={{
										userSelect: "none",
										pointerEvents: "none",
									}}
									className={`uk-button uk-button-white uk-button-rounded ${
										props.active === category.category_id ? "active" : ""
									}`}>
									{category.category_name}
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export default Filters;
