import { React, useState, useEffect } from "react";
import axios from "axios";
import { urls, routes } from "../../Global";
import { useNavigate, Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg";
import Alert from "../../Components/Helpers/Alert";
import Cookies from "universal-cookie";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlacesSearch from "../../Components/PlaceSearch/PlacesSearch";
import Guide from "../Guide";
import { useTranslation } from 'react-i18next';


function Category() {
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
 
	const [responseMessage, setResponseMessage] = useState({
		type: "",
		message: "",
	});

	const cookies = new Cookies();

	// const { isLoaded } = useJsApiLoader({
	// 	id: "google-map-script",
	// 	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	// });

	const [clickedLocation, setClickedLocation] = useState(null);
	const [currentLocation, setCurrentLocation] = useState(null);

	const mapStyles = {
		height: "400px",
		width: "100%",
	};

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setCurrentLocation({ lat: latitude, lng: longitude });
				},
				(error) => {
					console.error("Error getting user's location:", error);
				}
			);
		}
	}, []);

	const handleMapClick = (event) => {
		setClickedLocation({
			lat: event.latLng.lat(),
			lng: event.latLng.lng(),
		});
	};

	function goBack() {
		navigate(-1);
	}

	async function fetchCategory() {
		try {
			const filters = await axios.get(urls.serverUrl + routes.resources);
			setCategories(filters.data.items_details.category_details);
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(function () {
		fetchCategory();
	}, []);

	function save(e) {
		e.preventDefault();
		const formData = new FormData(e.target);
		const product = {};
		if (!clickedLocation) {
			setResponseMessage({
				type:"danger",
				message:"Please enter location"
			})
			return;
		}
		product.latitude = clickedLocation.lat;
		product.longitude = clickedLocation.lng;
		product.address = clickedLocation.address;
		
		formData.forEach((value, name) => {
			product[name] = value;
		});

		navigate("/seller/add-product/details", {
			state: { product },
		});
	}

	const storedLanguage = localStorage.getItem('language');
	console.log(storedLanguage);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (storedLanguage) {
		    i18n.changeLanguage(storedLanguage);
		} else {
		    i18n.changeLanguage('en');
		}
	}, [storedLanguage, i18n]);

	return (
		<>
			<div
				className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
					<img
						src="/images/side_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
				</div>
				<div className="uk-flex uk-flex-center uk-flex-middle">
					<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div className="uk-flex">
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left uk-icon-black"
									/>
								</button>
								<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
									{t('listProduct')}
								</h1>
							</div>
							<Link to="../Guide">
						        <ReactSVG src={info} className="uk-icon-info" />
					      	</Link>
						</div>
						<form
							onSubmit={save}
							style={{ marginTop: "50px" }}
							className="uk-grid-small uk-child-width-1-1"
							data-uk-grid>
							<div>
								<label className="uk-form-label" htmlFor="gender">
									{t('gender')}
								</label>
								<div className="uk-form-controls">
									<ul className="uk-list uk-list-sort">
										<li>
											<select name="gender" id="gender" className="uk-select" required>
												<option value="0">Male</option>
												<option value="1">Female</option>
											</select>
										</li>
									</ul>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="phone">
									{t('category')}
								</label>
								<div className="uk-form-controls">
									<ul className="uk-list uk-list-sort">
										<li>
											<select name="category_id" className="uk-select" required>
												<option value="">Select...</option>
												{categories.map((category, index) => (
													<option key={index} value={category.category_id}>
														{category.category_name}
													</option>
												))}
											</select>
										</li>
									</ul>
								</div>
							</div>
							<div>
								<label className="uk-form-label" htmlFor="product_location">
									{t('locationDetails')}
								</label>
								<PlacesSearch
									id="product_location"
									onSelect={setClickedLocation}
								/>
								{/* <div className="uk-form-controls">
                                    <textarea name="product_location" id="product_location" className="uk-textarea" placeholder="Enter detailed product address with house no., street, pincode and landmark..." rows={4} />
                                </div> */}
								{/* {isLoaded ? (
									<GoogleMap
										mapContainerStyle={mapStyles}
										zoom={10}
										center={currentLocation || { lat: 0, lng: 0 }}
										onClick={handleMapClick}>
										{clickedLocation && (
											<Marker
												position={{
													lat: clickedLocation.lat,
													lng: clickedLocation.lng,
												}}
											/>
										)}
									</GoogleMap>
								) : (
									<></>
								)} */}
							</div>
							<Alert
								message={responseMessage}
								reset={function () {
									setResponseMessage({
										type: false,
										message: false,
									});
								}}
							/>
							<div>
								<button
									type="submit"
									name="continue"
									className="uk-button uk-button-primary">
									{t('continue')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Category;
