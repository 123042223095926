import React, { useState, useEffect } from "react";
import Header from "../Helpers/Header";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import { routes, urls } from "../../Global";
import Cookies from "universal-cookie";
import axios from "axios";
import Alert from "../Helpers/Alert";
import { useTranslation } from 'react-i18next';


function Feedback(props) {
  const storedLanguage = localStorage.getItem('language');
  // console.log(storedLanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      } else {
        i18n.changeLanguage('en');
      }
  }, [storedLanguage, i18n]);
  
  const navigate = useNavigate();
  const [selectedRating, setSelectedRating] = useState(null);
  const [feedback, setFeedback] = useState("");
  const cookies = new Cookies();
  const id = cookies.get("id")
  const [responseMessage, setResponseMessage] = useState({
    type: "",
    message: "",
  });

  function goBack() {
    navigate(-1);
  }

  const handleItemClick = (rating) => {
    setSelectedRating(rating);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const isButtonDisabled = !selectedRating || feedback === "";

  async function handleSubmit() {
    try {
    
      const submit = await axios.post(urls.serverUrl + routes.userFeedback + `/${id}`, {
        rating: selectedRating,
        feedback_desc: feedback
      })
      console.log(submit)
      setResponseMessage({
        type: "success",
        message: "Thank you for your review"
      })
      setSelectedRating(null)
      setFeedback("")
      setTimeout(() => {
        navigate('/');
      }, 3000)
    } catch (err) {
      console.log(err)
      setResponseMessage({
        type: "danger",
        message: "Your review could not be added!"
      })
    }
  };

  return (
    <>
      <Header />
      <div className="uk-section uk-section-feedback">
        <div className="uk-container uk-container-feedback">
          <div className="uk-flex uk-flex-middle">
            <button onClick={goBack} className="uk-button-noeffect">
              <ReactSVG
                src={arrowLeft}
                className="uk-icon-left uk-icon-black"
              />
            </button>
            <p className="uk-text-4 uk-text-fw-600 uk-margin-left">{t('feedback')}</p>
          </div>
          <p className="uk-text-6-big">{t('rateUs')}</p>
          <ul className="uk-list uk-list-feedback">
            {[1, 2, 3, 4, 5].map((rating) => (
              <li
                key={rating}
                onClick={() => handleItemClick(rating)}
                className={selectedRating && rating <= selectedRating ? "selected" : ""}
              >
                {rating}
              </li>
            ))}
          </ul>
          <div className="uk-flex uk-flex-between uk-text-muted uk-text-6">
            <p>{t('notLikelyAtAll')}</p>
            <p>{t('extremelyLikely')}</p>
          </div>
          <p
            className="uk-text-6-big uk-text-fw-600"
            style={{ maxWidth: "422px" }}>
            {t('facingAnyIssue')}
          </p>
          <textarea
            className="uk-textarea uk-text-6"
            rows="3"
            value={feedback}
            onChange={handleFeedbackChange}
            placeholder={t('writeSomethingHere')}
          />
          <Alert
            message={responseMessage}
            className="uk-margin-top"
            reset={function () {
              setResponseMessage({
                type: false,
                message: false,
              });
            }}
          />
          <div className="uk-text-right" style={{ marginTop: "28px" }}>
            <button className="uk-button uk-button-primary" disabled={isButtonDisabled} onClick={handleSubmit}>
              {t('submit')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedback;
