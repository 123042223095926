import { React, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import info from "../../assets/svg_icons/info-light.svg";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import ProductContext from "../../context/addProductContext";
import Alert from "../../Components/Helpers/Alert";
import { useTranslation } from 'react-i18next';


function Sizes() {
	const storedLanguage = localStorage.getItem('language');
    console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    const { resources } = useContext(ProductContext)

    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState({
			type: "",
			message: "",
		});
    const [shoulderSize, setShoulderSize] = useState({
        min: 20,
        max: 30
    })

    const [chestSize, setChestSize] = useState({
        min: 34,
        max: 56
    })

    const [waistSize, setWaistSize] = useState({
        min: 32,
        max: 50
    })

    const [thighSize, setThighSize] = useState({
        min: 20,
        max: 30
    })

    const location = useLocation();

    function goBack() {
        navigate(-1);
    }

    if (!resources) return;

    const product = location.state.product;

    function save(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        formData.forEach((value, name) => {
            product[name] = value;
        });
        product["shoulder_size_low"] = shoulderSize.min
        product["shoulder_size_high"] = shoulderSize.max

        product["chest_size_low"] = chestSize.min
        product["chest_size_high"] = chestSize.max

        product["waist_size_low"] = waistSize.min
        product["waist_size_high"] = waistSize.max

        product["thighs_size_low"] = thighSize.min
        product["thighs_size_high"] = thighSize.max

        if (!product.bodytype){
            setResponseMessage({
                type: "danger",
                message: "Please select body type",
            });
            return;
        }
        navigate("/seller/add-product/media", {
            state: { product },
        });
    }

	const handleClick = () => {
		navigate("/seller/Guide");
	};

    return (
			<>
				<div
					className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
					data-uk-grid>
					<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
						<img
							src="/images/side_banner.png"
							alt="Side Banner"
							className="uk-width-side-banner"
						/>
					</div>
					<div className="uk-flex uk-flex-center uk-flex-middle">
						<div className="uk-container uk-container-signup-form uk-section uk-width-1-1">
							<div className="uk-flex uk-flex-between uk-flex-middle">
								<div className="uk-flex">
									<button onClick={goBack} className="uk-button-noeffect">
										<ReactSVG
											src={arrowLeft}
											className="uk-icon-left uk-icon-black"
										/>
									</button>
									<h1 className="uk-text-5 uk-margin-xsmall-left uk-text-fw-600">
										{t('listProduct')}
									</h1>
								</div>
								<ReactSVG src={info} className="uk-icon-info" onClick={handleClick} />
							</div>
							<form
								onSubmit={save}
								style={{ marginTop: "50px" }}
								className="uk-grid-small uk-child-width-1-1"
								data-uk-grid>
								<div>
									<label className="uk-form-label" htmlFor="shoulder-size">
										{t('shoulderSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={20}
											maxValue={30}
											value={shoulderSize}
											onChange={(value) => {
												setShoulderSize(value);
											}}
										/>
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="chest-size">
										{t('chestSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={34}
											maxValue={56}
											value={chestSize}
											onChange={(value) => {
												setChestSize(value);
											}}
										/>
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="phone">
										{t('waistSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={32}
											maxValue={50}
											value={waistSize}
											onChange={(value) => {
												setWaistSize(value);
											}}
										/>
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="phone">
										{t('thighsSize')}
									</label>
									<div className="uk-form-controls uk-margin-top">
										<InputRange
											minValue={20}
											maxValue={30}
											value={thighSize}
											onChange={(value) => {
												setThighSize(value);
											}}
										/>
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<label className="uk-form-label" htmlFor="bodytype">
										{t('bodyType')}
									</label>
									<div
										className="uk-form-controls uk-slider-size-picker"
										data-uk-slider>
										<div className="uk-slider-container">
											<ul className="uk-slider-items uk-child-width-1-4">
												{product.gender == 0 ? (
													<>
														{resources.bodytype_for_man.map((size, index) => (
															<li key={index}>
																<input
																	id={size.bodytype_name}
																	type="radio"
																	name="bodytype"
																	value={size.bodytype_id}
																/>
																<label htmlFor={size.bodytype_name}>
																	<img src={`${size.body_type_image}`} />
																</label>
															</li>
														))}
													</>
												) : (
													<>
														{resources.bodytype_for_woman.map((size, index) => (
															<li key={index}>
																<input
																	id={size.bodytype_name}
																	type="radio"
																	name="bodytype"
																	value={size.bodytype_id}
																/>
																<label htmlFor={size.bodytype_name}>
																	<img src={`${size.body_type_image}`} />
																</label>
															</li>
														))}
													</>
												)}
											</ul>
										</div>
									</div>
								</div>
								<Alert
									message={responseMessage}
									reset={function () {
										setResponseMessage({
											type: false,
											message: false,
										});
									}}
								/>
								<div>
									<button
										type="submit"
										name="continue"
										className="uk-button uk-button-primary">
										{t('continue')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
}

export default Sizes;
