import React from "react";

function Alert(props) {
  function closeAlert() {
    props.reset();
  }

  return (
    <>
      {props.message.message ? (
        <div>
          <div className={`uk-alert-${props.message.type} ${props.className}`} data-uk-alert>
            <a
              className="uk-alert-close"
              onClick={closeAlert}
              data-uk-close></a>
            <p>{props.message.message}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Alert;
