import React, {useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Cookies from "universal-cookie";
import arrowLeft from "../../assets/svg_icons/arrow-left.svg";
import shield from "../../assets/svg_icons/shield.svg";
import chevronRight from "../../assets/svg_icons/chevron-right.svg";
import instagram from "../../assets/svg_icons/instagram.svg";
import facebook from "../../assets/svg_icons/facebook.svg";
import checkboxActive from "../../assets/svg_icons/checkbox-active.svg";
import checkboxInactive from "../../assets/svg_icons/checkbox-inactive.svg";
import closeAccount from "../../assets/svg_icons/close-account.svg";
import axios from "axios";
import { routes, urls } from "../../Global";
import { useTranslation } from 'react-i18next';


function Security() {

	const storedLanguage = localStorage.getItem('language');
	//console.log(storedLanguage);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (storedLanguage) {
			i18n.changeLanguage(storedLanguage);
		} else {
			i18n.changeLanguage('en');
		}	
	}, [storedLanguage, i18n]);

  const navigate = useNavigate();
  const [deactivate, setDeactivate] = useState(false);

  const cookies = new Cookies();
  const id = cookies.get("id")

  function goBack() {
    navigate(-1);
  }

  async function deactivateAccount() {
    try {
      if (!window.confirm("Are you sure you want to deactivate your account?")) {
				return false;
			}
      const deactivate = await axios.delete(urls.serverUrl + routes.deactivateAccount + `${id}`)
      navigate("/logout/")
    } catch (err) {
      console.log(err)
    }
  }

  return (
		<>
			<div
				className="uk-section-signin uk-section-profile uk-grid uk-child-width-1-2@m uk-height-1-1"
				data-uk-grid>
				<div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center uk-flex-column">
					<img
						src="/images/profile_banner.png"
						alt="Side Banner"
						className="uk-width-side-banner"
					/>
					<p className="uk-margin-large-top uk-text-4 uk-width-large uk-text-center uk-text-fw-600">
						Lorem ipsum dolor sit amet consectetur.
					</p>
				</div>
				<div className="uk-flex uk-flex-center">
					<div className="uk-container uk-container-signup-form uk-section-large">
						<div className="uk-flex uk-flex-between uk-flex-middle">
							<div className="uk-flex uk-flex-middle">
								<button onClick={goBack} className="uk-button-noeffect">
									<ReactSVG
										src={arrowLeft}
										className="uk-icon-left-small uk-icon-black"
									/>
								</button>
								<p className="uk-text-5-small uk-text-fw-600 uk-margin-left">
									{t('loginAndSecurity')}
								</p>
							</div>
						</div>
						<div className="uk-list-border">
							{/*<div className="uk-flex uk-flex-between uk-flex-middle">
								<p className="uk-color-green uk-text-6 uk-text-fw-500">
									Reset Password
								</p>
								<ReactSVG className="uk-icon-chevron" src={chevronRight} />
							</div>
							<div>
								<p className="uk-text-6 uk-text-fw-500">Social Accounts</p>
								<ul className="uk-list uk-list-socials">
									<li>
										<ReactSVG src={instagram} />
										<span>INSTAGRAM</span>
										<ReactSVG
											src={checkboxActive}
											className="uk-width-expand uk-text-right uk-cb-active"
										/>
									</li>
									<li>
										<ReactSVG src={facebook} />
										<span>FACEBOOK</span>
										<ReactSVG
											src={checkboxInactive}
											className="uk-width-expand uk-text-right uk-cb-inactive"
										/>
									</li>
								</ul>
							</div>*/}
							
							<Link
								to="/logout"
								className="uk-margin-top uk-flex uk-flex-between uk-flex-middle uk-cursor-pointer">
								<div className="uk-flex">
									<ReactSVG src={shield} />
									<p style={{color:"black"}} className="uk-color-gray uk-text-6 uk-text-fw-500 uk-margin-left">
										{t('logOut')}
									</p>
								</div>
								<ReactSVG className="uk-icon-chevron" src={chevronRight} />
							</Link>
							<div
								className="uk-flex uk-flex-between uk-flex-middle uk-cursor-pointer"
								onClick={(e) => setDeactivate(!deactivate)}>
								<div className="uk-flex">
									<ReactSVG src={closeAccount} />
									<p className="uk-color-red uk-text-6 uk-text-fw-500 uk-margin-left">
										{t('deactivateAccount')}
									</p>
								</div>
								<ReactSVG className="uk-icon-chevron" src={chevronRight} />
							</div>
						</div>
						{deactivate ? (
							<>
								<div className="uk-card uk-card-deactivate">
									<div className="uk-card-body">
										{/* <p className="uk-text-fw-600 uk-text-8-big">
                      To Deactivate Account type your account password to
                      proceed
                    </p>
                    <div className="uk-margin-medium-top">
                      <label className="uk-form-label" htmlFor="password">
                        Enter Password
                      </label>
                      <div className="uk-form-controls">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="uk-input"
                        />
                      </div>
                    </div> */}
										<div className="uk-margin-top-">
											<button
												className="uk-button uk-button-black-bg"
												onClick={deactivateAccount}>
												{t('deactivateAccount')}
											</button>
										</div>
									</div>
								</div>
							</>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default Security;
