import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';

export const ProductShare=({url,isOpen,handleClose})=> {
  const [isCopied,setIsCopied]=useState(false)
  useEffect(()=>{
    if(isCopied){
        setInterval(() => {
            setIsCopied(false)
        }, 2000);
    }
  },[isCopied])

  const copyToClipboard = async() => {
    await navigator.clipboard.writeText(url);
    setIsCopied(true)
  };

  const handleWhatsAppShare=()=>{
    const message = `Check out this product: ${url}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
    window.open(whatsappUrl);
  }

  const handleFacebookShare=()=>{
    const encodedProductLink = encodeURIComponent(url);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedProductLink}`;
    window.open(facebookUrl);
  }

  const handleInstagramShare=()=>{
    const encodedProductLink = encodeURIComponent(url);
    const instagramUrl = `https://www.instagram.com/sharelink/?url=${encodedProductLink}`;
    window.open(instagramUrl);
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Share Product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Share this product with your friends:
          </DialogContentText>

          <Stack m={"1rem 0"} flexDirection={'row'} columnGap={2}>
          <i onClick={handleWhatsAppShare} style={{cursor:"pointer",fontSize:"3rem",backgroundColor:"#25D366",color:"white",borderRadius:"50%",boxShadow:"0 0 1rem 1px"}} class='bx bxl-whatsapp'></i>
          <i onClick={handleFacebookShare} style={{cursor:"pointer",fontSize:"3rem",backgroundColor:"#1877F2",color:"white",borderRadius:"50%", }} class='bx bxl-facebook'></i>
          <i onClick={handleInstagramShare} style={{cursor:"pointer",fontSize:"3rem",color:"white",borderRadius:"50%",background: "linear-gradient(135deg, #833ab4, #fd1d1d, #fcb045)"}} class='bx bxl-instagram' ></i>
          </Stack>


          <DialogContentText>
            Product Link:
            <a style={{wordBreak:"break-word"}} href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
            <IconButton
              aria-label="Copy Link"
              onClick={copyToClipboard}
              color="primary"
            >
                {
                    isCopied?<CheckCircleIcon/>:<FileCopyIcon/>
                }
            </IconButton>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

