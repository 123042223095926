import axios from "axios";
import React, { useEffect, useState } from "react";
import { routes, urls } from "../Global";
import GuideItem from "./GuideItem";
import { useTranslation } from 'react-i18next';

function Guide() {
    const [guides, setGuides] = useState(false)
    useEffect(function () {
        async function fetchGuides() {
            try {
                console.log("inside guide")
                const guides = await axios.get(urls.serverUrl + routes.productGuides + `?limit=1000`)
                console.log(guides.data.products)
                setGuides(guides.data.products)
            } catch (err) {
                console.log("Failed", err);
            }
        }
        fetchGuides();
    }, [])

    const storedLanguage = localStorage.getItem('language');
    // console.log(storedLanguage);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        } else {
            i18n.changeLanguage('en');
        }
    }, [storedLanguage, i18n]);

    return (
        <div className="uk-section">
            <div className="uk-container">
                <h1 className="uk-text-5">{t('productGuide')}</h1>
                {guides ? (
                    <div data-uk-grid className="uk-grid uk-grid-guides uk-child-width-1-3@m uk-child-width-1-2@s" data-uk-lightbox>
                        {guides.map((guide, index) => (
                            <GuideItem key={index} guide={guide} />
                        ))}
                    </div>
                ) : ("")}
            </div>
        </div>
    );
}

export default Guide;
