import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import AuthContext from "./context/authContext";
import LogoutBtn from "./Components/Authentication/Logout";
import Signin from "./Components/Signin/Signin";
import Home from "./Components/Home/Home";
import Product from "./Components/Product/Product";
import axios from "axios";
import Cookies from "universal-cookie";
import Terms from "./pages/terms";
import Login from "./Components/Authentication/Login";
import Wallet from "./Components/Content/Wallet";
import Notifications from "./Components/Content/Notifications";
import Booking from "./Components/Content/Booking";
import Wishlist from "./Components/Content/Wishlist";
import Bookings from "./Components/Content/Bookings";
import Feedback from "./Components/Content/Feedback";
import RaiseComplaint from "./Components/Content/RaiseComplaint";
import OTPVerification from "./Components/Authentication/Otp-confirmation";
import ResetPassword from "./Components/Authentication/ResetPassword";
import Profile from "./Components/Content/Profile";
import CompleteKYC from "./Components/Content/CompleteKYC";
import Security from "./Components/Content/Security";
import Payouts from "./Components/Content/Payouts";
import Ratings from "./Components/Content/Ratings";
import Payment from "./Components/Content/Payment";
import PaymentConfirmation from "./Components/Content/PaymentConfirm";
import Inbox from "./Components/Content/Inbox";
import Seller from "./Seller/Seller";
import Guide from "./Seller/Guide";
import Header from "./Seller/Header";
import SellerInbox from "./Seller/Inbox";
import SellerNotification from "./Seller/Notification";
import SellerRatings from "./Seller/Ratings";
import SellerBookings from "./Seller/Bookings";
import Category from "./Seller/AddProductScreens/Category";
import Details from "./Seller/AddProductScreens/Details";
import Sizes from "./Seller/AddProductScreens/Sizes";
import Media from "./Seller/AddProductScreens/Media";
import Price from "./Seller/AddProductScreens/Price";
import Add from "./Seller/AddProductScreens/add";
import AddProduct from "./Seller/AddProduct";
import EditProduct from "./Seller/EditProduct";
import EditCategory from "./Seller/EditProductScreens/Category";
import EditDetails from "./Seller/EditProductScreens/Details";
import EditSizes from "./Seller/EditProductScreens/Sizes";
import EditMedia from "./Seller/EditProductScreens/Media";
import EditPrice from "./Seller/EditProductScreens/Price";
import Edit from "./Seller/EditProductScreens/edit";
import Register from "./Components/Authentication/Register";
import Policy from "./pages/policy";
import RentalTerms from "./pages/rental-terms";
import Welcome from "./pages/welcom";
import { defaultToken } from "./Global";

function Router() {
    const cookies = new Cookies();

    axios.defaults.withCredentials = false;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = `Bearer ${
        cookies.get("token") || defaultToken
    }`;

    const { openRoutes } = useContext(AuthContext);

    return (
        <Routes>
            <Route exact path="/" Component={Home} />
            <Route path="/product/:product_id" Component={Product} />
            <Route path="/verify-otp" Component={OTPVerification} />
            <Route path="/reset-password" Component={ResetPassword} />
            <Route path="/login" Component={Login} />
            <Route path="/register" Component={Register} />
            <Route path="/welcome" Component={Welcome} />

            {openRoutes === true && (
                <>
                    <Route exact path="/" Component={Home} />
                    <Route path="/product/:product_id" Component={Product} />
                    <Route path="/wallet" Component={Wallet} />
                    <Route path="/wishlist" Component={Wishlist} />
                    <Route path="/booking" Component={Booking} />
                    <Route path="/payments" Component={Payment} />
                    <Route path="/payment-confirmation/*" Component={PaymentConfirmation} />
                    <Route path="/bookings" Component={Bookings} />
                    <Route path="/bookings/:status" Component={Bookings} />
                    <Route path="/feedback" Component={Feedback} />
                    <Route path="/raise-complaint/:product_id" Component={RaiseComplaint} />
                    <Route path="/profile" Component={Profile} />
                    <Route path="/inbox" Component={Inbox} />
                    <Route path="/inbox/ratings" Component={Ratings} />
                    <Route path="/profile/security" Component={Security} />
                    <Route path="/profile/payouts" Component={Payouts} />
                    <Route path="/complete-kyc" Component={CompleteKYC} />
                    <Route path="/logout" Component={LogoutBtn} />

                    <Route path="inbox/notifications" Component={Notifications} />

                    <Route path="/seller" element={<Header />}>
                        <Route index element={<Seller />} />
                        <Route path="calender" Component={Seller} />
                        <Route path="bookings" element={<SellerBookings />} />
                        <Route path="bookings/:status" element={<SellerBookings />} />
                        <Route path="guide" element={<Guide />} />
                        <Route path="inbox" element={<SellerInbox />} />
                        <Route path="inbox/notifications" element={<SellerNotification />} />
                        <Route path="inbox/ratings" element={<SellerRatings />} />
                        <Route path="add-product" element={<AddProduct />}>
                            <Route index element={<Category />} />
                            <Route path="category" element={<Category />} />
                            <Route path="details" element={<Details />} />
                            <Route path="sizes" element={<Sizes />} />
                            <Route path="media" element={<Media />} />
                            <Route path="price" element={<Price />} />
                            <Route path="add" element={<Add />} />
                        </Route>
                        <Route path="edit-product/:product_id" element={<EditProduct />}>
                            <Route index element={<EditCategory />} />
                            <Route path="category" element={<EditCategory />} />
                            <Route path="details" element={<EditDetails />} />
                            <Route path="sizes" element={<EditSizes />} />
                            <Route path="media" element={<EditMedia />} />
                            <Route path="price" element={<EditPrice />} />
                            <Route path="edit" element={<Edit />} />
                        </Route>
                    </Route>
                </>
            )}
            {openRoutes === false && (
                <>
                    <Route path="/" Component={Signin} />
                </>
            )}
            <Route path="/terms-and-conditions" Component={Terms} />
            <Route path="/privacy-policy" Component={Policy} />
            <Route path="/rental-terms" Component={RentalTerms} />
        </Routes>
    );
}

export default Router;
