import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/authContext";

function Signin() {
  const { removeCookies } = useContext(AuthContext);

  useEffect(() => {
    async function remove() {
      try {
        await removeCookies()
        
      } catch (err) {
        console.log(err)
      }
    }
    remove()
  }, [])

  return (
    <>
      <div
        className="uk-section-signin uk-grid uk-child-width-1-2@m uk-height-1-1"
        data-uk-grid>
        <div className="uk-background-secondary uk-flex uk-flex-middle uk-flex-center">
          <img
            src="/images/side_banner.png"
            alt="Side Banner"
            className="uk-width-side-banner"
          />
        </div>
        <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center">
          <div className="uk-container uk-section uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <Link className="uk-button uk-button-primary" to="/login">
              Sign In
            </Link>
            <Link
              className="uk-button uk-button-white uk-margin-top"
              to="/register">
              Create Account
            </Link>
            {/* <Link
              className="uk-button uk-button-link uk-margin-top"
              to="/guest">
              Guest Mode
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
